import { imageList } from "@/components/VisualSearch/v1/UploadImage/constant"
const {
  faucetmainImage,
  faucetWrong1,
  faucetWrong2,
  faucetWrong3,
  faucetWrong1Mob,
  faucetWrong2Mob,
  faucetWrong3Mob,
  faucetmainImageTab,
  faucetmainImageMobile,
  toiletmainImage,
  toiletWrong1,
  toiletWrong2,
  toiletWrong3,
  toiletWrongMob1,
  toiletWrongMob2,
  toiletmainImageTab,
  valvemainImage,
  valveWrong1,
  valveWrong2,
  valveWrong3,
  valvemainImageTab,
  showermainImage,
  showerWrong1,
  showerWrong2,
  showerWrong3,
} = imageList

export const getImageCategory = (value, device) => {
  const imageForFaucet = device?.isSmallScreen
    ? faucetmainImageMobile
    : faucetmainImageTab

  const defaultItems = {
    idealImage: device?.isLargeTabView ? faucetmainImage : imageForFaucet,
    badAngle: device?.isSmallScreen ? faucetWrong1Mob : faucetWrong1,
    tooClose: device?.isSmallScreen ? faucetWrong2Mob : faucetWrong2,
    tooFar: device?.isSmallScreen ? faucetWrong3Mob : faucetWrong3,
  }

  switch (value) {
    case "faucet":
      return defaultItems

    case "toilet":
      return {
        idealImage: device?.isLargeTabView
          ? toiletmainImage
          : toiletmainImageTab,
        badAngle: device?.isSmallScreen ? toiletWrongMob1 : toiletWrong1,
        tooClose: device?.isSmallScreen ? toiletWrongMob2 : toiletWrong2,
        tooFar: toiletWrong3,
        text: "Photo should include tank and bowl",
      }

    case "valve":
      return {
        idealImage: device?.isLargeTabView ? valvemainImage : valvemainImageTab,
        badAngle: valveWrong1,
        tooClose: valveWrong2,
        tooFar: valveWrong3,
      }

    case "shower":
      return {
        idealImage: showermainImage,
        badAngle: showerWrong1,
        tooClose: showerWrong2,
        tooFar: showerWrong3,
      }

    default:
      return defaultItems
  }
}
