import DOMPurify from "isomorphic-dompurify"
import React, { useState } from "react"
import styles from "@/components/Shared/index.module.scss"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import { addAnalyticsOnClick } from "./analytics"

const MainFooter = props => {
  const { data, columnClassNames } = props
  const [gbhExpand, setGbhExpand] = useState([])
  const { winWidth } = useWindowDimensions()

  const footerTitleRole = winWidth < 1024 ? "button" : "heading"

  const isExpanded = key => gbhExpand.includes(key)

  const getExpandedClassname = key => (isExpanded(key) ? "gbh-expand" : "")

  const handleGbhExpand = key => {
    if (!isExpanded(key)) {
      const newGbhExpand = [...gbhExpand]
      newGbhExpand.push(key)
      setGbhExpand(newGbhExpand)
    } else {
      const newGbhExpand = [...gbhExpand]
      const idx = gbhExpand.indexOf(key)
      newGbhExpand.splice(idx, 1)
      setGbhExpand(newGbhExpand)
    }
  }

  const handleOnLinkClick = ({
    linkName,
    item,
    quadrantTitle,
    windowFeature = "",
    target,
    url = "",
  }) => {
    addAnalyticsOnClick(linkName, item, quadrantTitle)
    url && window.open(url, target, windowFeature)
  }

  return (
    <div
      className={`${columnClassNames ? columnClassNames : ""} ${
        styles.mainFooterWrapper
      }`}
    >
      <footer>
        <section
          className="gbh-footer-main-nav main-footer"
          aria-label="Footer"
        >
          <section className="gbh-container">
            <div className="row">
              <div
                className="col"
                aria-label={data?.quadrantTitleOne}
                role="list"
              >
                {data?.radioNavigationOne == "descriptionOne" && (
                  <>
                    <h2>{data?.quadrantTitleOne}</h2>
                    <div
                      className="gbh-find-store"
                      role="listitem"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantDescOne),
                      }}
                    />
                  </>
                )}
                {data?.radioNavigationOne == "linksOne" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${getExpandedClassname(
                          "linksOne"
                        )}`}
                        role={footerTitleRole}
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksOne")}
                        tabIndex={0}
                        aria-expanded={isExpanded("linksOne")}
                        aria-controls="footer-inner-links-linksOne"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.quadrantTitleOne),
                          }}
                        />
                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !isExpanded("linksOne") ? { display: "none" } : {}
                        }
                        id={`footer-inner-links-linksOne`}
                      >
                        {data?.items.map((item, index) => {
                          return (
                            <React.Fragment
                              key={`gbh-footer-main-nav-link-${index}`}
                            >
                              <li>
                                <a
                                  className="gbh-data-layer"
                                  aria-label={`${item?.brandNameOne}${
                                    item?.newTabOne
                                      ? " external link opens in a new tab"
                                      : ""
                                  }`}
                                  onClick={() =>
                                    handleOnLinkClick({
                                      linkName: "firstLink",
                                      item,
                                      quadrantTitle: data?.quadrantTitleOne,
                                      target: `${
                                        item?.newTabOne ? "_blank" : "_self"
                                      }`,
                                      windowFeature: "noreferrer",
                                      url: item?.brandLinkOne
                                        ?.split(
                                          process.env
                                            .NEXT_PUBLIC_INTERNALLINKPATH
                                        )
                                        .join("")
                                        .replace(".html", ""),
                                    })
                                  }
                                  tabIndex="0"
                                >
                                  {item?.brandNameOne}
                                </a>
                              </li>
                            </React.Fragment>
                          )
                        })}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="col" aria-label={data?.quadrantTitleTwo}>
                {data?.radioNavigationTwo == "descriptionTwo" && (
                  <>
                    <div
                      className="footer-inner-head"
                      role="heading"
                      aria-level="2"
                    >
                      {data?.quadrantTitleTwo}
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantDescTwo),
                      }}
                    />
                  </>
                )}
                {data.radioNavigationTwo == "linksTwo" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${getExpandedClassname(
                          "linksTwo"
                        )}`}
                        role={footerTitleRole}
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksTwo")}
                        tabIndex={0}
                        aria-expanded={isExpanded("linksTwo")}
                        aria-controls="footer-inner-links-linksTwo"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.quadrantTitleTwo),
                          }}
                        />
                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !isExpanded("linksTwo") ? { display: "none" } : {}
                        }
                        className={data?.itemsOne.length > 4 ? "odd-even" : ""}
                        id="footer-inner-links-linksTwo"
                      >
                        {data?.itemsOne.map((item, index) => (
                          <li key={`linksTwo-${index}`}>
                            <a
                              className="gbh-data-layer"
                              aria-label={`${item?.brandNameTwo}${
                                item?.newTabTwo
                                  ? " external link opens in a new tab"
                                  : ""
                              }`}
                              onClick={() =>
                                handleOnLinkClick({
                                  url: item?.brandLinkTwo
                                    ?.split(
                                      process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                    )
                                    .join("")
                                    .replace(".html", ""),
                                  linkName: "oddEven",
                                  quadrantTitle: data?.quadrantTitleTwo,
                                  item,
                                  windowFeature: "noreferrer",
                                  target: item?.newTabTwo ? "_blank" : "_self",
                                })
                              }
                              tabIndex="0"
                            >
                              {item?.brandNameTwo && item?.brandNameTwo}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="col" aria-label={data?.quadrantTitleThree}>
                {data?.descriptionThree == "descriptionThree" && (
                  <>
                    <h2>{data?.quadrantTitleThree}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantDescThree),
                      }}
                    />
                  </>
                )}
                {data?.radioNavigationThree == "linksThree" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${getExpandedClassname(
                          "linksThree"
                        )}`}
                        role={footerTitleRole}
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksThree")}
                        tabIndex={0}
                        aria-expanded={isExpanded("linksThree")}
                        aria-controls="footer-inner-links-linksThree"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              data?.quadrantTitleThree
                            ),
                          }}
                        />
                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !isExpanded("linksThree") ? { display: "none" } : {}
                        }
                        className={data?.itemsTwo.length > 4 ? "odd-even" : ""}
                        id="footer-inner-links-linksThree"
                      >
                        {data?.itemsTwo.map((item, index) => (
                          <li key={`linksThree-${index}`}>
                            <a
                              className="gbh-data-layer"
                              href={item?.brandLinkThree
                                ?.split(
                                  process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                )
                                .join("")
                                .replace(".html", "")}
                              target={item?.newTabThree ? "_blank" : ""}
                              rel="noreferrer"
                              aria-label={`${item?.brandNameThree}${
                                item?.newTabThree
                                  ? " external link opens in a new tab"
                                  : ""
                              }`}
                              data-gbh-data-layer={`{"clickInternalLinks":"${
                                item?.newTabThree ? "false" : "true"
                              }","internalLinkPosition":"${data?.quadrantTitleThree.toLowerCase()}","internalLinkZoneName":"global footer","internalLinkURL":"${item?.brandLinkThree
                                ?.split(
                                  process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                )
                                .join("")
                                .replace(
                                  ".html",
                                  ""
                                )}","internalLinkName":"${item?.brandNameThree?.toLowerCase()}","eventName":"footer menu:${item?.brandNameThree?.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"footer submenu","eventAction":"footer menu:${item?.brandNameThree?.toLowerCase()}"}`}
                              tabIndex="0"
                            >
                              {item?.brandNameThree && item?.brandNameThree}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
              <div className="col" aria-label={data?.quadrantTitleFour}>
                {data?.radioNavigationFour == "descriptionFour" && (
                  <>
                    <h2>{data?.quadrantTitleFour}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data?.quadrantDescFour),
                      }}
                    />
                  </>
                )}
                {data?.radioNavigationFour == "linksFour" && (
                  <>
                    <div className="gbh-footer-main-nav-link">
                      <div
                        className={`footer-inner-head ${getExpandedClassname(
                          "linksFour"
                        )}`}
                        role={footerTitleRole}
                        aria-level="2"
                        onClick={() => handleGbhExpand("linksFour")}
                        tabIndex={0}
                        aria-expanded={isExpanded("linksFour")}
                        aria-controls="footer-inner-links-linksFour"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data?.quadrantTitleFour),
                          }}
                        />
                        <div className="accordion">
                          <div className="line line-1"></div>
                          <div className="line line-2"></div>
                        </div>
                      </div>
                      <ul
                        style={
                          !isExpanded("linksFour") ? { display: "none" } : {}
                        }
                        className={
                          data?.itemsThree.length > 4 ? "odd-even" : ""
                        }
                        id="footer-inner-links-linksFour"
                      >
                        {data?.itemsThree.map((item, index) => (
                          <li key={`linksThree-${index}`}>
                            <a
                              className="gbh-data-layer"
                              aria-label={`${item?.brandNameFour}${
                                item?.newTabFour
                                  ? " external link opens in a new tab"
                                  : ""
                              }`}
                              onClick={() =>
                                handleOnLinkClick({
                                  url: item?.brandLinkFour
                                    ?.split(
                                      process.env.NEXT_PUBLIC_INTERNALLINKPATH
                                    )
                                    .join("")
                                    .replace(".html", ""),
                                  linkName: "linkThree",
                                  item,
                                  quadrantTitle: data?.quadrantTitleFour,
                                  windowFeature: "noreferrer",
                                  target: item?.newTabFour ? "_blank" : "_self",
                                })
                              }
                              tabIndex="0"
                            >
                              {item?.brandNameFour && item?.brandNameFour}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </section>
      </footer>
    </div>
  )
}

export default MainFooter
