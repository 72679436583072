import { HELP_AND_FAQ_SUPPORT } from "@/constants/index"

export const getCategoryURL = category => {
  const PRODUCT_FAQ_URL = window?.location?.origin + HELP_AND_FAQ_SUPPORT
  switch (category) {
    case "sink":
      return `${PRODUCT_FAQ_URL}/identifying-your-sink-model`

    case "bath":
      return `${PRODUCT_FAQ_URL}/identifying-your-bath-or-whirlpool-model`

    case "shower door":
      return `${PRODUCT_FAQ_URL}/identifying-your-shower-door-model`

    case "toilet seat":
      return `${PRODUCT_FAQ_URL}/identifying-your-toilet-seat-model`
  }
}
