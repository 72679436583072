import { store } from "@/store"
import { getShortenedUrlPath } from "@/utils/helper"
import { getCartIdParams } from "@/utils/cart"
import { getPayPalPaymentIdFromParams } from "@/utils"

import { ACI_PAYMENT_CALLBACK_ENDPOINT } from "@/constants/routes"
import { apim, aemAxiosInstance } from "@/constants/api"
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants/"

import { setLoading } from "@/store/features/genericSlice"
import { setPaymetId, getCart } from "@/store/features/cartSlice"
import {
  setIsPaymentInfoAction,
  setPaymentMethodNameAction,
  setCheckoutAction,
  setExpPayPalCheckout,
  setPayPalCallBackErrorMsg,
  setIsVerifiedAddress,
  setPaymentErrorMessageAction,
  setPaymentFailedAction,
  setIsInvalidCountryAction,
} from "@/store/features/checkoutSlice"

export const getAciPayPalPaymentStatus = async data => {
  const {
    genericError = "",
    unverifiedAddressMsg = "",
    outOfCountryAddressMsg = "",
    expPayPalCallBackErrorMsg: expPayPalAuthorErrorMsg = "",
    onNext = () => {},
    onPaymentError = () => {},
  } = data || {}

  const isXpp = checkExpPayPal()
  const id = getPayPalPaymentIdFromParams()
  store.dispatch(setPaymentFailedAction(false))

  const {
    generic: {
      aciPaymentErrorMessagesPath = "",
      globalConfig: { general: { checkoutPagePath = "/" } = {} } = {},
    } = {},
  } = store.getState() || {}

  store.dispatch(setLoading(true))
  store.dispatch(setPaymentErrorMessageAction(""))

  const checkoutUrl = getShortenedUrlPath(checkoutPagePath)
  const { activeCartId = "" } = getCartIdParams()

  if (!id) {
    window.history.replaceState(null, null, checkoutUrl)
    store.dispatch(setLoading(false))
    return false
  }

  const apiUrl = `${ACI_PAYMENT_CALLBACK_ENDPOINT}?cartId=${activeCartId}&sessionId=${id}&pm=${
    isXpp ? "xpp" : "pp"
  }`

  try {
    const { data = {} } = await apim.get(apiUrl)

    const { paymentInfo: { payments = [] } = {}, isVerifiedAddress = false } =
      data
    const {
      id: payPalpaymentId = "",
      obj: { paymentMethodInfo: { method = "" } = {} } = {},
    } = payments[0] ?? {}
    store.dispatch(setPaymetId(payPalpaymentId))
    store.dispatch(setIsPaymentInfoAction(true))
    store.dispatch(setPaymentMethodNameAction(method))
    store.dispatch(setLoading(false))

    onNext()

    if (isXpp) {
      store.dispatch(setIsVerifiedAddress(isVerifiedAddress))
      store.dispatch(setCheckoutAction(!isVerifiedAddress ? 0 : 3))
      if (!isVerifiedAddress) {
        store.dispatch(setPayPalCallBackErrorMsg(unverifiedAddressMsg))
      }
      store.dispatch(getCart())
    }

    window.history.replaceState(
      null,
      null,
      `${checkoutUrl}${isXpp ? "?pm=xpp" : ""}`
    )
  } catch (error) {
    const {
      response: {
        data: { errorCode, errorDesc = "", isShipmentFromCart = true } = {},
      } = {},
    } = error ?? {}

    store.dispatch(setPaymentFailedAction(true))

    try {
      const { data = [] } = await aemAxiosInstance.get(
        GLOBAL_CONFIGURATION_ENDPOINT + "?path=" + aciPaymentErrorMessagesPath
      )
      const { value: errorMsg } = data.find(e => e.text === errorCode)
      onPayPalCallBackErorr({
        isXpp,
        genericError,
        errorMsg,
        errorDesc,
        expPayPalAuthorErrorMsg,
        outOfCountryAddressMsg,
        unverifiedAddressMsg,
        isShipmentFromCart,
      })

      onPaymentError("")
      store.dispatch(setLoading(false))
    } catch {
      onPayPalCallBackErorr({
        isXpp,
        genericError,
        errorDesc,
        expPayPalAuthorErrorMsg,
        outOfCountryAddressMsg,
        unverifiedAddressMsg,
        isShipmentFromCart,
      })
    }

    store.dispatch(setLoading(false))
    window.history.replaceState(null, null, checkoutUrl)
  }
}

const onPayPalCallBackErorr = data => {
  const {
    isXpp,
    genericError,
    errorMsg,
    errorDesc,
    expPayPalAuthorErrorMsg,
    outOfCountryAddressMsg,
    unverifiedAddressMsg,
    isShipmentFromCart,
  } = data

  const isInvalidCountry = errorDesc === "invalid country"
  store.dispatch(setIsInvalidCountryAction(isInvalidCountry))

  const getErrorMessage = () => {
    if (isInvalidCountry) {
      return outOfCountryAddressMsg
    }
    if (!isShipmentFromCart) {
      return unverifiedAddressMsg
    }
    return errorMsg || expPayPalAuthorErrorMsg
  }

  if (isXpp) {
    store.dispatch(setCheckoutAction(0))
    store.dispatch(setExpPayPalCheckout(false))

    store.dispatch(setPayPalCallBackErrorMsg(getErrorMessage()))
  } else {
    store.dispatch(setCheckoutAction(2))
    store.dispatch(setPaymentMethodNameAction("paypal"))
    store.dispatch(
      setPaymentErrorMessageAction(errorMsg || errorDesc || genericError)
    )
  }
}

export const checkExpPayPal = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const paymentType = urlParams.get("pm")
  return paymentType === "xpp"
}

export const aciPaymentWidgetsInit = (isInit = false, checkoutId = "") => {
  const scriptElem = document.getElementById("aciPaymentWidget")

  if (isInit) {
    const script = document.createElement("script")
    script.src = `${process.env.NEXT_PUBLIC_ACI_PAYMENT_WIDGETS_ENDPOINT}?${
      checkoutId ? `checkoutId=${checkoutId}` : ""
    }`
    script.id = "aciPaymentWidget"
    document.body.append(script)
  } else {
    scriptElem?.remove()
  }
}
