import { useEffect, useCallback, useState, useRef } from "react"
import { staticClasses } from "@/constants"
import styles from "@/components/Shared/HeaderNavTabs/index.module.scss"
import { preventBodyScrollTab } from "@/utils/helper"
import {
  backClickForMobile,
  closeButtonDatalayer,
  l1DatalayerclickMobile,
} from "@/components/Shared/HeaderNavTabs/analytics/index.js"
import useSmartCrop from "@/hooks/useSmartCrop"
import { checkSingleNonEmptyLevelThreeNavMulti } from "@/components/Shared/HeaderNavTabs/utils"
import TabPanel from "@/components/Shared/HeaderNavTabs/TabPanel/index"
import UtilityNavHeader from "@/components/Shared/UtilityNavHeader"


const HeaderNavTabsV3 = props => {
  const {
    data,
    columnClassNames = "",
    eventHandler: {
      store: { isOpen },
      callbacks: { toggleMenu = () => {}, setMainCategory = () => {} },
    },
    wrapperId = "",
  } = props || {}

  const element = data[":items"]

  const isMobile = typeof window !== "undefined" && window.innerWidth <= 1200
  const iconCloseMedium = "/icons/close-medium.svg"
  const [navOpen, setNavOpen] = useState("")
  const smartCrop = useSmartCrop()
  const [selectedTab, setSelectedTab] = useState("")
  const [darkThemeFlag, setDarkThemeFlag] = useState(false)
  const [activeIndex, setActiveIndex] = useState(null)
  const [selectedId, setSelectedId] = useState(null)
  const [selectedNavTabIndex, setSelectedNavTabIndex] = useState(null)
  const [showTab, setShowTab] = useState(false)

  const menuVisible = useRef(0)
  const dataRecursion = useCallback(data => {
    if (data?.[":itemsOrder"]) {
      data?.[":itemsOrder"]?.map((key, index) => {
        const item = element[key]
        return dataRecursion(item)
      })
    }
  }, [])

  const onClickNav = (data, str, navTabIndex) => {
    if (data) {
      if (selectedTab !== data["cq:panelTitle"]) {
        setSelectedTab(data["cq:panelTitle"])
      }

      if (navOpen !== data["cq:panelTitle"]) {
        setNavOpen(data["cq:panelTitle"])
        if (!isMobile) {
          setNavOpen(data["cq:panelTitle"])
        }
        setMainCategory({ item: data["cq:panelTitle"] })
        menuVisible.current = 1
        preventBodyScrollTab(true)
        // setSelectedNavTabIndex(null)
        setActiveIndex(null)
        setSelectedId(null)
        if (str === "title click") {
          setShowTab(true)
          setSelectedNavTabIndex(navTabIndex)
        }
        isMobile &&
          str === "title click mob" &&
          l1DatalayerclickMobile(data["cq:panelTitle"])
      } else {
        str === "mobile back btn" && backClickForMobile(selectedTab)

        setShowTab(false)
        setNavOpen("")
        setSelectedTab("")
        setMainCategory({ item: "" })
        menuVisible.current = 0
        preventBodyScrollTab(false)
      }
    }
  }

  const handleEnterClickForTab = e => {
    if (e.keyCode === 13) {
      setTimeout(() => onClickNav(element[e.target.id]), 50)
    }
  }

  const handleAccordionClick = (index, id, navTabIndex) => {
    setActiveIndex(activeIndex === index && selectedId === id ? null : index)
    setSelectedId(selectedId === id && activeIndex === index ? null : id)
    setSelectedNavTabIndex(
      selectedNavTabIndex === navTabIndex ? null : navTabIndex
    )
  }
  const levelOneLengthsArray = []

  const handleMouseLeave = () => {
    setNavOpen("")
    setSelectedTab("")
    setMainCategory({ item: "" })
    menuVisible.current = 0
    preventBodyScrollTab(false)
  }

  const onMenuClickEvent = e => {
    if (
      (e?.target?.classList?.contains("gbh-pro-menu__inner") ||
        (e?.target?.closest(".gbh-pro-menu__inner") === null &&
          e?.target?.closest(".gbh-full-w-menu__inner") === null &&
          e?.target?.closest(".cmp-tabs__tablist") === null &&
          !isMobile)) &&
      menuVisible.current
    ) {
      setNavOpen("")
      setSelectedTab("")
      setMainCategory({ item: "" })
      menuVisible.current = 0
      preventBodyScrollTab(false)
    }
  }
  useEffect(() => {
    const anchorElements = document.querySelectorAll(
      ".gbh-full-w-menu__inner a:not([href])"
    )

    anchorElements?.forEach(anchor => {
      anchor.style.textDecoration = "none"
      anchor.style.cursor = "default"
    })
    if (levelOneLengthsArray[selectedNavTabIndex] !== -1) {
      setActiveIndex(levelOneLengthsArray[selectedNavTabIndex])
      setSelectedId(levelOneLengthsArray[selectedNavTabIndex])
    }
  }, [])
  useEffect(() => {
    if (
      document
        ?.getElementsByTagName?.("body")?.[0]
        ?.className?.includes("nav-dark-theme")
    ) {
      setDarkThemeFlag(true)
    }
  }, [])

  useEffect(() => {
    if (navOpen) {
      document.addEventListener("click", onMenuClickEvent)
    } else {
      document.removeEventListener("click", onMenuClickEvent)
    }
    return () => document.removeEventListener("click", onMenuClickEvent)
  }, [navOpen])

  useEffect(() => {
    dataRecursion(data)
  }, [data, dataRecursion])

  const getInternalLinkURLS = url => {
    if (typeof window !== "undefined") {
      return !(
        url?.startsWith("/") ||
        url?.includes(window.location.hostname) ||
        url === null
      )
    }
  }
  return (
    <div id="HEADER ID" className={styles.headerNavV3Wrapper}>
      <div
        className={`tabs ${
          data?.appliedCssClassNames
            ? data?.appliedCssClassNames
            : "gbh-global-tab"
        } ${columnClassNames} ${isOpen ? "open" : ""}`}
        id={wrapperId}
      >
        <div
          className={`cmp-tabs ${darkThemeFlag ? "dark-theme" : "light-theme"}`}
        >
          <span
            className="gbh-hamber-menu gbh-close icon-Close-Medium"
            aria-label="close button"
            tabIndex="0"
            onClick={() => {
              toggleMenu()
              isMobile && closeButtonDatalayer(selectedTab)
              onClickNav({ "cq:panelTitle": navOpen })
            }}
          >
            <img src={iconCloseMedium} alt="" />
          </span>
          <ol
            onMouseOver={
              !isMobile
                ? event => {
                    onClickNav(element[event.target.id])
                  }
                : null
            }
            onClick={
              !isMobile
                ? event => {
                    onClickNav(element[event.target.id])
                  }
                : null
            }
            onMouseLeave={!isMobile ? handleMouseLeave : null}
            role="tablist"
            className="cmp-tabs__tablist"
            aria-multiselectable="false"
          >
            {data[":itemsOrder"] &&
              data[":itemsOrder"].map((key, navTabIndex) => {
                const itemType = element[key]

                const item =
                  itemType[":items"]?.globalnavigation ||
                  itemType[":items"][
                    Object.keys(itemType[":items"])?.find(key =>
                      key.includes("globalnavigation_v3")
                    )
                  ]

                const {
                  firstImagesblock = {},
                  secondImagesblock = {},
                  navigationMenuType: menuType = "",
                  navTitle = "",
                  levelOneNavMulti = [],
                  multiCTA = [],
                } = item || {}

                levelOneLengthsArray.push(
                  checkSingleNonEmptyLevelThreeNavMulti(levelOneNavMulti)
                )
                return (
                  <div
                    className="tab-panel-container"
                    data-nav-id={itemType["cq:panelTitle"]}
                    key={key + navTabIndex}
                    aria-expanded={navOpen === itemType["cq:panelTitle"]}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <a
                      onMouseOver={event => {
                        !isMobile
                          ? onClickNav(
                              element[event.target.id],
                              "title click mob"
                            )
                          : null
                      }}
                      aria-label={`Tab for ${itemType["cq:panelTitle"]}  ${
                        navOpen === itemType["cq:panelTitle"]
                          ? "expanded"
                          : "collapsed"
                      }`}
                      id={key}
                      onClick={event =>
                        onClickNav(
                          element[event.target.id],
                          "title click",
                          navTabIndex
                        )
                      }
                      onFocus={
                        !isMobile
                          ? () => {
                              setNavOpen("")
                            }
                          : null
                      }
                      onFocusCapture={!isMobile ? () => setNavOpen("") : null}
                      onKeyDown={e => handleEnterClickForTab(e)}
                      role="link"
                      className={`cmp-tabs__tab ${
                        navOpen === itemType["cq:panelTitle"]
                          ? "cmp-tabs__tab--active"
                          : ""
                      }`}
                      tabIndex={"0"}
                      key={`navItem-${navTabIndex}`}
                    >
                      {itemType["cq:panelTitle"]}
                    </a>
                    {!isMobile || (isMobile && showTab) ? (
                      <TabPanel
                        firstImagesblock={firstImagesblock}
                        secondImagesblock={secondImagesblock}
                        itemType={itemType}
                        staticClasses={staticClasses}
                        handleMouseLeave={handleMouseLeave}
                        onClickNav={onClickNav}
                        navOpen={navOpen}
                        darkThemeFlag={darkThemeFlag}
                        menuType={menuType}
                        selectedTab={selectedTab}
                        navTitle={navTitle}
                        levelOneNavMulti={levelOneNavMulti}
                        activeIndex={activeIndex}
                        getInternalLinkURLS={getInternalLinkURLS}
                        multiCTA={multiCTA}
                        handleAccordionClick={handleAccordionClick}
                        smartCrop={smartCrop}
                        navTabIndex={navTabIndex}
                        levelOneLengthsArray={levelOneLengthsArray}
                        selectedNavTabIndex={selectedNavTabIndex}
                        setActiveIndex={setActiveIndex}
                        setSelectedId={setSelectedId}
                        selectedId={selectedId}
                      />
                    ) : null}
                  </div>
                )
              })}
            {data?.mainTabId &&
              Object.keys(data?.mainTabId).map((key, index) => {
                return (
                  <li
                    role="tab"
                    className="cmp-tabs__tab"
                    tabIndex="0"
                    key={`navItem-${index}`}
                  >
                    {key}
                  </li>
                )
              })}
          </ol>
          {isMobile ? (
            <UtilityNavHeader
              data={element}
              eventHandler={props?.eventHandler}
            />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default HeaderNavTabsV3
