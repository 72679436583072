import {
  getShortenedUrlwithGeneral,
  sanitizeInnerHtml,
  sanitizeTextArticle,
} from "@/utils/helper"
import { ConditionalWrapper } from "@/utils/index"
import _isEmpty from "lodash/isEmpty"
import { useEffect, useState } from "react"
import { addTeaserDescriptionAnalytic } from "@/components/Default/Teaser/v1/analytics"

const Description = props => {
  const {
    linkURL = "",
    linkURLRef = "",
    linkNewTab = "",
    description = "",
    mainTitle = "",
    componentPosition = null,
    componentLinkType = null,
    pageType: serverPageType = "",
    linkDescription = false,
    general = {},
    expandText = "",
    collapseText = "",
    disableExpandCollapse = "",
    preTitle = "",
  } = props

  const [shortenedLinkUrl, setShortenedLinkUrl] = useState("")
  const [pageType, setPageType] = useState(serverPageType)
  const [target, setTarget] = useState("")
  const [details, setDetails] = useState(description)
  const [isPdp, setIsPdp] = useState("")
  const [linkText, setLinkText] = useState("")
  const isPlp = pageType === "plp"

  useEffect(() => {
    window.expandText = expandText
    window.collapseText = collapseText
    window.disableExpandCollapse = disableExpandCollapse
  }, [expandText, collapseText, disableExpandCollapse])

  useEffect(() => {
    const pageInfoDetails = window?.adobeDataLayer?.getState("page")
    if (
      serverPageType !== pageInfoDetails?.pageInfo?.contentType &&
      pageInfoDetails?.pageInfo?.contentType === "pdp"
    ) {
      setPageType(pageInfoDetails?.pageInfo?.contentType)
      setIsPdp(true)
    }
  }, [])
  const internalLinkPositionNotPdp = `${pageType.toLowerCase()}:${
    componentPosition ? componentPosition : "teaser"
  }`
  const internalLinkTypeNotPdp = `${pageType}:${
    componentLinkType ? componentLinkType : mainTitle.toLowerCase()
  }`
  let sanitizeDescription = sanitizeTextArticle(description).toLowerCase()
  useEffect(() => {
    if (!_isEmpty(general)) {
      if (linkURL) {
        setShortenedLinkUrl(getShortenedUrlwithGeneral(linkURL, general))
      }
      if (description) {
        setDetails(getShortenedUrlwithGeneral(description, general))
        if(description.includes("href")){
          let tempElement = document.createElement("div")
          tempElement.innerHTML = description
          let text = tempElement.querySelector('a').textContent
          setLinkText(text)
        }
      }
    }
  }, [linkURL, description, general])

  sanitizeDescription = details.includes("href") ? linkText.toLowerCase() : sanitizeDescription
  
  // Added to fix eslint error Disallow target="_blank" attribute without rel="noreferrer" (react/jsx-no-target-blank)
  useEffect(() => {
    if (linkNewTab) {
      setTarget("_blank")
    } else {
      setTarget("_self")
    }
  }, [linkNewTab])

  return (
    <div
      className="cmp-teaser__description"
      data-gbh-data-layer={addTeaserDescriptionAnalytic(
        target,
        isPdp,
        isPlp,
        pageType,
        internalLinkPositionNotPdp,
        preTitle,
        mainTitle,
        linkURL,
        internalLinkTypeNotPdp,
        sanitizeDescription
      )}
    >
      <ConditionalWrapper
        condition={linkDescription}
        wrapper={children => (
          <a
            href={shortenedLinkUrl}
            rel={linkURLRef ? "noreferrer" : ""}
            target={target}
          >
            {children}
          </a>
        )}
      >
        <div>{sanitizeInnerHtml(details)}</div>
      </ConditionalWrapper>
    </div>
  )
}

export default Description
