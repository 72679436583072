import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { withTranslation } from "react-i18next"
import _isEmpty from "lodash/isEmpty"
import axios from "axios"

import { apim } from "@/constants/api"
import { getShortenedUrlPath } from "@/utils/helper"
import FindAProResultsLanding from "@/components/FindAProResults/v1/FindAProResults.view"
import { selectGenericState } from "@/store/features/genericSlice"
import useFindAProResultsi18n from "@/i18n/useFindAProResultsi18n"

const FindAProResults = authorData => {
  const [data, setData] = useState([])
  const [designersData, setDesignersData] = useState([])
  const [loading, setLoading] = useState(true)
  const {
    globalConfig: {
      general: {
        findAProbyZipPagePath = "",
        bathroomDesignServicesPagePath = "",
        siteName = "",
        findProResults = "",
      } = {},
    } = {},
  } = useSelector(selectGenericState)

  const urlParams =
    typeof window !== "undefined"
      ? new URLSearchParams(window.location.search)
      : null
  const zipcodeVal = urlParams?.get("q")

  useEffect(() => {
    const fetchData = async () => {
      if (!_isEmpty(findProResults)) {
        const response = await axios.get(findProResults)
        if (response && response.data) {
          setDesignersData(response.data.items ?? [])
        }
      }
    }

    const fetchPros = async () => {
      if (!_isEmpty(zipcodeVal)) {
        try {
          const response = await apim.get("/maps/pro", {
            params: {
              spatialFilter: `nearby('${zipcodeVal}',${40})`,
              $format: "json",
              brand: siteName.toLowerCase(),
              $select:
                "EntityID,LocationName,AddressLine,AddressLine2,Locality,AdminDistrict,PostalCode,LogoIMG,LocationRemodeler,LocationInstaller,LocationPlumber,LocationBuilder,LocationDesigner,LocationArchitect,Email,Phone,__Distance,SpecGeneral,SpecIntelligentToilets,SpecDigitalShowering,SpecLighting,SpecKohlerConnected,SpecShowerDoors",
            },
          })
          if (response && response.data) {
            setData(response.data.d.results)
          }
          setLoading(false)
        } catch (error) {
          setLoading(null)
        }
      }
    }

    fetchData()
    fetchPros()
  }, [zipcodeVal, findProResults])

  const staticTexts = useFindAProResultsi18n(authorData?.data)
  return (
    <>
      <div className="find-a-pro-resuls-test">
        <FindAProResultsLanding
          loading={loading}
          data={data}
          zipcodeVal={zipcodeVal}
          staticTexts={staticTexts}
          designersData={designersData}
          findAProbyZipPagePath={getShortenedUrlPath(findAProbyZipPagePath)}
          bathroomDesignServicesPagePath={getShortenedUrlPath(
            bathroomDesignServicesPagePath
          )}
        />
      </div>
    </>
  )
}

export default withTranslation()(FindAProResults)
