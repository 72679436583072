import { useTranslation } from "next-i18next"

const usePdpi18n = (authorData, badgeText, name) => {
  const { t } = useTranslation()
  const i18n = {
    home: t("kf.global.label.home"),
    viewLink: t("kf.pdp.label.viewGallery"),
    linkMore: t("kf.pdp.label.morePhotos"),
    moreCollection: t("kf.pdp.label.moreFromCollection"),
    moreCollectionDiscontinued: t(
      "kf.pdp.label.moreFromCollectionDiscontinued"
    ),
    shopText: t("kf.pdp.label.shopAll"),
    listPrice: t("kf.pdp.label.listPrice"),
    refPrice: authorData.listpricelabel ?? t("kf.pdp.label.refPrice"),
    startingAt: t("kf.pdp.label.startingAt"),
    finish: authorData.colorlabel ?? t("kf.pdp.label.finish"),
    orderSample: t("kf.pdp.label.orderSample"),
    dimensions: t("kf.pdp.label.dimensions"),
    details: t("kf.pdp.label.details"),
    viewAllSpecs: t("kf.pdp.label.viewAllSpecs"),
    viewMore: t("kf.pdp.label.viewMore"),
    viewGallery:
      authorData?.viewgalleryctalabel ?? t("kf.pdp.label.viewGallery"),
    length: t("kf.pdp.label.length"),
    width: t("kf.pdp.label.width"),
    height: t("kf.pdp.label.height"),
    smLength: t("kf.pdp.label.smLength"),
    smWidth: t("kf.pdp.label.smWidth"),
    smHeight: t("kf.pdp.label.smHeight"),
    learnMore: t("kf.pdp.label.learnMore"),
    addToCart: t("kf.cart.label.addToCart"),
    addService: t("kf.cart.label.addService"),
    backOrderMssg: days => t("kf.cart.label.backOrderMssg", { days }),
    print: t("kf.plp.label.print"),
    wishlist: t("kf.pdp.label.wishlist"),
    share: t("kf.plp.label.share"),
    addToCompare: t("kf.compare.label.addToCompare"),
    nearByStoreLink: t("kf.pdp.label.nearByStoreLink"),
    professionalService:
      authorData.professionalService ?? t("kf.pdp.label.professionalService"),
    installationHelp:
      authorData.installationHelp ?? t("kf.pdp.label.installationHelp"),
    installerArea: authorData.installerArea ?? t("kf.pdp.label.installerArea"),
    sameCategory: t("kf.compare.validation.sameCategory"),
    maxProduct:
      authorData.maxProducts ?? t("kf.compare.validation.maxProducts"),
    sameVariants:
      authorData?.sameVariants ?? t("kf.compare.validation.sameVariants"),
    itemAdded: t("kf.pdp.label.itemAdded"),
    itemsAdded: t("kf.pdp.label.itemsAdded"),
    yourCart: t("kf.pdp.label.yourCart"),
    selectMoreOptions: t("kf.pdp.label.selectMoreOptions"),
    productOptions:
      authorData.superskulabel ?? t("kf.pdp.label.productOptions"),
    priceFootnoteTitle:
      authorData.priceFootnoteTitle ?? t("kf.pdp.label.priceFootnoteTitle"),
    priceFootnote: authorData.priceFootnote ?? t("kf.pdp.label.priceFootnote"),
    colorFootnoteTitle:
      authorData.colorFootnoteTitle ?? t("kf.pdp.label.colorFootnoteTitle"),
    colorFootnote: authorData.colorFootnote ?? t("kf.pdp.label.colorFootnote"),
    discontinueProductMsg:
      authorData.discontinueProductMsg ??
      t("kf.pdp.label.discontinueProductMsg"),
    discontinueColorMsg:
      authorData.discontinueColorMsg ?? t("kf.pdp.label.discontinueColorMsg"),
    itemReqCTA: authorData.onPdpCtaLabel ?? t("kf.pdp.label.itemReqCTA"),
    editItemReqCTA: authorData.onPdpCtaLabel
      ? `${t("kf.pdp.label.edit")} ${authorData.onPdpCtaLabel}`
      : t("kf.pdp.label.editItemReqCTA"),
    colorOrFinish: authorData.colorOrFinish ?? t("kf.pdp.label.colorOrFinish"),
    outOfStockMsg: authorData.outofStockDesc,
    outOfStock: authorData.outofStockTitle ?? t("kf.pdp.label.outOfStockMsg"),
    notifyMailCTA: t("kf.pdp.label.notifyMailCTA"),
    emailAddress: t("kf.pdp.label.emailAddress"),
    validEmail: t("kf.pdp.label.validEmail"),
    welcomeMsg: authorData.welcomeMsg ?? t("kf.pdp.label.welcomeMsg"),
    discontinuedTxt: t("kf.pdp.label.discontinuedTxt"),
    compareTxt: t("kf.pdp.label.compareTxt"),
    favTxt: t("kf.pdp.label.favTxt"),
    projectTxt: t("kf.pdp.label.projectTxt"),
    findItemCTA: t("kf.pdp.label.findItemCTA"),
    availColorCTA: authorData.discontinuePartialCtaLabel
      ? authorData.discontinuePartialCtaLabel
      : t("kf.pdp.label.availColorCTA"),
    notifyEmailTxt:
      authorData.notifyEmailTxt ?? t("kf.pdp.label.notifyEmailTxt"),
    findLocalStore:
      authorData.findLocalStore ?? t("kf.pdp.label.findLocalStore"),
    toCheckAvailability: t("kf.pdp.label.toCheckAvailability"),
    backOrder: authorData.backOrder ?? t("kf.pdp.label.backOrder"),
    specialOrder: authorData.specialOrder ?? t("kf.pdp.label.specialOrder"),
    discontinuedColor:
      authorData.discontinueColorLabel ?? t("kf.pdp.label.discontinuedColor"),
    discontinuedColorFinish:
      authorData.discontinueColorFinishLabel ??
      t("kf.pdp.label.discontinuedColorFinish"),
    toolTip: t("kf.pdp.label.toolTip"),
    requiredProductTitle:
      authorData.modalRightSectionTitle ??
      t("kf.pdp.label.requiredProductTitle"),
    cadRequiredProductTitle:
      authorData.modalRightSectionTitle ??
      t("kf.pdp.label.cadRequiredProductTitle"),
    requiredProductDescription:
      authorData.modalRightSectionDesc ??
      t("kf.pdp.label.requiredProductDescription"),
    cadRequiredProductDescription:
      authorData.modalRightSectionDesc ??
      t("kf.pdp.label.cadRequiredProductDescription"),
    productText: t("kf.pdp.label.productText"),
    includesText: t("kf.pdp.label.includesText"),
    updateText: t("kf.pdp.label.updateText"),
    includeTextCTA:
      authorData.modalRighttSectionCtaLabel ?? t("kf.pdp.label.includeTextCTA"),
    title: authorData.modalLeftSectionTitle ?? t("kf.pdp.label.title"),
    remove: t("kf.pdp.label.remove"),
    tooltipMsg: authorData.onPdpTooltip ?? t("kf.pdp.label.tooltipMsg"),
    priceSpiderTitle:
      authorData.priceSpiderTitle ?? t("kf.pdp.label.priceSpiderTitle"),
    primaryTxt:
      authorData.oversizeMsgTitle ?? t("kf.pdp.label.alert.primaryMsg"),
    secondaryTxt:
      authorData.oversizeMsgDesc ?? t("kf.pdp.label.alert.secondaryMsg"),
    withoutPriceTxt: authorData.noPriceMsg ?? t("kf.pdp.label.withoutPriceTxt"),
    projectPackMssg: authorData.projectPackMssg
      ? authorData.projectPackMssg
      : t("kf.pdp.label.projectPackMssg"),
    qtyMoreThanStock: t("kf.pdp.label.qtyMoreThanStock"),
    associatePrice: t("kf.pdp.label.associatePrice"),
    friendsFamilyPrice: t("kf.pdp.label.friendsFamilyPrice"),
    listPriceInfo:
      authorData.listpricetooltip ?? t("kf.pdp.label.listPriceInfo"),
    favSuccessMsg: t("kf.favorites.success"),
    favErrorMsg: t("kf.favorites.error"),
    installationTitle: t("kf.pdp.label.installationTitle"),
    nearestService: authorData.subCopy ?? t("kf.pdp.label.nearestService"),
    nearestInPerson: authorData.subCopy ?? t("kf.pdp.label.nearestInPerson"),
    edit: t("kf.pdp.label.edit"),
    change: t("kf.pdp.label.change"),
    eligibleZipCodeSuccess:
      authorData.serviceavailable ?? t("kf.pdp.label.eligibleZipCodeSuccess"),
    eligibleZipCodeError:
      authorData.errorMessage ?? t("kf.pdp.label.eligibleZipCodeError"),
    findaPro: t("kf.pdp.label.findaPro"),
    zipcode: t("kf.pdp.label.zipcode"),
    checkAvailability: t("kf.pdp.label.checkAvailability"),
    zipCodeGuidText: t("kf.pdp.label.zipCodeGuidText"),
    update: t("kf.pdp.label.updateBtn"),
    search: t("kf.pdp.label.searchBtn"),
    forText: t("kf.pdp.label.forText"),
    memberShipPrice: t("kf.pdp.label.memberShipPrice"),
    bathroomDesignService: t("kf.pdp.label.bathroomDesignService"),
    maxInventory: t("kf.pdp.label.maxInventory"),
    zipcodeEmptyError:
      authorData.zipcodevalidationmessage ??
      t("kf.pdp.label.zipcodeEmptyError"),
    validZipcode:
      authorData.zipcodevalidationmessage ?? t("kf.pdp.label.validZipcode"),
    percentOff: t("kf.pdp.label.percentOff"),
    serviceItemAdded: t("kf.pdp.label.serviceItemAdded"),
    serviceItemsAdded: t("kf.pdp.label.serviceItemsAdded"),
    serviceParts: t("kf.technical.label.serviceParts"),
    outOfStockError: authorData.invalidEmailMessage
      ? authorData.invalidEmailMessage
      : t("kf.pdp.error.outOfStock"),
    successTxt: t("kf.pdp.outOfStock.successTxt"),
    successMsg: authorData.outofStockConfirmMsg
      ? authorData.outofStockConfirmMsg
      : t("kf.pdp.outOfStock.thankMsg"),
    doneCTA: t("kf.pdp.outOfStock.doneCTA"),
    new: badgeText.new ?? t("kf.plp.label.new"),
    sale: badgeText.onSale ?? t("kf.plp.label.onSale"),
    exclusive: t("kf.plp.label.exclusive"),
    newExclusive: t("kf.plp.label.newExclusive"),
    discontinued: badgeText.discontinued
      ? badgeText.discontinued
      : t("kf.plp.label.discontinued"),
    shippingTitle:
      authorData.shippingTitle ?? t("kf.pdp.label.alert.shippingRestrictions"),
    shippingDescription:
      authorData.shippingDescription ??
      t("kf.pdp.label.alert.shippingRestrictionsDesc"),
    ariaLabel: {
      serviceAddToCart: t("kf.pdp.aria.label.addServiceToCart"),
      serviceLearnMore: t("kf.pdp.aria.label.serviceLearnMore"),
      designServiceAddToCart: t("kf.pdp.aria.label.addDesignServiceToCart"),
      designServiceLearnMore: t("kf.pdp.aria.label.designServiceLearnMore"),
      addProductToCart: t("kf.pdp.aria.label.addProductToCart", { name }),
      addProductService: t("kf.pdp.aria.label.addProductService", { name }),
      print: t("kf.pdp.label.aria.print", { productName: name }),
      share: t("kf.pdp.label.aria.share", { productName: name }),
      currentPage: t("kf.pdp.label.aria.currentPage"),
      page: t("kf.pdp.label.aria.page"),
      breadcrumb: t("kf.pdp.label.aria.breadcrumb"),
    },
    outOfStockErr:
      authorData.modalRightSectionNoStockMsg ??
      t("kf.pdp.label.reqItem.err.outOfStockErrMsg"),
    removeItem: t("kf.favorites.removeItem"),
    removeItemError: t("kf.favorites.removeItemError"),
    backInStock: t("kf.pdp.label.backInStock"),
    notApplicable: t("kf.pdp.label.notApplicable"),
    partNumber: t("kf.pdp.label.partNumber"),
    partName: t("kf.pdp.label.partName"),
    descReadMore: t("kf.pdp.label.descReadMore"),
    productAccordionText: {
      features: t("kf.pdp.label.features"),
      dimensions: t("kf.pdp.label.dimensions"),
      specs: t("kf.pdp.label.specs"),
      addOnServices: t("kf.pdp.label.addOnServices"),
      technologies: t("kf.pdp.label.technology"),
      productDetails: t("kf.pdp.label.productDetails"),
      material: t("kf.pdp.label.material"),
      installation: t("kf.pdp.label.installation"),
      hydrotherapy: t("kf.pdp.label.hydrotherapy"),
      rebates: t("kf.pdp.label.rebates"),
      props65Warning: t("kf.pdp.label.props65Warning"),
      selectAll: t("kf.technical.label.selectAll"),
      download: t("kf.technical.label.download"),
      installationSupport: t("kf.pdp.label.installationSupport"),
      designFiles: t("kf.technical.label.designFiles"),
      technicalInfo: t("kf.technical.label.technicalInfo"),
      file: t("kf.technical.label.file"),
      files: t("kf.technical.label.files"),
      specsSheet: t("kf.technical.label.specsSheet"),
      guides: t("kf.technical.label.guides"),
      installVideo: t("kf.technical.label.installVideo"),
      helpfulLinks: authorData.sectionHeading
        ? authorData.sectionHeading
        : t("kf.technical.label.helpfulLinks"),
      envDeclaration: t("kf.technical.label.envDeclaration"),
      installationGuide: t("kf.technical.label.installationGuide"),
      installationVideo: t("kf.technical.label.installationVideo"),
      userGuide: t("kf.technical.label.userGuide"),
      homeOwnerGuide: t("kf.technical.label.homeOwnerGuide"),
      homeOwnerGuideWithoutSPPDF: t(
        "kf.technical.label.homeOwnerGuideWithoutSPPDF"
      ),
      declareLabel: t("kf.technical.label.declareLabel"),
      tearSheet: t("kf.technical.label.tearSheet"),
      quickReference: t("kf.technical.label.quickReference"),
      warranty: t("kf.technical.label.warranty"),
      serviceParts: t("kf.technical.label.serviceParts"),
      twoDplan: t("kf.technical.label.twoDplan"),
      twoDside: t("kf.technical.label.twoDside"),
      twoDfront: t("kf.technical.label.twoDfront"),
      threeDfile: t("kf.technical.label.threeDfile"),
      bimFile: t("kf.technical.label.bimFile"),
      cutoutTemplate: t("kf.technical.label.cutoutTemplate"),
      partsDiagram: t("kf.pdp.label.partsDiagram"),
      installationGuidesSubTitle: t(
        "kf.technical.label.installationGuidesSubTitle"
      ),
      supportSubTitle: t("kf.technical.label.supportSubTitle"),
      icon: t("kf.technical.label.icon"),
      pdf: t("kf.literature.aria.label.pdf"),
      video: t("kf.technical.label.video"),
      viewMore: t("kf.pdp.label.viewMore"),
    },
    baseProduct: t("kf.pdp.label.baseProduct"),
    subTotal: t("kf.pdp.label.subTotal"),
    requiredItems: t("kf.pdp.label.requiredItems"),
    requiredItemsProduct: t("kf.pdp.label.productRequiredItem"),
    requiredItemsPrice: t("kf.pdp.label.priceRequiredItem"),
    zipCodes: t("kf.pdp.lable.zipcode"),
    ingredientsLabel: authorData.ingredientsLabel,
    saveToListText:t("kf.saveToList.save.header"),
    savedToListText:t("kf.saveToList.action.saved"),
    deleteFromListText:t("kf.myList.page.pdp.deletefromList")
  }

  return i18n
}

export default usePdpi18n
