import { convertStringToBoolean } from "@/utils/helper"
import { addDataLayerOnClick } from "./analytics"

const BrandSwitcher = props => {
  const { data, datalayerPageName } = props

  const handleOnClick = (e, item) => {
    addDataLayerOnClick(datalayerPageName, item)
    window.open(
      item.link
        ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
        .join("")
        .replace(".html", ""),
      `${convertStringToBoolean(item.linkToNewTab) ? "_blank" : "_self"}`,
      item.linkRef ? "noreferrer" : ""
    )
  }
  return (
    <div
      className={`brand-switcher ${
        datalayerPageName === "checkout" ? "checkout-brand" : ""
      }`}
    >
      <div className="gbh-nav-panel">
        <nav className="gbh-nav navbar navbar-xpand-sm navbar-dark justify-content-between">
          <ul className="gbh-nav__list navbar-nav">
            {data.items.map((item, index) => (
              <li className={`gbh-nav__item`} key={`brand-${index}`}>
                <a
                  className={`gbh-data-layer gbh-nav--link ${
                    item.highlight && item.highlight === "true"
                      ? "gbh-nav__link--active"
                      : ""
                  }`}
                  aria-label={`${item.brandName}. Go to ${item.brandName}'s Home Page`}
                  onClick={e => handleOnClick(e, item)}
                  role="link"
                >
                  {item.brandName}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default BrandSwitcher
