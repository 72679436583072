import React from "react"
import { useTranslation } from "next-i18next"
import Style from "@/components/VisualSearch/v1/UploadImage/uploadImage.module.scss"
import useViewport from "@/hooks/useViewport"
import QRCode from "@/components/VisualSearch/v1/QRCode"
import FileUpload from "@/components/VisualSearch/v1/UploadImage/FileUpload.view"
import UploadImageSection from "@/components/VisualSearch/v1/UploadImage/UploadImageSection.view"
import { getTranslations } from "@/components/VisualSearch/localization"
import {
  CloseIconGuide,
  CloseIconGuideMob,
} from "@/components/VisualSearch/v1/UploadImage/icons"

const UploadImage = props => {
  const { setImage = () => {}, category, setShowInfo, setSection } = props
  const device = useViewport()
  const { t } = useTranslation()
  const { photoGuidelineText } = getTranslations(t)

  return (
    <>
      <div className={Style.UploadImageContainer}>
        <div className="outer-wrapper position-relative visualContentwarpper">
          <FileUpload setImage={setImage} setSection={setSection} />
          {device?.isSmallTabView ? (
            <div className="mobile-wrapper ">
              <div className="close-container d-flex align-items-center justify-content-between">
                <span
                  className="close-Icon"
                  onClick={() =>
                    setShowInfo ? setShowInfo(false) : setSection("category")
                  }
                  tabIndex="0"
                  role="button"
                >
                  <CloseIconGuide />
                  <CloseIconGuideMob />
                </span>
                <div className="guide-photo d-flex align-items-center justify-content-center flex-1">
                  <p>{photoGuidelineText}</p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="how-it-works">
            <UploadImageSection category={category} />
          </div>
        </div>
      </div>
      <div className={Style.QrWrapper}>
        {!device?.isSmallTabView ? <QRCode /> : null}
      </div>
    </>
  )
}

export default UploadImage
