import React from "react"
import UploadImage from "@/components/VisualSearch/v1/UploadImage/index"
import Style from "@/components/VisualSearch/v1/CameraView/cameraview.module.scss"

const GuildePageMobile = props => {
  const { setShowInfo, category = "", setSection = () => {} } = props
  return (
    <div className={Style.CameraView}>
      <div className="cameraview-container">
        <UploadImage
          setShowInfo={setShowInfo}
          category={category}
          setSection={setSection}
        />
      </div>
    </div>
  )
}

export default GuildePageMobile
