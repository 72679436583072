import { useEffect, useState } from "react"
import Collapsible from "react-collapsible"
import { BrowserView } from "react-device-detect"
import SelectProductCategory from "@/components/VisualSearch/v1/SelectProductCategory"
import UploadImage from "@/components/VisualSearch/v1/UploadImage"
import LoadingData from "@/components/VisualSearch/v1/LoadingData"
import CameraView from "@/components/VisualSearch/v1/CameraView/index"
import useViewport from "@/hooks/useViewport"
import ComingSoon from "@/components/VisualSearch/v1/ComingSoon"
import ErrorFallback from "@/components/VisualSearch/v1/ErrorFallBack"
import Style from "@/components/VisualSearch/v1/visualsearch.module.scss"

const VisualSearch = props => {
  const { showVisualSearch = false } = props
  const [category, setCategory] = useState(null)
  const [section, setSection] = useState("category")
  const [image, setImage] = useState(null)
  const [openCam, setOpenCam] = useState(false)
  const device = useViewport()

  useEffect(() => {
    if (showVisualSearch === true) {
      document
        .querySelector("mavenoid-assistant")
        ?.classList?.add("mavenoid-active")
    } else {
      document
        .querySelector("mavenoid-assistant")
        ?.classList?.remove("mavenoid-active")
    }
  }, [showVisualSearch])

  useEffect(() => {
    if (image != null) setSection("loading")
  }, [image])

  useEffect(() => {
    const inpageSearch = device?.isExtraSmallScreen
      ? document.querySelector(".kf-react-button")
      : document.querySelector(".inpage-search__header-section")
    if (openCam && device?.isSmallScreen) {
      document.body.style.overflow = "hidden"
      inpageSearch.scrollIntoView({ behavior: "smooth" })
    } else {
      document.body.style.overflow = ""
    }
    return () => {
      document.body.style.overflow = ""
    }
  }, [openCam])

  const updateCategoryItems = input => {
    setCategory(input[0]?.category)
    if (input?.[0]?.comingSoon == true) {
      setSection("comingSoon")
      setOpenCam(false)
    } else {
      setSection("upload")
    }
  }

  const selectSection = () => {
    switch (section) {
      case "category":
        return (
          <SelectProductCategory
            updateCategoryItems={updateCategoryItems}
            setOpenCam={setOpenCam}
          />
        )
      case "comingSoon":
        return <ComingSoon category={category} setSection={setSection} />
      case "error":
        return <ErrorFallback setSection={setSection} setOpenCam={setOpenCam} />
      case "upload":
        return (
          <>
            <BrowserView>
              <UploadImage
                setImage={setImage}
                category={category}
                setSection={setSection}
              />
            </BrowserView>

            {device?.isSmallTabView ? (
              <div className="mobile-container">
                <CameraView
                  setImage={setImage}
                  back={() => setSection("category")}
                  category={category}
                  setOpenCam={setOpenCam}
                  setSection={setSection}
                />
              </div>
            ) : null}
          </>
        )
      case "loading":
        return (
          <LoadingData
            setSection={setSection}
            image={image}
            category={category}
            setOpenCam={setOpenCam}
          />
        )
      default:
        return (
          <SelectProductCategory
            updateCategoryItems={updateCategoryItems}
            setOpenCam={setOpenCam}
          />
        )
    }
  }
  return (
    <div className={Style.VisualSearchContainer}>
      <Collapsible open={showVisualSearch}>{selectSection()}</Collapsible>
    </div>
  )
}
export default VisualSearch
