import { useState, useEffect, Fragment } from "react"
import Link from "next/link"
import FindStoreUtilNav from "@/components/FindStoreUtilNav/v1/FindStoreUtilNav"
import AuthUtilityNav from "@/components/Default/AuthUtilityNav/v1/AuthUtilityNav"
import _get from "lodash/get"
import { MY_LISTS_PAGE_PATH } from "@/constants/routes"
import CountryUtilNav from "@/components/Default/CountryUtilNav/v1/CountryUtilNav"
import MiniCart from "@/components/Shared/MiniCart/v1/MiniCart"
import { isElementExcluded, toggeleBodyScroll } from "@/utils/helper"
import styles from "@/components/Shared/index.module.scss"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import {
  selectFavoritesState,
  getFavorites,
  getMyAccountListsWithLineItems,
} from "@/store/features/favoritesSlice"
import {
  locationState,
  setFindAStoreActive,
  setFindAStoreShow,
} from "@/store/features/locationSlice"
import { aemAxiosInstance } from "@/constants/api"

import { useDispatch, useSelector } from "react-redux"

import utilityStyles from "@/components/Shared/UtilityWidgets/v1/UtilityWidgets.module.scss"
import { selectGenericState } from "@/store/features/genericSlice"
import useWindowDimensions from "@/hooks/useWindowDimensions"
import { getShortenedUrl } from "@/utils/helper"
import { getConfig } from "@/constants/config"
import useAuthUtilityNavi18n from "@/i18n/useAuthUtilityNavi18n"
import { getSrc } from "@/components/Shared/helper"
import { dataLayerForUtilityClicks } from "@/components/Shared/HeaderNavTabs/analytics/"
import { GLOBAL_CONFIGURATION_ENDPOINT } from "@/constants/index"

const UtilityNavHeader = ({ data, eventHandler }) => {
  const [favoritesPagePath, setFavoritesPagePath] = useState("/")
  const { ":items": { store: { zipCodeContent = "" } = {} } = {} } = data
  const { findAStoreShow, findAStoreActive } = useSelector(locationState)
  const {
    store: { isOpen, mainCategoryActive, isRegionOpen, isAuthNavOpen },
    callbacks: {
      // eslint-disable-next-line no-console
      setRegionNav = () => console.log("Event Not Defined"),
      // eslint-disable-next-line no-console
      setAuthNav = () => console.log("Event Not Defined"),
    },
  } = eventHandler
  const [languageUrl, setLanguageUrl] = useState("")
  const { winWidth } = useWindowDimensions()
  const {
    datalayerPageName,
    featureFlags = {},
    globalConfig: { internationalization: { language = "en" } = {} } = {},
  } = useSelector(selectGenericState)
  const isMobile = typeof window !== "undefined" && window.innerWidth <= 1200
  const { isAuth } = useSelector(selectAuthState)
  const { MYSQLCONNSTR_ENABLE_SHOPPING_LISTS_V2: isSaveProductToList = false } =
    featureFlags
  const { favorites, saveToListLineItems } = useSelector(selectFavoritesState)
  const [authNavShow, setAuthNavShow] = useState(false)
  const [authNavActive, setAuthNavActive] = useState(false)
  const [favoritesPage, setFavoritesPage] = useState([])
  const [countryNavShow, setCountryNavShow] = useState(false)
  const [countryNavActive, setCountryNavActive] = useState(false)
  const [findAStoreMob, setFindAStoreMob] = useState([])
  eventHandler.callbacks.toggleFindAStore = () => {
    dispatch(setFindAStoreActive(false))
  }
  useEffect(() => {
    if (findAStoreShow) {
      dispatch(setFindAStoreActive(true))
    }
    if (authNavShow) {
      setAuthNavActive(true)
    }
    if (countryNavShow) {
      setCountryNavActive(true)
    }
  }, [findAStoreShow, authNavShow, countryNavShow])

  useEffect(() => {
    if (isSaveProductToList) {
      setFavoritesPagePath(`/${language}${MY_LISTS_PAGE_PATH}`)
    } else {
      getConfig().then(async config => {
        const myFavPath = _get(config, "general.myFavoritesPagePath", "")
        setFavoritesPagePath(await getShortenedUrl(myFavPath ?? "/"))
      })
    }
    const shortenUrl = async () => {
      if (data?.enableLanguageSwitcher) {
        const shortendUrl = await getShortenedUrl(data?.languageUrl)
        setLanguageUrl(shortendUrl)
      }
    }
    shortenUrl()
  }, [isSaveProductToList])

  useEffect(() => {
    if (isAuth) {
      dispatch(getFavorites())
        .unwrap()
        .then(rsp => {
          if (rsp && rsp !== null) {
            setFavoritesPage(rsp.lineItems)
          } else {
            setFavoritesPage([])
          }
        })
      if (isSaveProductToList) {
        dispatch(getMyAccountListsWithLineItems())
          .unwrap()
          .then(res => {
            if (Object.keys(res[0]).length) {
              setFavoritesPage(Object.keys(res[0]))
            } else {
              setFavoritesPage([])
            }
          })
      }
    }
  }, [isAuth, isSaveProductToList])
  useEffect(() => {
    if (favorites?.lineItems && favorites?.lineItems.length > 0) {
      setFavoritesPage(favorites?.lineItems)
    } else {
      setFavoritesPage([])
    }
  }, [favorites])

  useEffect(() => {
    if (!findAStoreActive) {
      setTimeout(() => {
        dispatch(setFindAStoreShow(false))
      }, 1000)
    }
    if (!authNavActive) {
      setTimeout(() => {
        setAuthNavShow(false)
      }, 1000)
    }
    if (!countryNavActive) {
      if (winWidth <= 1200) {
        setCountryNavShow(false)
      } else {
        setTimeout(() => {
          setCountryNavShow(false)
        }, 1000)
      }
    }
  }, [findAStoreActive, authNavActive, countryNavActive])

  useEffect(() => {
    if (Object.keys(saveToListLineItems).length && isAuth) {
      setFavoritesPage(Object.keys(saveToListLineItems))
    } else {
      setFavoritesPage([])
    }
  }, [Object.keys(saveToListLineItems).length])

  const toggleFindAStore = () => {
    dataLayerForUtilityClicks(
      data[":items"]?.store?.findStore || "Find a Store",
      themeChange
    )
    if (!findAStoreShow) {
      dispatch(setFindAStoreShow(true))
      toggeleBodyScroll(true)
    } else {
      if (findAStoreActive) {
        dispatch(setFindAStoreActive(false))
        toggeleBodyScroll(false)
      } else {
        dispatch(setFindAStoreShow(false))
        toggeleBodyScroll(false)
      }
    }
  }
  const themeChange =
    typeof window !== "undefined" &&
    winWidth <= 1200 &&
    (document.body.classList.contains("nav-light-theme") ||
      document.body.classList.contains("nav-dark-theme"))

  const iconFavorite = "/icons/favorites-icon.svg"
  const iconFavoriteBlack = "/icons/favorites-full-black.svg"
  const iconLocation = "/icons/location-icon.svg"

  const staticTexts = useAuthUtilityNavi18n()

  const toggleAuthNav = () => {
    setAuthNavShow(!authNavActive)
    toggeleBodyScroll(!authNavActive)
    setAuthNavActive(!authNavActive)
  }

  const toggleCountryNav = () => {
    if (!countryNavActive) {
      setCountryNavShow(true)
      toggeleBodyScroll(true)
    } else {
      setCountryNavActive(false)
      toggeleBodyScroll(false)
    }
  }

  const clickEvent = e => {
    if (
      !isElementExcluded(e, ["header-container", "modal-popup", "auth-modal"])
    ) {
      !isMobile && !setAuthNavActive(false)
      !isMobile && dispatch(setFindAStoreActive(false))
      !isMobile && setCountryNavActive(false)
      toggeleBodyScroll(false)
    }
  }

  useEffect(() => {
    if (findAStoreActive) {
      setAuthNavActive(false)
      setCountryNavActive(false)
      document.addEventListener("click", clickEvent)
    } else {
      document.removeEventListener("click", clickEvent)
    }

    return () => document.removeEventListener("click", clickEvent)
  }, [findAStoreActive])

  useEffect(() => {
    if (authNavActive) {
      dispatch(setFindAStoreActive(false))
      setCountryNavActive(false)
      document.addEventListener("click", clickEvent)
    } else {
      document.removeEventListener("click", clickEvent)
    }

    return () => document.removeEventListener("click", clickEvent)
  }, [authNavActive])

  useEffect(() => {
    if (countryNavActive) {
      setAuthNavActive(false)
      dispatch(setFindAStoreActive(false))
      document.addEventListener("click", clickEvent)
    } else {
      document.removeEventListener("click", clickEvent)
    }

    return () => document.removeEventListener("click", clickEvent)
  }, [countryNavActive])
  const style = { display: "list-item" }
  const displayNone = { display: "none" }
  const dispatch = useDispatch()
  const onClickSignIn = e => {
    if (!isAuth) {
      dispatch(setAuthModalVisibility({ show: true }))
      e.stopPropagation()
      e.preventDefault()
    }
    dataLayerForUtilityClicks(
      staticTexts?.favorites,
      themeChange,
      favoritesPagePath
    )
  }

  const onAuthMenuClick = payload => {
    setAuthNavActive(payload)
    setAuthNav(payload)
  }

  const { globalConfig } = useSelector(selectGenericState)

  const getNavMobileData = async () => {
    const result = await aemAxiosInstance({
      url: `${GLOBAL_CONFIGURATION_ENDPOINT}?path=${globalConfig.contentFragmentListConfig.map.mobileNavigationFooterLinks}`,
      method: "GET",
    })
    const commonTopics = JSON.parse(result.data.commonTopics)
    setFindAStoreMob(commonTopics)
  }

  const { contentFragmentListConfig : { map:fragmentList = {}} = {} } = globalConfig;

  useEffect(() => {
    if(Object.keys(fragmentList).length) getNavMobileData()
  }, [fragmentList])

  return (
    <div className={styles.utilityNavHeaderWrapper}>
      <div className="utilitynavheader">
        <div className="gbh-utility-nav">
          <div className="gbh-utility-navbar">
            <ul role="list">
              {isMobile && findAStoreMob.length ? (
                <Fragment>
                  <li
                    className="gbh-nav__signin"
                    {...(isOpen && !mainCategoryActive && !isRegionOpen
                      ? { style: style }
                      : {})}
                  >
                    <div className={utilityStyles.utilityWidgetsWrapper}>
                      <div
                        role="list"
                        className="utility-widgets utility-widgets__brand-switcher"
                      >
                        <AuthUtilityNav
                          show={authNavShow}
                          active={authNavActive}
                          toggleAuthNav={toggleAuthNav}
                          setAuthNav={setAuthNav}
                          onAuthMenuClick={onAuthMenuClick}
                        />
                      </div>
                    </div>
                  </li>
                  <li
                    className="gbh-nav__findStore position_unset"
                    {...(isOpen &&
                    !mainCategoryActive &&
                    !isRegionOpen &&
                    !isAuthNavOpen
                      ? { style: style }
                      : {})}
                  >
                    <a
                      role="button"
                      tabIndex="0"
                      aria-live="polite"
                      aria-labelledby="find-ada"
                      className={
                        "gbh-data-layer gbh-nav__findStore--link cmp-find-place"
                      }
                      onClick={() => toggleFindAStore()}
                    >
                      {themeChange ? <img src={iconLocation} alt="" /> : null}
                      {findAStoreMob[2]?.label || "Find a Store"}
                    </a>
                    <FindStoreUtilNav
                      show={findAStoreShow}
                      active={findAStoreActive}
                      eventHandler={eventHandler}
                      zipCodeContent={zipCodeContent}
                    />
                  </li>
                  <li
                    role="listitem"
                    className="wishlist-widget mobile_block position_unset"
                    {...(isOpen && !mainCategoryActive && !isRegionOpen
                      ? { style: style }
                      : {})}
                  >
                    <Link
                      href={favoritesPagePath}
                      aria-label="View your wishlist"
                      className="gbh-data-layer"
                      onClick={onClickSignIn}
                    >
                      <img
                        alt="View your wishlist"
                        aria-hidden="true"
                        role="presentation"
                        src={getSrc(
                          themeChange,
                          favoritesPage,
                          iconFavoriteBlack,
                          iconFavorite
                        )}
                      />
                      {themeChange ? (
                        <span>{findAStoreMob[3]?.label}</span>
                      ) : null}
                    </Link>
                  </li>
                  <span id="country-ada" style={displayNone}>
                    Country Selector{" "}
                    {process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country} -{" "}
                  </span>
                  <li
                    className="gbh-nav__region region_arrowdropdown"
                    id={
                      process.env.NEXT_PUBLIC_COUNTRYNAME.toLowerCase()
                        .split(" ")
                        .join("_") ||
                      data?.country.toLowerCase().split(" ").join("_")
                    }
                    {...(isOpen && !mainCategoryActive ? { style: style } : {})}
                  >
                    <a
                      role="button"
                      tabIndex="0"
                      aria-labelledby="country-ada"
                      aria-expanded={countryNavActive}
                      onClick={() => {
                        setRegionNav(true)
                        toggleCountryNav()
                      }}
                      className={`gbh-data-layer gbh-nav__region--link active-country ${
                        data.enableLanguageSwitcher ? "selected-country" : ""
                      } ${countryNavActive ? "expand" : ""}`}
                      data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"${datalayerPageName}:global header","internalLinkURL":"n/a","internalLinkName":"${(
                        process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country
                      )?.toLowerCase()} - ${(
                        data?.language || process.env.NEXT_PUBLIC_LANGUAGENAME
                      )?.toLowerCase()}","eventName":"country select","internalLinkType":"global header : navigation","eventType":"click","eventAction":"country select"}`}
                    >
                      {process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country} -{" "}
                      {data?.language || process.env.NEXT_PUBLIC_LANGUAGENAME}
                    </a>
                    {data.enableLanguageSwitcher && (
                      <a
                        role="button"
                        href={languageUrl}
                        rel="noreferrer"
                        aria-label={data.languageLabel}
                        className="country-selector"
                      >
                        {data.languageLabel}
                      </a>
                    )}

                    {findAStoreMob?.[4]?.utilitynavheader[":items"]
                      ?.countryselector &&
                      countryNavShow && (
                        <CountryUtilNav
                          show={true}
                          countryselector={
                            findAStoreMob[4]?.utilitynavheader[":items"]
                              ?.countryselector
                          }
                          active={countryNavActive}
                          eventHandler={eventHandler}
                          toggleCountryNav={toggleCountryNav}
                          datalayerPageName={datalayerPageName}
                        />
                      )}
                  </li>
                </Fragment>
              ) : null}

              <li
                className="gbh-nav__findStore mobile_none"
                {...(isOpen &&
                !mainCategoryActive &&
                !isRegionOpen &&
                !isAuthNavOpen
                  ? { style: style }
                  : {})}
              >
                <a
                  role="button"
                  tabIndex="0"
                  aria-live="polite"
                  aria-labelledby="find-ada"
                  className={
                    "gbh-data-layer gbh-nav__findStore--link cmp-find-place"
                  }
                  onClick={() => toggleFindAStore()}
                >
                  {themeChange ? <img src={iconLocation} alt="" /> : null}
                  {data[":items"]?.store?.findStore || "Find a Store"}
                </a>
                <FindStoreUtilNav
                  show={findAStoreShow}
                  active={findAStoreActive}
                  eventHandler={eventHandler}
                  zipCodeContent={zipCodeContent}
                />
              </li>
              <span id="country-ada" style={displayNone}>
                Country Selector{" "}
                {process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country} -{" "}
              </span>
              <li
                className="gbh-nav__region region_arrowdropdown mobile_none"
                id={
                  process.env.NEXT_PUBLIC_COUNTRYNAME.toLowerCase()
                    .split(" ")
                    .join("_") ||
                  data?.country.toLowerCase().split(" ").join("_")
                }
                {...(isOpen && !mainCategoryActive ? { style: style } : {})}
              >
                <a
                  role="button"
                  tabIndex="0"
                  aria-labelledby="country-ada"
                  aria-expanded={countryNavActive}
                  onClick={() => {
                    setRegionNav(true)
                    toggleCountryNav()
                  }}
                  className={`gbh-data-layer gbh-nav__region--link active-country ${
                    data.enableLanguageSwitcher ? "selected-country" : ""
                  } ${countryNavActive ? "expand" : ""}`}
                  data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"global header","internalLinkZoneName":"${datalayerPageName}:global header","internalLinkURL":"n/a","internalLinkName":"${(
                    process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country
                  )?.toLowerCase()} - ${(
                    data?.language || process.env.NEXT_PUBLIC_LANGUAGENAME
                  )?.toLowerCase()}","eventName":"country select","internalLinkType":"global header : navigation","eventType":"click","eventAction":"country select"}`}
                >
                  {process.env.NEXT_PUBLIC_COUNTRYNAME || data?.country} -{" "}
                  {data?.language || process.env.NEXT_PUBLIC_LANGUAGENAME}
                </a>
                {data.enableLanguageSwitcher && (
                  <a
                    role="button"
                    href={languageUrl}
                    rel="noreferrer"
                    aria-label={data.languageLabel}
                    className="country-selector"
                  >
                    {data.languageLabel}
                  </a>
                )}
                {data[":items"]?.countryselector && (
                  <CountryUtilNav
                    show={countryNavShow}
                    countryselector={data[":items"]?.countryselector}
                    active={countryNavActive}
                    eventHandler={eventHandler}
                    toggleCountryNav={toggleCountryNav}
                    datalayerPageName={datalayerPageName}
                  />
                )}
              </li>
              <li
                className="gbh-nav__signin mobile_none"
                {...(isOpen && !mainCategoryActive && !isRegionOpen
                  ? { style: style }
                  : {})}
              >
                <div className={utilityStyles.utilityWidgetsWrapper}>
                  <div
                    role="list"
                    className="utility-widgets utility-widgets__brand-switcher"
                  >
                    <AuthUtilityNav
                      show={authNavShow}
                      active={authNavActive}
                      toggleAuthNav={toggleAuthNav}
                      setAuthNav={setAuthNav}
                      onAuthMenuClick={onAuthMenuClick}
                    />
                    {(winWidth > 1200 || themeChange) && (
                      <>
                        <div role="listitem" className="wishlist-widget">
                          <Link
                            href={favoritesPagePath}
                            aria-label="View your wishlist"
                            className="gbh-data-layer"
                            onClick={onClickSignIn}
                          >
                            <img
                              alt="View your wishlist"
                              aria-hidden="true"
                              role="presentation"
                              src={getSrc(
                                themeChange,
                                favoritesPage,
                                iconFavoriteBlack,
                                iconFavorite
                              )}
                            />
                            {themeChange ? (
                              <span>
                                {isSaveProductToList
                                  ? staticTexts?.myLists
                                  : staticTexts.favorites}
                              </span>
                            ) : null}
                          </Link>
                        </div>
                        {data?.linkCart && !themeChange && (
                          <div
                            role="listitem"
                            className="cart-indicator-widget"
                          >
                            <MiniCart
                              eventHandler={eventHandler}
                              source={"from desk"}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UtilityNavHeader
