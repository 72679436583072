import { cropCanvas } from "@/components/VisualSearch/v1/utils"

/**
 * Webcam component
 */
export class WebCam {
  /**
   * Constructor
   * @param {WebCam} webcamElement
   */
  constructor(webcamElement) {
    this.canvasElement = document.createElement("canvas")
    this.webcamElement = webcamElement
    this.mediaStream = null
    this.facingMode = ["environment", "user"]
    this.flashLight = null
    this.autoFocus = null
    this.portWidth = window.innerWidth
    this.portHeight = window.innerHeight
    this.portAspectRatio = this.portWidth / this.portHeight
  }
  /**
   * Initialization
   */
  async setupCamera() {
    if (navigator.mediaDevices.getUserMedia !== undefined) {
      let width = window.innerWidth
      width = width - (width % 4)
      let height = window.innerHeight
      height = height - (height % 4)
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          facingMode: "environment",
          aspectRatio: window.matchMedia("(orientation: landscape)").matches
            ? width / height
            : height / width,
        },
      })
      if ("srcObject" in this.webcamElement) {
        this.webcamElement.srcObject = mediaStream
        this.mediaStream = mediaStream
      } else {
        this.webcamElement.src = window.URL.createObjectURL(mediaStream)
      }
      this.webcamElement.onloadedmetadata = e => {
        this.webcamElement.play()
      }
      this.webcamElement.style.setProperty("width", "auto", "important")
      this.webcamElement.style.setProperty("height", "100%", "important")
      this.webcamElement.style.setProperty("max-width", "none", "important")
    }
  }
  /**
   * Stops Media stream
   */
  stopMediaStream() {
    if (this.mediaStream) {
      if (this.mediaStream.getVideoTracks && this.mediaStream.getAudioTracks) {
        this.mediaStream.getVideoTracks().forEach(track => {
          this.mediaStream.removeTrack(track)
          track.stop()
        })
        this.mediaStream.getAudioTracks().forEach(track => {
          this.mediaStream.removeTrack(track)
          track.stop()
        })
      } else {
        this.mediaStream.stop()
      }
    }
  }

  /**
   * Renders Captured Image
   * @return {Object} Object with width and height of captured Image
   */
  _drawImage() {
    const videoWidth = this.webcamElement.videoWidth
    const videoHeight = this.webcamElement.videoHeight
    let imageWidth = this.webcamElement.videoWidth
    let imageHeight = this.webcamElement.videoHeight
    const imageAspectRatio = imageWidth / imageHeight
    let top = 0
    let left = 0
    if (this.portAspectRatio < imageAspectRatio) {
      left = (imageWidth * (1 - this.portAspectRatio / imageAspectRatio)) / 2
      imageWidth = imageWidth * (this.portAspectRatio / imageAspectRatio)
    } else {
      top = (imageHeight * (1 - imageAspectRatio / this.portAspectRatio)) / 2
      imageHeight = (imageHeight * imageAspectRatio) / this.portAspectRatio
    }
    const context = this.canvasElement.getContext("2d")
    this.canvasElement.width = videoWidth
    this.canvasElement.height = videoHeight
    context.drawImage(this.webcamElement, 0, 0, videoWidth, videoHeight)
    const result = cropCanvas(
      this.canvasElement,
      left,
      top,
      imageWidth,
      imageHeight
    )
    this.canvasElement = result
    return { imageHeight, imageWidth }
  }
  /**
   * Captures image from canvas
   * @param {Object} param0
   * @return {Object} Object with base64 value
   */
  takeBase64Photo({ type, quality } = { type: "png", quality: 1 }) {
    const { imageHeight, imageWidth } = this._drawImage()
    const base64 = this.canvasElement.toDataURL("image/" + type, quality)
    return { base64, imageHeight, imageWidth }
  }
}
