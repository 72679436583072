import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import Modal from "react-bootstrap/Modal"
import useTrigger from "@/hooks/useTrigger"
import { getPartList } from "@/store/features/pdpSlice"
import { preventBodyScroll } from "@/utils/helper"
import { getProjectionPartListData } from "@/utils/ProjectionPartlist.js"
import PartsViewerModal from "@/components/PartsViewer/v2/PartViewerModal"
import { LoaderSpinner, CloseIcon } from "@/components/PartsViewer/v2/icon"
import Style from "@/components/PartsViewer/v2/partviewer.module.scss"
import { updateMetaContent } from "@/utils/index.js"

const PartsViewer = props => {
  const {
    page = "",
    analyticsCustomData = null,
    onClose = () => {},
    showModal = false,
    productId = "",
    fileMap = [],
    partId = "",
  } = props
  const [parts, setParts] = useState("")
  const [partsheight, setPartsHeight] = useState("")
  const [trigger, triggerAction] = useTrigger()
  const [loading, setLoading] = useState({ status: true, error: false })
  const dispatch = useDispatch()

  useEffect(() => {
    const metaVal = "width=device-width, initial-scale=1.0"

    if (showModal) {
      updateMetaContent(
        "viewport",
        `${metaVal}, maximum-scale=1.0, user-scalable=false`
      )
      preventBodyScroll(true)
      document
        .querySelector("mavenoid-assistant")
        ?.classList?.add("mavenoid-suppress")
      document.addEventListener("touchmove", handlerViewport, false)
    }
    return () => {
      document
        .querySelector("mavenoid-assistant")
        ?.classList?.remove("mavenoid-suppress")
      document.removeEventListener("touchmove", handlerViewport)
      updateMetaContent("viewport", metaVal)
    }
  }, [showModal])

  useEffect(() => {
    if (productId && fileMap) {
      const obj = {}
      fileMap.forEach(item => {
        obj[item.id] = item.title
      })
      productInfo(obj)
    }
  }, [productId])

  const handlerViewport = event => {
    if (event?.scale !== 1) {
      event.preventDefault()
    }
  }

  const productInfo = async obj => {
    const resp = await dispatch(getPartList({ productId })).unwrap()
    if (resp?.SVGFiles) {
      const { Info, SVGFiles } = resp
      const diagramFiles = fileMap.map(val => {
        const getfile = SVGFiles?.filter(v => v?.FileName === val?.id)
        return { ...getfile?.[0], ...val }
      })
      const activePart = diagramFiles?.filter(val => val.FileName === partId)
      const selectedFile =
        activePart.length !== 0 ? activePart[0] : diagramFiles[0]
      const priceList = await getProjectionPartListData(selectedFile?.Parts)
      selectedFile.prices = priceList
      setParts({
        fileMap: obj,
        partsInfo: { Info, SVGFiles: diagramFiles },
        selectedFile,
      })
      setLoading({ status: false, error: false })
    } else setLoading({ status: false, error: true })
  }

  const closeModal = () => {
    setParts("")
    setLoading({ status: true, error: false })
    preventBodyScroll(false)
    onClose()
  }

  const getPartDiagramCloseEventInfo = JSON.stringify({
    eventAction: page + ":modal:interactive parts diagram:close",
    eventName: page + ":modal:interactive parts diagram:close",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "close",
    internalLinkPosition: page,
    internalLinkType: page + ":close",
    internalLinkZoneName: page + ":modal:interactive parts diagram",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  })
  let text1
  let text2
  let title

  if (parts?.partsInfo?.Info !== undefined) {
    text1 = parts?.partsInfo?.Info?.["Product Name"]
    text2 = parts?.partsInfo?.Info?.["Short Description"]
    title = text1.replace(new RegExp(text2, "g"), "")
  }
  const prefeedbackController = () => {}
  return (
    <Modal
      show={showModal}
      closeButtonAnalytics={getPartDiagramCloseEventInfo}
      analyticsCustomData={analyticsCustomData || null}
      className={`partviwer-modal ${Style.PartDiagramViewer}`}
      fullscreen={true}
    >
      <div
        className={`partviewer-modal-container part-${partsheight} ${
          !parts ? "loading-text" : ""
        }`}
      >
        <button className="close-btns" onClick={closeModal}>
          <CloseIcon />
        </button>
        {!loading.status ? (
          <PartsViewerModal
            trigger={trigger}
            triggerAction={triggerAction}
            files={parts?.partsInfo?.SVGFiles}
            fileMap={parts?.fileMap}
            prefeedbackController={prefeedbackController}
            skuImage={parts?.partsInfo?.Info?.Thumbnail}
            title={title}
            sku={parts?.partsInfo?.Info?.Cod}
            setParts={setParts}
            selectedFile={parts?.selectedFile}
            loading={loading}
            analytics={{ page: page }}
            setPartsHeight={setPartsHeight}
          />
        ) : (
          <LoaderSpinner />
        )}
      </div>
    </Modal>
  )
}

export default PartsViewer
