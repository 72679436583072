import {
  GuideFaucet,
  GuideValve,
  GuideEmpty,
} from "@/components/VisualSearch/v1/CameraView/icons"

export const getGuide = (category, content) => {
  const { faucetGuide, toiletGuide, volveGuide, defaultGuide } = content
  switch (category) {
    case "Faucets":
      return {
        image: <GuideFaucet />,
        text: faucetGuide,
      }
    case "Toilet":
      return {
        image: <GuideEmpty />,
        text: toiletGuide,
      }
    case "Valve":
      return { image: <GuideValve />, text: volveGuide }
    case "Shower":
      return { image: <GuideEmpty />, text: defaultGuide }
    default:
      return {
        image: <GuideEmpty />,
        text: defaultGuide,
      }
  }
}
