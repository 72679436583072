import { store } from "@/store"
import { showToast } from "@/store/features/genericSlice"
import { addProduct, removeItems } from "@/store/features/favoritesSlice"
import { getAnalyticsData, productSuggestionLinkAnalytics } from "@/components/ProductDetail/v3/AddToCart/analytics"
import { apim } from "@/constants/api"
import { CATALOG_NOTIFY_ENDPOINT } from "@/constants/routes"
import { SHOP_TOILET_SEATS } from "@/constants/index"

export const callFavorite = (e, data, addFavoriteCallBack = () => {}) => {
  const {
    isFavorite,
    favItem = [],
    staticTexts: {
      removeItem = "",
      removeItemError = "",
      favSuccessMsg = "",
      favErrorMsg = "",
    } = {},
    ctProductId = "",
    isAuth,
    toasterContent,
    sku = "",
    ctData,
  } = data

  e.preventDefault()

  if (isFavorite) {
    favItem?.map(fav => {
      if (
        fav.productId === ctProductId &&
        (ctData?.variants?.find(
          vt => vt.sku === sku && vt?.id === fav?.variantId
        ) ||
          (ctData?.masterVariant?.id === fav?.variantId &&
            ctData?.masterVariant?.sku === sku)) &&
        isAuth
      ) {
        store
          .dispatch(removeItems([fav.id]))
          .unwrap()
          .then(res => {
            store.dispatch(
              showToast({
                message: toasterContent.unfavoriteSuccessMessage || removeItem,
                isVisible: true,
              })
            )
            addFavoriteCallBack(res?.lineItems)
          })
          .catch(() => {
            store.dispatch(
              showToast({
                message: removeItemError,
                isVisible: true,
              })
            )
          })
      }
    })
  } else {
    store
      .dispatch(addProduct(sku))
      .unwrap()
      .then(res => {
        store.dispatch(
          showToast({
            message: toasterContent.successMessage || favSuccessMsg,
            isVisible: true,
          })
        )
        addFavoriteCallBack(res?.lineItems)
      })
      .catch(() => {
        store.dispatch(
          showToast({
            message: favErrorMsg,
            isVisible: true,
          })
        )
      })
  }
  window.keyFocusCallBack = e.target.id
}

export const addDatalayerForLocalStoreOrShowroom = data => {
  const { localStoreOrShowroomRef, productAnalyticsData } = data
  if (localStoreOrShowroomRef.current) {
    const localStoreOrShowroomAnchorLink =
      localStoreOrShowroomRef.current.querySelector("a")
    if (localStoreOrShowroomAnchorLink) {
      localStoreOrShowroomAnchorLink.classList.add("gbh-data-layer")
      localStoreOrShowroomAnchorLink.setAttribute(
        "data-gbh-data-layer",
        getAnalyticsData(
          "local store or showroom",
          localStoreOrShowroomAnchorLink.getAttribute("href")
        )
      )
      localStoreOrShowroomAnchorLink.setAttribute(
        "data-gbh-data-layer-custom",
        productAnalyticsData.stringProductInfo
      )
    }
  }
}

export const callNotification = data => {
  const { email = "", sku = "", callBackNotify } = data
  if (email) {
    const body = {
      customer: {
        id: "",
        firstName: "",
        lastName: "",
        email: email,
      },
      sku,
      pdpURL: window.location.href,
    }
    apim
      .post(CATALOG_NOTIFY_ENDPOINT, body)
      .then(res => {
        if (res) {
          callBackNotify(true)
        }
      })
      .catch(() => {
        callBackNotify(false)
      })
  }
}

export const isAnchor = (element) => {
  return element?.closest('a');
};

export const isMatchingAnchorText = (anchor, text) => {
  return anchor?.textContent?.toLowerCase() === text?.toLowerCase();
};

export const getAnchorUrl = (anchor) => {
  return anchor?.getAttribute('href');
};

export const handleProductSuggestion = (e, productInfo) => {
  const anchor = isAnchor(e.target);
  if (!anchor) return;
  if (!isMatchingAnchorText(anchor, SHOP_TOILET_SEATS)) return;
  const anchorUrl = getAnchorUrl(anchor);
  productSuggestionLinkAnalytics(SHOP_TOILET_SEATS, anchorUrl, productInfo);
};