import React, { useEffect } from "react"
import { useTranslation } from "next-i18next"
import { getTranslations } from "@/components/VisualSearch/localization"
import Style from "@/components/VisualSearch/v1/ErrorFallBack/index.module.scss"
import { sanitizeInnerHtml } from "@/utils/helper"

const ErrorFallBack = props => {
  const { setSection, setOpenCam } = props
  const { t } = useTranslation()
  const { errorText, tryAgainBtn, errorSubText } = getTranslations(t)

  useEffect(() => {
    setOpenCam(false)
  }, [])

  return (
    <div className={Style.ErrorContainer}>
      <div className="error-section">
        <p className="sorry-section">{errorText}</p>
        <p className="try-section">{sanitizeInnerHtml(errorSubText)}</p>
        <button
          className="back-button"
          onClick={() => {
            setSection("category")
          }}
        >
          <p>{tryAgainBtn}</p>
        </button>
      </div>
    </div>
  )
}

export default ErrorFallBack
