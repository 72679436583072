import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _isEmpty from "lodash/isEmpty"
import _get from "lodash/get"
import {
  getShortenedUrl,
  sanitizeInnerHtml,
  validateField,
  validatePhone,
} from "@/utils/helper"
import { selectGenericState, showToast } from "@/store/features/genericSlice"
import ReCaptcha, {
  validateRecaptcha,
} from "@/components/core/ReCaptcha/ReCaptcha"
import Modal from "@/components/core/Modal/Modal"
import Input from "@/components/core/Input/Input"
import Button from "@/components/core/Button/Button"
import DatePicker from "@/components/core/DatePicker/DatePicker"
import { convertToPhoneNumber } from "@/components/RegisterOnlineProduct/v1/RegisterOnlineHelper"
import {
  addBookAppointmentAnalytics,
  getLegalPageAnalytics,
  getRecaptchaBookAppointmentAnalytics,
  handleExternalLinkAnalytics,
} from "@/components/StoreAppointment/v1/analytics"
import leftArrow from "@/public/icons/left-arrow.svg"
import leftArrowDisabled from "@/public/icons/left-arrow-disabled.svg"
import rightArrowDisabled from "@/public/icons/right-arrow-disabled.svg"
import emailImg from "@/public/icons/email.svg"
import phoneImg from "@/public/icons/phone-icon.svg"
import rightArrow from "@/public/icons/right-arrow.svg"
import MissingImage from "@/public/images/image-missing.png"
import styles from "@/components/StoreAppointment/v1/index.module.scss"

const StoreAppointmentView = props => {
  const {
    bpNumber = "",
    daysData = [],
    getAppointmentDate = () => {},
    getUrlData = {},
    hideInput = false,
    hidePast = false,
    i18n = {},
    isSuccess = false,
    legalPathLinks = {},
    monthsData = [],
    onModalClose = () => {},
    requestAppointment = () => {},
    successData = {},
    getStoreData = {},
    loading = false,
    internalLinkType = "",
  } = props
  const { isShowAppointmentModal } = useSelector(selectGenericState)
  const [activeId, setActiveId] = useState(null)
  const [selectedTime, setSelectedTime] = useState("")
  const [currentSlotPage, setCurrentSlotPage] = useState(1)
  const [availableTimeData, setAvailableTimeData] = useState([])
  const tomorrowFullDate = new Date(+new Date() + 86400000)
  const nextDay =
    tomorrowFullDate.getFullYear() +
    "/" +
    (tomorrowFullDate.getMonth() + 1) +
    "/" +
    tomorrowFullDate.getDate()
  const [dateInputVal, setDateInputVal] = useState(nextDay)
  const [dayVal, setDayVal] = useState(tomorrowFullDate.getDay())
  const [data, setData] = useState({
    date: { value: "", error: false, message: "" },
    firstName: { value: "", error: false, message: "" },
    lastName: { value: "", error: false, message: "" },
    email: { value: "", error: false, message: "" },
    phone: { value: "", error: false, message: "" },
    time: { value: selectedTime, error: false, timeError: "" },
  })
  const [activeDay, setActiveDay] = useState(tomorrowFullDate)
  const [isSelectFromPicker, setSelectFromPicker] = useState(false)
  const [isNextDay, setisNextDay] = useState(nextDay === dateInputVal)
  const [storeDetailUrl, setStoreDetailUrl] = useState("")
  const dispatch = useDispatch()

  useEffect(() => {
    setisNextDay(dateInputVal === nextDay)
  }, [])

  useEffect(() => {
    getAvailableTimes()
  }, [getStoreData])

  useEffect(() => {
    if (!_isEmpty(getStoreData)) {
      getAvailableTimes()
      getAvailableTimeData()
    }
  }, [dayVal, isNextDay])

  useEffect(() => {
    if (successData) {
      getShortenedUrl(successData.storeDetailUrl).then(res => {
        setStoreDetailUrl(res)
      })
    }
  }, [successData])

  useEffect(() => {
    setDayVal(activeDay.getDay())
    setDateInputVal(
      `${activeDay.getFullYear()}/${
        activeDay.getMonth() + 1
      }/${activeDay.getDate()}`
    )
  }, [activeDay])

  const clearErrors = () => {
    const curData = data

    if (curData) {
      for (const item in curData) {
        if (curData.hasOwnProperty(item)) {
          curData[item].error = false
          curData[item].message = ""
        }
      }
      setData(curData)
    }
  }

  const getAvailableTimes = () => {
    const {
      getStoreData: {
        Appointment_Hours_Sunday__c: appointmentHoursSunday,
        Appointment_Hours_Saturday__c: appointmentHoursSat,
        Appointment_Hours_M_F__c: appointmentHoursMF,
      },
    } = props
    if (!_isEmpty(getStoreData)) {
      const checkDisabledTime = []
      let splitAppointmentHoursInArr = []
      if (dayVal === 0) {
        splitAppointmentHoursInArr =
          appointmentHoursSunday !== "Closed"
            ? appointmentHoursSunday?.split(";")
            : []
      } else if (dayVal === 6) {
        splitAppointmentHoursInArr =
          appointmentHoursSat !== "Closed"
            ? appointmentHoursSat?.split(";")
            : []
      } else {
        splitAppointmentHoursInArr = appointmentHoursMF?.split(";")
      }
      if (splitAppointmentHoursInArr?.length === 0 && !isSelectFromPicker) {
        setActiveDay(new Date(activeDay.setDate(activeDay.getDate() + 1)))
        return
      }
      splitAppointmentHoursInArr?.forEach(item =>
        checkDisabledTime.push({ time: item })
      )
      setAvailableTimeData(checkDisabledTime)
    }
  }

  const getTime = (id, time) => {
    setActiveId(id)
    setData({ ...data, time: { error: false, value: time, message: "" } })
    setSelectedTime(time)
  }

  const handleSubmit = () => {
    clearErrors()
    setTimeout(async () => {
      const obj = {}
      let valid = true
      const errorMessage = []

      if (
        !data.firstName.value ||
        !(await validateField("name", data.firstName.value))
      ) {
        obj.firstName = {
          ...data.firstName,
          error: true,
          message: i18n.fnameError,
        }
        valid = false
        errorMessage.push(i18n.fnameError)
      }
      if (
        !data.lastName.value ||
        !(await validateField("name", data.lastName.value))
      ) {
        obj.lastName = {
          ...data.lastName,
          error: true,
          message: i18n.lnameError,
        }
        valid = false
        errorMessage.push(i18n.lnameError)
      }
      if (!data.email.value) {
        obj.email = {
          ...data.email,
          error: true,
          message: i18n.noEmailError,
        }
        valid = false
        errorMessage.push(i18n.noEmailError)
      } else if (!(await validateField("email", data.email.value))) {
        obj.email = {
          ...data.email,
          error: true,
          message: i18n.validEmail,
        }
        valid = false
        errorMessage.push(i18n.validEmail)
      }
      if (!validatePhone(data.phone.value)) {
        obj.phone = {
          ...data.phone,
          error: true,
          message: i18n.validPhone,
        }
        valid = false
        errorMessage.push(i18n.validPhone)
      }

      if (_isEmpty(dateInputVal)) {
        obj.date = { ...data.date, error: true, message: i18n.dateError }
        valid = false
        errorMessage.push(i18n.dateError)
      }

      if (_isEmpty(selectedTime)) {
        obj.time = {
          ...data.time,
          error: true,
          timeError: i18n.timeError,
        }
        valid = false
        errorMessage.push(i18n.timeError)
      }

      const newData = { ...data, ...obj }
      setData(newData)
      if (valid) {
        const appointmentDate =
          monthsData[new Date(dateInputVal).getMonth()] +
          " " +
          new Date(dateInputVal).getDate() +
          ", " +
          new Date(dateInputVal).getFullYear() +
          " " +
          i18n.at +
          " " +
          selectedTime
        const body = {
          Interaction_Type__c: "Appointment",
          Preferred_Email__c: _get(data, "email.value", ""),
          PreferredPhone: _get(data, "phone.value", ""),
          BP_Number__c: bpNumber,
          LastName__c: _get(data, "lastName.value", ""),
          FirstName__c: _get(data, "firstName.value", ""),
          Appointment_Date_Time__c:
            dateInputVal.replaceAll("/", "-") + " " + selectedTime,
        }

        validateRecaptcha().then(res => {
          if (res.success) {
            getAppointmentDate(appointmentDate)
            requestAppointment(body, appointmentDate)
          } else {
            dispatch(
              showToast({
                message: i18n?.genError,
                isVisible: true,
              })
            )
          }
        })
      } else {
        const appointmentDate =
          monthsData[new Date(dateInputVal).getMonth()] +
          " " +
          new Date(dateInputVal).getDate() +
          ", " +
          new Date(dateInputVal).getFullYear()
        addBookAppointmentAnalytics({
          storeData: getStoreData,
          action: "cmp:click",
          status: "failure",
          errorMessage: errorMessage,
          appointmentDate: appointmentDate,
          appointmentTime: selectedTime,
        })
      }
    }, 100)
  }

  const handleExternalLinkClick = (e, storeData, title, link) => {
    e.preventDefault()
    handleExternalLinkAnalytics(storeData, title, link)
  }

  const onBlurInput = async (name, value) => {
    switch (name) {
      case "fname":
        return !value
          ? setData({
              ...data,
              firstName: {
                error: true,
                value: value,
                message: i18n.fnameError,
              },
            })
          : setData({
              ...data,
              firstName: { error: false, value: value, message: "" },
            })
      case "lname":
        return !value
          ? setData({
              ...data,
              lastName: {
                error: true,
                value: value,
                message: i18n.lnameError,
              },
            })
          : setData({
              ...data,
              lastName: { error: false, value: value, message: "" },
            })
      case "email":
        if (!value)
          return setData({
            ...data,
            email: {
              error: true,
              value: value,
              message: i18n.noEmailError,
            },
          })
        if (!(await validateField("email", value)))
          setData({
            ...data,
            email: {
              error: true,
              value: value,
              message: i18n.validEmail,
            },
          })
        else
          setData({
            ...data,
            email: { error: false, value: value, message: "" },
          })
        break
      case "phone":
        if (!validatePhone(value)) {
          setData({
            ...data,
            phone: {
              error: true,
              value: value,
              message: i18n.validPhone,
            },
          })
        } else
          setData({
            ...data,
            phone: { error: false, value: value, message: "" },
          })
        break
      default:
        return
    }
  }

  const checkDisabled = data => {
    if (
      data.firstName.value &&
      data.lastName.value &&
      data.email.value &&
      data.phone.value
    )
      return false
    return true
  }

  const showPrevious = () => {
    if (currentSlotPage !== 1) {
      setCurrentSlotPage(currentSlotPage => currentSlotPage - 1)
    }
  }

  const showNext = () => {
    if (currentSlotPage < Math.ceil(availableTimeData.length / 12)) {
      setCurrentSlotPage(currentSlotPage => currentSlotPage + 1)
    }
  }

  const getAvailableTimeData = () => {
    let getavailableTimes = []
    const startIndex = currentSlotPage * 12 - 12
    const endIndex = startIndex + 12
    if (!_isEmpty(getStoreData)) {
      availableTimeData.sort(function (a, b) {
        return (
          new Date(nextDay + " " + a.time) - new Date(nextDay + " " + b.time)
        )
      })
      getavailableTimes = availableTimeData.slice(startIndex, endIndex)
    }
    return getavailableTimes
  }

  const getDayVal = val => {
    setSelectFromPicker(true)
    setDayVal(val)
    setCurrentSlotPage(1)
  }

  const getInputValue = val => {
    setDateInputVal(val)
  }

  const handleChangeDatePicker = val => {
    setSelectFromPicker(true)
    setisNextDay(val === nextDay)
    setDateInputVal(val)
    setData({ ...data, date: { value: val, error: false, message: "" } })
  }

  const modalClose = () => {
    onModalClose()
    setActiveId(null)
    setDateInputVal(
      new Date().getFullYear() +
        "/" +
        (new Date().getMonth() + 1) +
        "/" +
        new Date().getDate()
    )
    setData({
      date: { value: "", error: false, message: "" },
      firstName: { value: "", error: false, message: "" },
      lastName: { value: "", error: false, message: "" },
      email: { value: "", error: false, message: "" },
      phone: { value: "", error: false, message: "" },
      time: { value: "", error: false, timeError: "" },
    })
    setSelectedTime("")
    setDayVal(tomorrowFullDate.getDay())
  }

  return (
    <div className={styles.storeAppointMentWrapper}>
      <div className="store-appointment">
        <Modal
          onModalClose={modalClose}
          modalTitle={getUrlData.title}
          showModal={isShowAppointmentModal}
          closeButtonAnalytics={getLegalPageAnalytics(
            getStoreData,
            isSuccess ? "thankyou:close" : "close"
          )}
        >
          {!isSuccess ? (
            <React.Fragment>
              <div className="row ml-0 mr-0">
                <div className="col-12 store-appointment__header">
                  {!_isEmpty(getUrlData) && (
                    <React.Fragment>
                      <h2 className="store-appointment__heading">
                        {getUrlData.title}
                      </h2>
                      <div className="store-appointment__desc">
                        {sanitizeInnerHtml(getUrlData.description)}
                      </div>
                    </React.Fragment>
                  )}
                  <p className="store-appointment__location">{i18n.location}</p>
                  {!_isEmpty(getStoreData) && (
                    <React.Fragment>
                      <p className="store-appointment__store">
                        {getStoreData.AEM_Microsite_Name__c}
                      </p>
                      <p className="store-appointment__address">
                        {getStoreData.BillingStreet +
                          ", " +
                          getStoreData.BillingCity +
                          ", " +
                          getStoreData.BillingState +
                          " " +
                          getStoreData.BillingPostalCode}
                      </p>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div
                className="row ml-0 mr-0"
                role="group"
                aria-label={i18n.stepOne + ": " + i18n.selectData}
              >
                <div className="col-12 store-appointment__select-date">
                  <p className="store-appointment__step">
                    {i18n.stepOne + ": " + i18n.selectData}
                  </p>
                </div>
                <div className="col-12 store-appointment__select-date-time">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <DatePicker
                        daysData={daysData}
                        monthsData={monthsData}
                        hideInput={hideInput}
                        i18n={i18n}
                        dateValue={val => handleChangeDatePicker(val)}
                        getInputValue={val => getInputValue(val)}
                        error={data.date.error}
                        data={data}
                        getDayfromInput={value => getDayVal(value)}
                        hidePast={hidePast}
                        isShowModal={isShowAppointmentModal}
                        startFromTomorrow={true}
                        activeDay={activeDay}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="store-appointment__available-time">
                        <p className="store-appointment__available-txt">
                          {i18n.availableTimes}
                        </p>
                        <div className="store-appointment__available-arrow">
                          {currentSlotPage === 1 ? (
                            <img
                              aria-disabled="true"
                              role="button"
                              tabIndex="0"
                              aria-label={i18n.ariaLabel.prevSlots}
                              src={leftArrowDisabled?.src}
                              alt={i18n.leftArrowAlt}
                              className="store-appointment__left-arrow-disable"
                            />
                          ) : (
                            <img
                              role="button"
                              tabIndex="0"
                              aria-label={i18n.ariaLabel.prevSlots}
                              src={leftArrow?.src}
                              alt={i18n.leftArrowAlt}
                              onClick={showPrevious}
                              className="store-appointment__arrow"
                            />
                          )}
                          {currentSlotPage ===
                          Math.ceil(availableTimeData.length / 12) ? (
                            <img
                              aria-disabled="true"
                              role="button"
                              tabIndex="0"
                              aria-label={i18n.ariaLabel.nextSlots}
                              src={rightArrowDisabled?.src}
                              alt={i18n.rightArrowAlt}
                              className="store-appointment__right-arrow-disable"
                            />
                          ) : (
                            <img
                              role="button"
                              tabIndex="0"
                              aria-label={i18n.ariaLabel.nextSlots}
                              src={rightArrow?.src}
                              alt={i18n.rightArrowAlt}
                              className="store-appointment__arrow"
                              onClick={showNext}
                            />
                          )}
                        </div>
                      </div>
                      <div
                        className="store-appointment__available-container"
                        role="listbox"
                      >
                        {getAvailableTimeData().map((item, i) => {
                          return (
                            <div
                              tabIndex="0"
                              role="option"
                              aria-selected={i === activeId}
                              className={`store-appointment__available-time-card ${
                                i === activeId ? "--active" : ""
                              }`}
                              key={i}
                              onClick={() => getTime(i, item.time)}
                            >
                              {item.time}
                            </div>
                          )
                        })}
                      </div>
                      {data.time.error && _isEmpty(selectedTime) ? (
                        <span
                          className="input-field__error-message"
                          role="alert"
                        >
                          {i18n.timeError}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="row ml-0 mr-0"
                role="group"
                aria-label={i18n.stepTwo + ": " + i18n.fillYourContact}
              >
                <div className="col-12 store-appointment__contact-heading">
                  <p className="store-appointment__step">
                    {i18n.stepTwo + ": " + i18n.fillYourContact}
                  </p>
                  <p className="store-appointment__required">
                    {i18n.requiredFields}
                  </p>
                </div>
                <div className="col-12 store-appointment__contact-details">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Input
                        id="firstname"
                        type="text"
                        value={data.firstName.value}
                        maxLength={40}
                        placeholder={`${i18n.firstName}*`}
                        label={`${i18n.firstName}*`}
                        onChange={e =>
                          setData({
                            ...data,
                            firstName: {
                              value: e.target.value,
                              error: false,
                              message: "",
                            },
                          })
                        }
                        showError={data.firstName.error}
                        errorMessage={data.firstName.message}
                        onBlur={() =>
                          onBlurInput("fname", data.firstName.value)
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <Input
                        id="lastname"
                        type="text"
                        value={data.lastName.value}
                        maxLength={40}
                        placeholder={`${i18n.lastName}*`}
                        label={`${i18n.lastName}*`}
                        onChange={e =>
                          setData({
                            ...data,
                            lastName: {
                              value: e.target.value,
                              error: false,
                              message: "",
                            },
                          })
                        }
                        showError={data.lastName.error}
                        errorMessage={data.lastName.message}
                        onBlur={() => onBlurInput("lname", data.lastName.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Input
                        id="email"
                        type="text"
                        value={data.email.value}
                        maxLength={60}
                        placeholder={`${i18n.email}*`}
                        label={`${i18n.email}*`}
                        onChange={e =>
                          setData({
                            ...data,
                            email: {
                              value: e.target.value,
                              error: false,
                              message: "",
                            },
                          })
                        }
                        showError={data.email.error}
                        errorMessage={data.email.message}
                        prefix={<img src={emailImg?.src} alt={i18n.email} />}
                        onBlur={() => onBlurInput("email", data.email.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Input
                        id="phone"
                        type="text"
                        value={data.phone.value}
                        maxLength={60}
                        placeholder={`${i18n.phone}*`}
                        label={`${i18n.phone}*`}
                        onChange={e =>
                          setData({
                            ...data,
                            phone: {
                              value: convertToPhoneNumber(e.target.value),
                              error: false,
                              message: "",
                            },
                          })
                        }
                        showError={data.phone.error}
                        errorMessage={data.phone.message}
                        prefix={<img src={phoneImg?.src} alt={i18n.phone} />}
                        onBlur={() => onBlurInput("phone", data.phone.value)}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Button
                        label={i18n.submit}
                        size="medium"
                        type="primary flexible"
                        onClick={handleSubmit}
                        disabled={checkDisabled(data)}
                        loading={loading}
                        role="button"
                      ></Button>
                      <ReCaptcha
                        getAnalyticsData={getRecaptchaBookAppointmentAnalytics(
                          internalLinkType
                        )}
                      />
                    </div>
                    <ul className="col-12 store-appointment__privacy-policy">
                      {!_isEmpty(legalPathLinks) && (
                        <React.Fragment>
                          <li>
                            <a
                              href={legalPathLinks?.privacyPolicy}
                              className="gbh-data-layer"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={e =>
                                handleExternalLinkClick(
                                  e,
                                  getStoreData,
                                  i18n.privacyPolicy?.toLowerCase(),
                                  legalPathLinks.privacyPolicy
                                )
                              }
                            >
                              {i18n.privacyPolicy}
                            </a>
                          </li>
                          <li>
                            <a
                              href={legalPathLinks?.subjectAccessRequest}
                              className="gbh-data-layer"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={e =>
                                handleExternalLinkClick(
                                  e,
                                  getStoreData,
                                  i18n.subjectAccess?.toLowerCase(),
                                  legalPathLinks.subjectAccessRequest
                                )
                              }
                            >
                              {i18n.subjectAccess}
                            </a>
                          </li>
                          <li>
                            <a
                              href={legalPathLinks?.termsOfService}
                              className="gbh-data-layer"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={e =>
                                handleExternalLinkClick(
                                  e,
                                  getStoreData,
                                  i18n.termsOfService?.toLowerCase(),
                                  legalPathLinks.termsOfService
                                )
                              }
                            >
                              {i18n.termsOfService}
                            </a>
                          </li>
                        </React.Fragment>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="store-appointment__confirmation">
                <div>
                  <div
                    className="store-appointment__thank-you-container"
                    role="alert"
                  >
                    {!_isEmpty(getUrlData) && (
                      <React.Fragment>
                        <p className="store-appointment__thank-you">
                          {getUrlData.confirmationTitle}
                        </p>
                        <div className="store-appointment__thank-you-desc">
                          {sanitizeInnerHtml(
                            getUrlData.confirmationDescription
                          )}
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div>
                  {successData && (
                    <React.Fragment>
                      <div className="store-appointment__details-container">
                        <div className="store-appointment__details-left">
                          <div className="store-appointment__details">
                            <p className="store-appointment__details-title">
                              {i18n.dateAndTime}
                            </p>
                            <p className="store-appointment__details-content">
                              {successData.selectedDate}
                            </p>
                          </div>
                          <div className="store-appointment__details">
                            <p className="store-appointment__details-title">
                              {i18n.location}
                            </p>
                            <div className="store-appointment__details-content-main">
                              <p className="store-appointment__details-content">
                                {successData.storeName}
                              </p>
                              <p className="store-appointment__details-content">
                                {successData.address}
                              </p>
                            </div>
                            <div className="store-appointment__details-content-main">
                              {successData.sameHours ? (
                                <React.Fragment>
                                  <p className="store-appointment__details-time">
                                    {i18n.wholeWeek +
                                      " " +
                                      successData.openingHours}
                                  </p>
                                </React.Fragment>
                              ) : _isEmpty(successData.saturday) ? (
                                <React.Fragment>
                                  <p className="store-appointment__details-time">
                                    {i18n.weekDays +
                                      " " +
                                      successData.openingHours}
                                  </p>
                                  <p className="store-appointment__details-time">
                                    {i18n.weekEnds +
                                      " " +
                                      successData.weekendHours}
                                  </p>
                                </React.Fragment>
                              ) : (
                                <React.Fragment>
                                  <p className="store-appointment__details-time">
                                    {i18n.weekDays +
                                      " " +
                                      successData.openingHours}
                                  </p>
                                  <p className="store-appointment__details-time">
                                    {i18n.sat + " " + successData.saturday}
                                  </p>
                                  <p className="store-appointment__details-time">
                                    {i18n.sun + " " + successData.sunday}
                                  </p>
                                </React.Fragment>
                              )}
                            </div>
                            <a
                              href={storeDetailUrl}
                              className="store-appointment__details-content store-appointment__details-store-link gbh-data-layer"
                              data-gbh-data-layer={getLegalPageAnalytics(
                                getStoreData,
                                "thankyou:view store details",
                                storeDetailUrl
                              )}
                            >
                              {i18n.viewStore}
                            </a>
                          </div>
                          <div className="store-appointment__details">
                            <p className="store-appointment__details-title">
                              {i18n.contactInfo}
                            </p>
                            <p className="store-appointment__details-content">
                              {successData.contactName}
                            </p>
                            <p className="store-appointment__details-content">
                              {successData.contactEmail}
                            </p>
                            <p className="store-appointment__details-content">
                              {successData.contactPhone}
                            </p>
                          </div>
                        </div>
                        <div className="store-appointment__details-right">
                          <picture>
                            <source
                              media="(min-width: 481px) and (max-width:990px) and (orientation: landscape)"
                              srcSet={
                                !_isEmpty(successData.storeImgMobileLandscape)
                                  ? successData.storeImgMobileLandscape
                                  : MissingImage?.src
                              }
                            />
                            <source
                              media="(max-width:480px)"
                              srcSet={
                                !_isEmpty(successData.storeImgMobilePortrait)
                                  ? successData.storeImgMobilePortrait
                                  : MissingImage
                              }
                            />
                            <img
                              src={
                                !_isEmpty(successData.storeImgDesktop)
                                  ? successData.storeImgDesktop
                                  : MissingImage?.src
                              }
                              alt={i18n.noImage}
                            />
                          </picture>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
                <div className="row ml-0 mr-0">
                  <div className="col-12 store-appointment__close-container">
                    <Button
                      label={i18n.done}
                      size="medium"
                      type="primary flexible"
                      onClick={modalClose}
                      customClass="store-appointment__close-btn"
                      className="gbh-data-layer"
                      data-gbh-data-layer={getLegalPageAnalytics(
                        getStoreData,
                        "thankyou:done"
                      )}
                      useDefaultClass={true}
                      role="button"
                    ></Button>
                  </div>
                </div>
              </div>
            </React.Fragment>
          )}
        </Modal>
      </div>
    </div>
  )
}

export default StoreAppointmentView
