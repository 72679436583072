import React, { useEffect, useState } from "react"
import { dataUrlToFile, debounce } from "@/components/VisualSearch/v1/utils"
import { WebCam } from "@/components/VisualSearch/v1/CameraView/WebCam"

const Camera = props => {
  const { captureTrigger = false, setCaptureTrigger, handlerResult } = props
  let wcstream = null
  const [webcamStream, setWebcamStream] = useState({})
  const loadWebcam = async () => {
    if (wcstream != null) wcstream.stopMediaStream()
    const webCamElement = document.getElementById("webcam")
    if (null == webCamElement) return
    const webCam = new WebCam(webCamElement)
    webCam.setupCamera().catch(() => {})
    setWebcamStream(webCam)
    wcstream = webCam
  }
  useEffect(() => {
    loadWebcam()
    const debouncedWebcamEvent = debounce(loadWebcam, 200)
    window.addEventListener("resize", debouncedWebcamEvent)
    window.addEventListener("orientationchange", debouncedWebcamEvent)
    const timer = setTimeout(() => {
      const elements = document.getElementsByTagName("mavenoid-assistant")
      if (elements.length > 0) {
        document.querySelector("mavenoid-assistant").style.display = "none"
      }
    }, 100)
    return () => {
      wcstream.stopMediaStream()
      window.removeEventListener("resize", debouncedWebcamEvent)
      window.removeEventListener("orientationchange", debouncedWebcamEvent)
      document.querySelector("mavenoid-assistant").style.display = "block"
      clearTimeout(timer)
    }
  }, [])
  useEffect(() => {
    if (captureTrigger) {
      takePhoto()
      setCaptureTrigger(false)
    }
  }, [captureTrigger])

  const takePhoto = async () => {
    const capturedData = webcamStream.takeBase64Photo({
      type: "jpeg",
      quality: 1,
    })
    webcamStream.stopMediaStream()
    const imageFile = await dataUrlToFile(capturedData.base64, "input.jpg")
    handlerResult({
      width: capturedData.imageWidth,
      height: capturedData.imageHeight,
      file: imageFile,
      dataURL: capturedData.base64,
    })
  }
  return (
    <video id="webcam" className="video-container" autoPlay playsInline muted />
  )
}
export default Camera
