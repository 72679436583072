import faucetImage from "@/components/VisualSearch/v1/SelectProductCategory/Image/faucets.png"
import toiletImage from "@/components/VisualSearch/v1/SelectProductCategory/Image/toilet.png"
import valveImage from "@/components/VisualSearch/v1/SelectProductCategory/Image/valve.png"
import showerImage from "@/components/VisualSearch/v1/SelectProductCategory/Image/shower.png"
import bathImage from "@/components/VisualSearch/v1/SelectProductCategory/Image/bath.png"
import sinkImage from "@/components/VisualSearch/v1/SelectProductCategory/Image/sink.png"
import toiletSeatImage from "@/components/VisualSearch/v1/SelectProductCategory/Image/toilet_seat.png"
import showerDoorImage from "@/components/VisualSearch/v1/SelectProductCategory/Image/shower_door.png"

export const imageInformation = [
  {
    imageUrl: faucetImage,
    imageName: "Faucet",
  },
  {
    imageUrl: toiletImage,
    imageName: "Toilet",
  },
  {
    imageUrl: valveImage,
    imageName: "Valve",
  },
  {
    imageUrl: showerImage,
    imageName: "Shower",
  },
  {
    imageUrl: toiletSeatImage,
    imageName: "Toilet Seat",
    comingSoon: true,
  },
  {
    imageUrl: showerDoorImage,
    imageName: "Shower Door",
    comingSoon: true,
  },
  {
    imageUrl: sinkImage,
    imageName: "Sink",
    comingSoon: true,
  },
  {
    imageUrl: bathImage,
    imageName: "Bath",
    comingSoon: true,
  },
]

export const scrollOptions = {
  className: "os-theme-dark cmp-carousel__whole",
  style: "height:auto",
  scrollbars: {
    visibility: "auto",
    autoHide: "never",
  },
}
