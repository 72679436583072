export const getStickNavEventInfo = linkName => {
  const menuName = linkName?.toLowerCase()
  return {
    eventAction: `find a pro results:sticky nav links:${menuName}`,
    eventName: `find a pro results:sticky nav links:${menuName}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: menuName,
    internalLinkPosition: "find a pro results",
    internalLinkType: "find a pro results:navigation",
    internalLinkZoneName: "find a pro results:sticky nav links",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
}
export const addAnalyticsData = (count, zipcodeVal) => {
  const eventInfo = {
    searchCount: sessionStorage.getItem("proSearches")
      ? Number(sessionStorage.getItem("proSearches")) + 1
      : 1,
    searchResult: count,
    searchTerm: zipcodeVal,
  }
  sessionStorage.setItem("proSearches", eventInfo.searchCount)
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:show",
    page,
    eventInfo,
  })
}

export const addDataLayerOnMenuItemClick = linkName => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const eventInfo = getStickNavEventInfo(linkName)
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
  })
}

export const getEventInfoForFindAproResultsView = (linkUrl, isForBathroom) => {
  const type = isForBathroom
    ? "main content area:bathroom design services"
    : "hero:change location"
  const internalLinkName = isForBathroom
    ? "bathroom design services"
    : "change location"
  const zoneName = isForBathroom ? "main content area​" : "hero"
  const internalLinkType = "find a pro results:navigation"
  return JSON.stringify({
    clickInternalLinks: "true",
    eventAction: `find a pro results:${type}`,
    eventName: `find a pro results:${type}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName,
    internalLinkPosition: "find a pro results",
    internalLinkType,
    internalLinkURL: linkUrl,
    internalLinkZoneName: `find a pro results:${zoneName}`,
  })
}
