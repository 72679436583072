import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { useTranslation } from "next-i18next"
import qs from "qs"
import { resetPassword } from "@/services/auth.service"
import { getAnonymousToken } from "@/constants/api"
import { requestForChangePassword } from "@/services/ct.service"

import { setAuthModalVisibility } from "@/store/features/authSlice"

import { getPasswordRegExp, validatePassword } from "@/utils/helper"

import {
  addAnalyticsShowAuthModal,
  addAnalyticsShowRequirements,
  addAnalyticsResetPassword,
} from "@/components/ChangePassword/analytics"

import { getTranslations } from "@/components/ChangePassword/localization"

import Input from "@/components/core/Input/Input"
import Button from "@/components/core/Button/Button"
import ToolTip from "@/components/core/ToolTip/ToolTip"
import PasswordRequirements from "@/components/ChangePassword/PasswordRequirements"

import Style from "@/components/ChangePassword/index.module.scss"

const ChangePassword = () => {
  const { t } = useTranslation("common")
  const staticText = getTranslations(t)

  const {
    passwordLabelText = "",
    resetPasswordLabelText = "",
    passwordRequirementsText = "",
    reEnterPasswordText = "",
    resetPasswordText = "",
    newPasswordDescriptionText = "",
    reenterPasswordLabelErrorMessage = "",
    successMessageText = "",
    passwordSuccessText = "",
    signinText = "",
    pwdErrorText = "",
    passwordAuthErrorText = "",
    passwordMatchErrorText = "",
    recentPasswordText = "",
  } = staticText

  const [showRequirements, setShowRequirements] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState({
    value: "",
    error: false,
    errorMssg: "",
  })
  const [rePassword, setRePassword] = useState({
    value: "",
    error: false,
    errorMssg: "",
  })
  const [passwordRegExp, setPasswordRegExp] = useState({
    regex: "",
    passwordMessage: [],
  })

  const dispatch = useDispatch()

  useEffect(() => {
    getPasswordRegExp().then(getPasswordRegEx => {
      setPasswordRegExp({
        regex: getPasswordRegEx.regex ?? "",
        passwordMessage: getPasswordRegEx.passwordMessage ?? [],
      })
    })
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }, [showSuccess])

  const clearError = () => {
    setPassword({ ...password, error: false, errorMssg: "" })
    setRePassword({ ...rePassword, error: false, errorMssg: "" })
  }

  const onBlurInput = (name, value) => {
    switch (name) {
      case "pwd": {
        if (!value)
          return setPassword({
            ...password,
            error: true,
            errorMssg: pwdErrorText,
          })
        if (!localStorage.getItem("passwordChangeRequired")) {
          const email =
            new URLSearchParams(window.location.search).get("email") ?? ""
          const emailVal = email ? email.split(/[@.]/) : []
          const passwordData = emailVal?.filter(el => el.length > 1)

          validatePassword(value, [...passwordData]).then(res => {
            if (!res)
              setPassword({
                ...password,
                error: true,
                errorMssg: passwordAuthErrorText,
              })
            else setPassword({ ...password, error: false, errorMssg: "" })
          })
        }
        break
      }
      case "re-pwd":
        if (!value)
          return setRePassword({
            ...rePassword,
            error: true,
            errorMssg: reenterPasswordLabelErrorMessage,
          })
        if (password.value !== rePassword.value)
          setRePassword({
            ...rePassword,
            error: true,
            errorMssg: passwordMatchErrorText,
          })
        else setRePassword({ ...rePassword, error: false, errorMssg: "" })
        break
      default:
        return
    }
  }

  const successCb = () => {
    setShowSuccess(true)
    setLoading(false)
    localStorage.removeItem("passwordChangeRequired")
    sessionStorage.removeItem("OTPPassword")
    sessionStorage.removeItem("userIdForOTP")
  }

  const errorCB = err => {
    setLoading(false)
    if (err?.errorCode && err?.errorCode === "E0000014") {
      setPassword({ ...password, error: true, errorMssg: recentPasswordText })
    } else if (err)
      setPassword({
        ...password,
        error: true,
        errorMssg: err,
      })
  }

  const handleResetPassword = async () => {
    clearError()
    let valid = true
    if (!localStorage.getItem("passwordChangeRequired")) {
      const email = new URLSearchParams(window.location.search).get("email")
      const emailVal = email ? email.split(/[@.]/) : []
      const passwordData = emailVal?.filter(el => el.length > 1)
      const isValidPassword = await validatePassword(password.value, [
        ...passwordData,
      ])
      if (!isValidPassword) {
        setPassword({
          ...password,
          error: true,
          errorMssg: passwordAuthErrorText,
        })
        valid = false
      } else {
        setPassword({ ...password, error: false, errorMssg: "" })
      }
    }
    if (password.value !== rePassword.value) {
      setRePassword({
        ...rePassword,
        error: true,
        errorMssg: passwordMatchErrorText,
      })
      valid = false
    }
    if (valid) {
      setLoading(true)
      const { recoveryToken } = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      })
      if (localStorage.getItem("passwordChangeRequired")) {
        changePassword(password.value)
        return
      }
      resetPassword(recoveryToken, password.value, successCb, errorCB)
    }
    addAnalyticsResetPassword()
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const changePassword = async newPassword => {
    await getAnonymousToken(true, "change_password")
    const res = await requestForChangePassword({
      oldPassword: sessionStorage.getItem("OTPPassword"),
      newPassword,
      id: sessionStorage.getItem("userIdForOTP"),
    })
    if (res && res.status === 200) {
      successCb()
    } else {
      errorCB(res.data)
    }
  }

  const handleShowAuthModal = () => {
    dispatch(setAuthModalVisibility({ show: true }))
    addAnalyticsShowAuthModal()
  }

  const handleShowRequirements = () => {
    setShowRequirements(!showRequirements)
    addAnalyticsShowRequirements()
  }

  return (
    <div className={Style.ChangePassword}>
      <div className="wrapper">
        {!showSuccess ? (
          <React.Fragment>
            <section className="header">
              <h2>{resetPasswordText}</h2>
              <span>{newPasswordDescriptionText}</span>
            </section>
            <Input
              id="kf-change-password"
              type="password"
              value={password.value}
              maxLength={40}
              placeholder={`${passwordLabelText}`}
              label={`${passwordLabelText}`}
              onChange={e =>
                setPassword({ ...password, value: e.target.value })
              }
              showError={password.error}
              errorMessage={password.errorMssg}
              onBlur={e => onBlurInput("pwd", e.target.value)}
            />
            <span
              className="password-requirements"
              onClick={handleShowRequirements}
            >
              {passwordRequirementsText}
            </span>
            <div
              className={`password-requirements__tooltip${
                showRequirements ? "--show" : "--hide"
              }`}
            >
              <div className="password-requirements__tooltip-wrapper">
                <ToolTip
                  message={
                    <PasswordRequirements passwordRegExp={passwordRegExp} />
                  }
                />
              </div>
            </div>
            <Input
              id="kf-change-re-password"
              type="password"
              value={rePassword.value}
              maxLength={40}
              placeholder={`${reEnterPasswordText}`}
              label={`${reEnterPasswordText}`}
              onChange={e =>
                setRePassword({ ...rePassword, value: e.target.value })
              }
              showError={rePassword.error}
              errorMessage={rePassword.errorMssg}
              onBlur={e => onBlurInput("re-pwd", e.target.value)}
            />
            <Button
              type="black"
              flexible={true}
              label={resetPasswordLabelText}
              onClick={handleResetPassword}
              customClass="reset-button"
              loading={loading}
              role="button"
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <section className="header">
              <h2>{`${passwordSuccessText}!`}</h2>
              <span>{successMessageText}</span>
            </section>
            <Button
              type="black"
              flexible={true}
              label={signinText}
              onClick={handleShowAuthModal}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default ChangePassword
