import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import styles from "@/components/Shared/index.module.scss"
import DOMPurify from "isomorphic-dompurify"

const AdditionalFooter = props => {
  const { data, columnClassNames } = props

  const router = useRouter()
  const [location, setLocation] = useState("")
  const [docTitle, setDocTitle] = useState("")

  useEffect(() => {
    setLocation(window.location.href)
    setDocTitle(document.title)
  }, [router.pathname])

  const getHref = item => {
    return item.link !== "#"
      ? {
          href: item.link
            ?.split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
            .join("")
            .replace(".html", ""),
        }
      : ""
  }

  const getRole = item => (item.link !== "#" ? "link" : "button")

  const getDomLocation = item => {
    if (item?.link === "#") {
      return "n/a"
    } else if (item?.link.includes("https")) {
      return item?.link
        .split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
        .join("")
        .replace(".html", "")
    } else {
      if (typeof window !== "undefined")
        return `${window.location.origin}${item?.link
          .split(process.env.NEXT_PUBLIC_INTERNALLINKPATH)
          .join("")
          .replace(".html", "")}`
    }
  }
  const pushAnalyticsObjToDataLayer = (eventInfo = {}) => {
    const { adobeDataLayer: { getState = () => {} } = {} } = window
    const page = getState("page")
    const event = "cmp:click"
    window.adobeDataLayer.push({
      event,
      eventInfo,
      page,
    })
  }

  const handleDataLayerForFooter = (e, title, item) => {
    const titleName =
      title === "Cookies Settings"
        ? e.target.innerText.toLowerCase()
        : title.toLowerCase()
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `global footer:${DOMPurify.sanitize(titleName)}`,
      eventMsg: "n/a",
      eventName: `global footer:${DOMPurify.sanitize(titleName)}`,
      eventStatus: "n/a",
      eventType: "footer menu-2",
      internalLinkName: `${DOMPurify.sanitize(titleName)}`,
      internalLinkPosition: `global footer`,
      internalLinkType: `global footer:navigation`,
      internalLinkURL: `${getDomLocation(item)}`,
      internalLinkZoneName: `global footer`,
    }
    pushAnalyticsObjToDataLayer(eventInfo)
  }

  return (
    <div
      className={`${columnClassNames ? columnClassNames : ""} ${
        styles.additionalFooterWrapper
      }`}
    >
      <div className="additional-footer">
        <footer>
          <section className="gbh-footer-add-nav">
            <section className="gbh-container">
              <section className="gbh-footer-add-panel">
                <ul className="gbh-footer-nav__links">
                  {data?.items &&
                    data?.items.map((item, index) => (
                      <li key={`additionalfooteritem-${index}`}>
                        <a
                          id={item?.idLabel}
                          className={item?.classLabel}
                          aria-label={item.linkLabel}
                          target={item.newTab ? "_blank" : "_self"}
                          {...getHref(item)}
                          rel="noreferrer"
                          onClick={e =>
                            handleDataLayerForFooter(e, item.linkLabel, item)
                          }
                          role={getRole(item)}
                          tabIndex={0}
                        >
                          {item.linkLabel}
                        </a>
                      </li>
                    ))}
                </ul>
                <p className="gbh-copy__rights">{data.copyright}</p>
                <ul className="gbh-social__links">
                  {data?.email == "email" && (
                    <li className="gbh-social__email">
                      <a
                        target="_blank"
                        className="gbh-data-layer"
                        aria-label="Email corporate page link opens in a new tab"
                        href={`mailto:?body=${location}&subject=${docTitle}`}
                        rel="noreferrer noopener"
                        title={data.linkLabelEmail}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkEmail
                        }","internalLinkName":"social media:${data?.linkLabelEmail?.toLowerCase()}","eventName":"social media:${data?.linkLabelEmail?.toLowerCase()}","internalLinkType":"global footer:"navigation","eventType":"social icon","eventAction":"social media icon"}`}
                        data-link-type="social media"
                      />
                    </li>
                  )}
                  {data?.facebook == "facebook" && (
                    <li className="gbh-social__facebook">
                      <a
                        target="_blank"
                        aria-label="Facebook corporate page link opens in a new tab"
                        href={data.linkFacebook}
                        rel="noreferrer noopener"
                        title={data?.linkLabelFacebook}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkFacebook
                        }","internalLinkName":"social media:${data?.linkLabelFacebook.toLowerCase()}","eventName":"social media:${data?.linkLabelFacebook.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media icon"}`}
                        className="gbh-data-layer"
                        data-link-type="social media"
                      />
                    </li>
                  )}
                  {data?.twitter == "twitter" && (
                    <li className="gbh-social__twitter">
                      <a
                        target="_blank"
                        aria-label="Twitter corporate page link opens in a new tab"
                        href={data.linkTwitter}
                        rel="noreferrer noopener"
                        title={data.linkLabelTwitter}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkTwitter
                        }","internalLinkName":"social media:${data?.linkLabelTwitter.toLowerCase()}","eventName":"social media:${data?.linkLabelTwitter.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media icon"}`}
                        className="gbh-data-layer"
                        data-link-type="social media"
                      />
                    </li>
                  )}
                  {data?.instagram == "instagram" && (
                    <li className="gbh-social__insta">
                      <a
                        target="_blank"
                        aria-label="Instagram corporate page link opens in a new tab"
                        href={data.linkInstagram}
                        rel="noreferrer noopener"
                        title={data.linkLabelInstagram}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkInstagram
                        }","internalLinkName":"social media:${data?.linkLabelInstagram.toLowerCase()}","eventName":"social media:${data?.linkLabelInstagram.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media icon"}`}
                        className="gbh-data-layer"
                        data-link-type="social media"
                      />
                    </li>
                  )}
                  {data?.pinterest == "pinterest" && (
                    <li className="gbh-social__pintrest">
                      <a
                        target="_blank"
                        aria-label="Pinterest corporate page link opens in a new tab"
                        href={data.linkPinterest}
                        rel="noreferrer noopener"
                        title={data.linkLabelPinterest}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkPinterest
                        }","internalLinkName":"social media:${data?.linkLabelPinterest.toLowerCase()}","eventName":"social media:${data?.linkLabelPinterest.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media icon"}`}
                        className="gbh-data-layer"
                        data-link-type="social media"
                      />
                    </li>
                  )}
                  {data?.youtube == "youtube" && (
                    <li className="gbh-social__youtube">
                      <a
                        target="_blank"
                        aria-label="Youtube corporate page link opens in a new tab"
                        href={data.linkYoutube}
                        rel="noreferrer noopener"
                        title={data.linkLabelYoutube}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkYoutube
                        }","internalLinkName":"social media:${data?.linkLabelYoutube.toLowerCase()}","eventName":"social media:${data?.linkLabelYoutube.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media icon"}`}
                        className="gbh-data-layer"
                        data-link-type="social media"
                      />
                    </li>
                  )}
                  {data?.houzz == "houzz" && (
                    <li className="gbh-social__houzz">
                      <a
                        target="_blank"
                        aria-label="Houzz corporate page link opens in a new tab"
                        href={data.linkHouzz}
                        rel="noreferrer noopener"
                        title={data.linkLabelHouzz}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkHouzz
                        }","internalLinkName":"social media:${data?.linkLabelHouzz.toLowerCase()}","eventName":"social media:${data?.linkLabelHouzz.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media icon"}`}
                        className="gbh-data-layer"
                        data-link-type="social media"
                      />
                    </li>
                  )}
                  {data?.linkedin == "linkedin" && (
                    <li className="gbh-social__linkedin">
                      <a
                        target="_blank"
                        aria-label="Linkedin corporate page link opens in a new tab"
                        href={data.linkLinkedin}
                        rel="noreferrer noopener"
                        title={data.linkLabelLinkedin}
                        data-gbh-data-layer={`{"clickInternalLinks":"false","internalLinkPosition":"global footer","internalLinkZoneName":"global footer","internalLinkURL":"${
                          data?.linkLinkedin
                        }","internalLinkName":"social media:${data?.linkLabelLinkedin.toLowerCase()}","eventName":"social media:${data?.linkLabelLinkedin.toLowerCase()}","internalLinkType":"global footer:navigation","eventType":"social icon","eventAction":"social media icon"}`}
                        className="gbh-data-layer"
                        data-link-type="social media"
                      />
                    </li>
                  )}
                </ul>
              </section>
            </section>
          </section>
        </footer>
      </div>
    </div>
  )
}

export default AdditionalFooter
