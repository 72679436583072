import React, { useRef, useEffect, useState, Fragment } from "react"
import { withOrientationChange } from "react-device-detect"
import ProfessionalsList from "@/components/FindAProResults/v1/ProfessionalsList/index"
import DesignerList from "@/components/FindAProResults/v1/DesignerList/index"
import Sticky from "react-sticky-el"
import { getConfig } from "@/constants/config"
import { sanitizeInnerHtml } from "@/utils/helper"
import {
  addAnalyticsData,
  addDataLayerOnMenuItemClick,
  getEventInfoForFindAproResultsView,
} from "@/components/FindAProResults/v1/analytics/helper"
import { getDimensions } from "@/components/FindAProResults/v1/utils"
import NoResults from "@/components/FindAProResults/v1/NoResults/index"

const FindAProResultsLanding = props => {
  const {
    data = {},
    zipcodeVal = "",
    staticTexts = "",
    designersData = [],
    loading = false,
    findAProbyZipPagePath = "",
  } = props
  const [visibleSection, setVisibleSection] = useState()
  const [navSticky, setNavSticky] = useState(false)
  const subMenusRef = useRef(null)

  const remodelersRef = useRef(null)
  const plumbersRef = useRef(null)
  const buildersRef = useRef(null)
  const architectRef = useRef(null)
  const designersRef = useRef(null)
  const bathroomDesignServiceLinkRef = useRef(null)
  const CONFIG = getConfig()
  const brandName = CONFIG.general?.siteName ?? ""
  const swatchUrl = CONFIG?.general?.swatchUrl
  const bathroomDesServTimeout = 2000

  const remodelersData =
    staticTexts?.LocationRemodeler !== "" &&
    data &&
    data.filter(item => item.LocationRemodeler === true)
  const plumbersinstallersData =
    (staticTexts?.LocationPlumber !== "" ||
      staticTexts?.LocationInstaller !== "") &&
    data &&
    data.filter(item => item.LocationPlumber === true)
  const buildersData =
    staticTexts?.LocationBuilder !== "" &&
    data &&
    data.filter(item => item.LocationBuilder === true)
  const architectData =
    data && data.filter(item => item.LocationArchitect === true)
  const designerData =
    data && data.filter(item => item.LocationDesigner === true)

  const dataList = [
    {
      section: "Remodelers",
      ref: remodelersRef,
      data: remodelersData || [],
      menuName: staticTexts?.remodelers,
      sectionTitle:
        brandName === "sterling"
          ? staticTexts?.remodelersSterlingTitleText
          : staticTexts?.remodelersTitleText,
    },
    {
      section: "Installers & Plumbers",
      ref: plumbersRef,
      data: plumbersinstallersData || [],
      menuName: staticTexts?.plumbersInstallers,
      sectionTitle: staticTexts?.plumbersInstallersTitleText,
    },
    {
      section: "Builders",
      ref: buildersRef,
      data: buildersData || [],
      menuName: staticTexts?.builders,
      sectionTitle: staticTexts?.buildersTitleText,
    },
    {
      section: "Architect",
      ref: architectRef,
      data: architectData || [],
      menuName: staticTexts?.architect,
      sectionTitle: staticTexts?.architectTitleText,
    },
    {
      section: "Designers",
      ref: designersRef,
      data: designerData || [],
      menuName: staticTexts?.designers,
      sectionTitle: staticTexts?.designersTitleText,
    },
  ]
  useEffect(() => {
    if (data && data?.length < 1) return
    const handleScroll = () => {
      const { height: headerHeight } = getDimensions(subMenusRef.current)
      const scrollPosition = window.scrollY + headerHeight

      const selected = dataList.find(({ ref }) => {
        const ele = ref.current
        if (ele) {
          const { offsetBottom, offsetTop } = getDimensions(ele)
          return scrollPosition > offsetTop && scrollPosition < offsetBottom
        }
      })

      if (!selected) {
        setVisibleSection(staticTexts?.remodelers)
      } else if (selected && selected.menuName !== visibleSection) {
        setVisibleSection(selected.menuName)
      }
    }

    handleScroll()

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [staticTexts])
  const bathroomDesignText = staticTexts?.bathroomDesignConsultText

  const professionalcount =
    dataList.reduce((sum, curr) => {
      return sum + curr.data.length
    }, 0) + designersData?.length

  useEffect(() => {
    if (loading === false) {
      addAnalyticsData(professionalcount, zipcodeVal)
    } else if (loading === null) {
      addAnalyticsData(0, zipcodeVal)
    }
  }, [loading])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const link = bathroomDesignServiceLinkRef.current?.querySelector("p > a")
      if (link) {
        link.classList.add("gbh-data-layer")
        link.setAttribute(
          "data-gbh-data-layer",
          getEventInfoForFindAproResultsView(link.getAttribute("href"), true)
        )
      }
    }, bathroomDesServTimeout)
    return () => clearTimeout(timeoutId)
  }, [])

  const scrollTo = ele => {
    ele.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  const handleKeyPress = (e, section) => {
    const menus = dataList.filter(item => {
      if (item.section === "Designers") {
        return item
      } else if (item.data?.length > 0) {
        return item
      }
    })
    const curMenu = menus.findIndex(item => item.section === section)
    if (e.keyCode === 37 || e.keyCode === 39) {
      let tab
      if (e.keyCode === 37) {
        tab = curMenu - 1
      } else if (e.keyCode === 39) {
        tab = curMenu + 1
      }
      if (tab < 0) tab = menus?.length - 1
      if (tab >= menus?.length) tab = 0
      document
        .getElementById(`section-${menus[tab].section.toLowerCase()}`)
        .focus()
    } else if (
      e.keyCode === 9 &&
      e.shiftKey &&
      document.activeElement ===
        document.getElementById(`section-${menus[0].section.toLowerCase()}`)
    ) {
      e.preventDefault()
      document.getElementById("request-change-location").focus()
      return
    }
  }

  const handleMenuClick = (menu, linkName) => {
    scrollTo(menu)
    addDataLayerOnMenuItemClick(linkName)
  }

  if (loading) return ""

  return (
    <div className="find-pro-results">
      {data?.length > 0 ? (
        <Fragment>
          <div className="find-pro-results__local-professional-details">
            <div className="container kf-react-container">
              <div className="local-professional-count">
                {`Showing ${professionalcount} professionals near `}
                {brandName === "sterling" ? (
                  <a
                    tabIndex="0"
                    className="local-professional-count"
                    href={findAProbyZipPagePath}
                  >
                    {zipcodeVal}
                  </a>
                ) : (
                  zipcodeVal
                )}
                <a
                  data-gbh-data-layer={getEventInfoForFindAproResultsView(
                    findAProbyZipPagePath
                  )}
                  id="request-change-location"
                  tabIndex="0"
                  className="change-location"
                  href={findAProbyZipPagePath}
                >
                  {staticTexts?.changeLocationText}
                </a>
              </div>
            </div>
          </div>
          <Sticky
            onFixedToggle={() => setNavSticky(!navSticky)}
            className="product-list__sticky-nav-wrap"
            stickyStyle={{ zIndex: 9 }}
          >
            <div className="find-pro-results__sub-navigation" ref={subMenusRef}>
              <nav className="container kf-react-container">
                <ul role="menubar">
                  {dataList.map((smenu, index) => {
                    return smenu.data?.length > 0 &&
                      smenu.section !== "Designers" ? (
                      <li
                        key={index}
                        className={`header_link ${
                          visibleSection === smenu.menuName ? "selected" : ""
                        }`}
                        onClick={() =>
                          handleMenuClick(smenu.ref.current, smenu.menuName)
                        }
                        onKeyDown={e => handleKeyPress(e, smenu.section)}
                        role="menuitem"
                        tabIndex={visibleSection === smenu.menuName ? 0 : -1}
                        id={`section-${smenu.section.toLowerCase()}`}
                      >
                        {smenu?.menuName}
                      </li>
                    ) : null
                  })}
                  <li
                    className={`header_link ${
                      visibleSection === staticTexts?.designers
                        ? "selected"
                        : ""
                    }`}
                    onClick={() =>
                      handleMenuClick(
                        designersRef.current,
                        staticTexts?.designers
                      )
                    }
                    onKeyDown={e => handleKeyPress(e, "Designers")}
                    role="menuitem"
                    tabIndex={
                      visibleSection === staticTexts?.designers ? 0 : -1
                    }
                    id={`section-designers`}
                  >
                    {staticTexts?.designers}
                  </li>
                </ul>
              </nav>
            </div>
          </Sticky>
          <div>
            {dataList.map((list, index) => {
              return list.data?.length > 0 && list.data !== designerData ? (
                <div
                  className="find-pro-results__remodelers-section"
                  key={index}
                  id={list.section}
                  ref={list.ref}
                  tabIndex="-1"
                >
                  <div className="container kf-react-container">
                    <div className="find-pro-results__container">
                      <div
                        className="find-pro-results__section-title"
                        role="heading"
                        level="2"
                      >
                        {list.sectionTitle}
                      </div>
                      <section role="list" aria-label={list.sectionTitle}>
                        {list.data &&
                          list.data.map((item, index) => (
                            <ProfessionalsList
                              staticTexts={staticTexts}
                              data={item}
                              key={index}
                              swatchUrl={swatchUrl}
                              selectedMenu={visibleSection}
                              serviceProviderType={list.sectionTitle}
                            />
                          ))}
                      </section>
                    </div>
                  </div>
                </div>
              ) : null
            })}
          </div>
          <div
            className="find-pro-results__designer-section"
            id="Designers"
            ref={designersRef}
            tabIndex="-1"
          >
            <div className="container kf-react-container">
              <div className="find-pro-results__container">
                <section role="list" aria-label={staticTexts?.sectionTitle}>
                  {designersData.map((item, index) => (
                    <DesignerList
                      staticTexts={staticTexts}
                      data={item}
                      key={index}
                      selectedMenu={visibleSection}
                      serviceProviderType={staticTexts?.designersTitleText}
                    />
                  ))}
                </section>
                <div
                  className="d-inline-block service-info"
                  ref={bathroomDesignServiceLinkRef}
                >
                  {sanitizeInnerHtml(bathroomDesignText)}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <NoResults
          professionalcount={professionalcount}
          brandName={brandName}
          findAProbyZipPagePath={findAProbyZipPagePath}
          zipcodeVal={zipcodeVal}
          staticTexts={staticTexts}
          subMenusRef={subMenusRef}
          dataList={dataList}
          visibleSection={visibleSection}
          designersRef={designersRef}
          handleMenuClick={handleMenuClick}
          handleKeyPress={handleKeyPress}
          designersData={designersData}
          bathroomDesignServiceLinkRef={bathroomDesignServiceLinkRef}
        />
      )}
    </div>
  )
}
export default withOrientationChange(FindAProResultsLanding)
