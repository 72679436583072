import { apim } from "@/constants/api"
import {
  CHANGE_PASSWORD_ENDPOINT,
  CATALOG_PROJECTION_ENDPOINT,
} from "@/constants/routes"
import { searchPdpEndPoint, skuBypimLookup } from "@/constants/index"
import { getSessionIdObjWithEpoc } from "@/utils/ApiUtil"

export const requestForChangePassword = async data => {
  try {
    return await apim.post(CHANGE_PASSWORD_ENDPOINT, data)
  } catch (err) {
    console.error("Failed to reset password", err)
    return err.response
  }
}

export const getProductByImage = async input => {
  try {
    const response = await apim.post(`${skuBypimLookup}`, input, {
      sessionId: getSessionIdObjWithEpoc(),
    })
    return response
  } catch (err) {
    return err
  }
}
export const getSearchPDP = async slug => {
  try {
    const response = await apim.post(`${searchPdpEndPoint}/${slug}`, {
      collections: "service_parts",
      fl: "*",
      profilename: "profile_service_parts_PDP",
      q: "*:*",
      search_type: "product_detail",
    })
    return response
  } catch (err) {
    return err
  }
}
export const getProjectionData = async payload => {
  const { slug, skus } = payload
  let query = ""
  let masterQuery = ""
  if (slug) masterQuery += `slug(en-US%3D"${slug}") or `
  if (skus.length > 0) {
    skus.forEach((sku, i) => {
      query += `"${sku}"`
      if (i + 1 !== skus.length) query += ", "
    })
    masterQuery += `masterVariant(sku in (${query})) or variants(sku in (${query}))`
  }
  try {
    const res = await apim.get(
      `${CATALOG_PROJECTION_ENDPOINT}?where=${masterQuery}`
    )
    if (res && res.data && res.data.results) return res.data.results
  } catch (err) {
    return err
  }
  return []
}
