export const VisualSearchIconDesktop = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="center_focus_weak">
      <mask
        id="mask0_381_122521"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="60"
        height="60"
      >
        <rect id="Bounding box" width="60" height="60" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_381_122521)">
        <path
          id="center_focus_weak_2"
          d="M10 50V37.7113H11.9231V48.0769H22.2888V50H10ZM37.7113 50V48.0769H48.0769V37.7113H50V50H37.7113ZM10 22.2888V10H22.2888V11.9231H11.9231V22.2888H10ZM48.0769 22.2888V11.9231H37.7113V10H50V22.2888H48.0769ZM30 37.0144C27.9983 37.0144 26.329 36.3456 24.9919 35.0081C23.6544 33.671 22.9856 32.0017 22.9856 30C22.9856 27.9983 23.6544 26.329 24.9919 24.9919C26.329 23.6544 27.9983 22.9856 30 22.9856C32.0017 22.9856 33.671 23.6544 35.0081 24.9919C36.3456 26.329 37.0144 27.9983 37.0144 30C37.0144 32.0017 36.3456 33.671 35.0081 35.0081C33.671 36.3456 32.0017 37.0144 30 37.0144ZM29.9937 35.0912C31.4242 35.0912 32.6315 34.6013 33.6156 33.6213C34.5994 32.6413 35.0913 31.4363 35.0913 30.0063C35.0913 28.5758 34.6013 27.3685 33.6213 26.3844C32.6413 25.4006 31.4363 24.9087 30.0063 24.9087C28.5758 24.9087 27.3685 25.3987 26.3844 26.3787C25.4006 27.3587 24.9088 28.5637 24.9088 29.9937C24.9088 31.4242 25.3987 32.6315 26.3787 33.6156C27.3587 34.5994 28.5637 35.0912 29.9937 35.0912Z"
          fill="#757575"
        />
      </g>
    </g>
  </svg>
)

export const VisualSearchIconMobile = () => (
  <svg
    width="35"
    height="36"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_242_37200"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="35"
      height="36"
    >
      <rect y="0.5" width="35" height="35" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_242_37200)">
      <path
        d="M5.83337 29.6654V22.4969H6.9552V28.5435H13.0018V29.6654H5.83337ZM21.9983 29.6654V28.5435H28.0449V22.4969H29.1667V29.6654H21.9983ZM5.83337 13.5005V6.33203H13.0018V7.45385H6.9552V13.5005H5.83337ZM28.0449 13.5005V7.45385H21.9983V6.33203H29.1667V13.5005H28.0449ZM17.5 22.0904C16.3324 22.0904 15.3586 21.7003 14.5786 20.9201C13.7984 20.1401 13.4083 19.1663 13.4083 17.9987C13.4083 16.8311 13.7984 15.8573 14.5786 15.0773C15.3586 14.2971 16.3324 13.907 17.5 13.907C18.6677 13.907 19.6415 14.2971 20.4214 15.0773C21.2017 15.8573 21.5918 16.8311 21.5918 17.9987C21.5918 19.1663 21.2017 20.1401 20.4214 20.9201C19.6415 21.7003 18.6677 22.0904 17.5 22.0904ZM17.4964 20.9686C18.3308 20.9686 19.0351 20.6828 19.6092 20.1111C20.183 19.5394 20.4699 18.8365 20.4699 18.0023C20.4699 17.1679 20.1841 16.4637 19.6124 15.8896C19.0408 15.3157 18.3379 15.0288 17.5037 15.0288C16.6693 15.0288 15.965 15.3146 15.3909 15.8863C14.8171 16.458 14.5301 17.1609 14.5301 17.9951C14.5301 18.8295 14.816 19.5337 15.3876 20.1078C15.9593 20.6817 16.6622 20.9686 17.4964 20.9686Z"
        fill="#757575"
      />
    </g>
  </svg>
)

export const VisualSearchIcon = () => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Visual Search Icon">
      <g id="center_focus_weak">
        <mask
          id="mask0_355_64216"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="60"
          height="60"
        >
          <rect id="Bounding box" width="60" height="60" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_355_64216)">
          <path
            id="center_focus_weak_2"
            d="M10 50V37.7113H11.9231V48.0769H22.2888V50H10ZM37.7113 50V48.0769H48.0769V37.7113H50V50H37.7113ZM10 22.2888V10H22.2888V11.9231H11.9231V22.2888H10ZM48.0769 22.2888V11.9231H37.7113V10H50V22.2888H48.0769ZM30 37.0144C27.9983 37.0144 26.329 36.3456 24.9919 35.0081C23.6544 33.671 22.9856 32.0017 22.9856 30C22.9856 27.9983 23.6544 26.329 24.9919 24.9919C26.329 23.6544 27.9983 22.9856 30 22.9856C32.0017 22.9856 33.671 23.6544 35.0081 24.9919C36.3456 26.329 37.0144 27.9983 37.0144 30C37.0144 32.0017 36.3456 33.671 35.0081 35.0081C33.671 36.3456 32.0017 37.0144 30 37.0144ZM29.9937 35.0912C31.4242 35.0912 32.6315 34.6013 33.6156 33.6213C34.5994 32.6413 35.0913 31.4363 35.0913 30.0063C35.0913 28.5758 34.6013 27.3685 33.6213 26.3844C32.6413 25.4006 31.4363 24.9087 30.0063 24.9087C28.5758 24.9087 27.3685 25.3987 26.3844 26.3787C25.4006 27.3587 24.9088 28.5637 24.9088 29.9937C24.9088 31.4242 25.3987 32.6315 26.3787 33.6156C27.3587 34.5994 28.5637 35.0912 29.9937 35.0912Z"
            fill="#212121"
          />
        </g>
      </g>
    </g>
  </svg>
)

export const VisualSearchIconGreyMobile = () => (
  <svg
    width="35"
    height="36"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_218_34843"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="35"
      height="36"
    >
      <rect y="0.5" width="35" height="35" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_218_34843)">
      <path
        d="M5.83325 29.6654V22.4969H6.95508V28.5435H13.0017V29.6654H5.83325ZM21.9981 29.6654V28.5435H28.0448V22.4969H29.1666V29.6654H21.9981ZM5.83325 13.5005V6.33203H13.0017V7.45385H6.95508V13.5005H5.83325ZM28.0448 13.5005V7.45385H21.9981V6.33203H29.1666V13.5005H28.0448ZM17.4999 22.0904C16.3323 22.0904 15.3585 21.7003 14.5785 20.9201C13.7983 20.1401 13.4082 19.1663 13.4082 17.9987C13.4082 16.8311 13.7983 15.8573 14.5785 15.0773C15.3585 14.2971 16.3323 13.907 17.4999 13.907C18.6676 13.907 19.6414 14.2971 20.4213 15.0773C21.2015 15.8573 21.5916 16.8311 21.5916 17.9987C21.5916 19.1663 21.2015 20.1401 20.4213 20.9201C19.6414 21.7003 18.6676 22.0904 17.4999 22.0904ZM17.4963 20.9686C18.3307 20.9686 19.0349 20.6828 19.609 20.1111C20.1829 19.5394 20.4698 18.8365 20.4698 18.0023C20.4698 17.1679 20.184 16.4637 19.6123 15.8896C19.0406 15.3157 18.3377 15.0288 17.5036 15.0288C16.6692 15.0288 15.9649 15.3146 15.3908 15.8863C14.8169 16.458 14.53 17.1609 14.53 17.9951C14.53 18.8295 14.8159 19.5337 15.3875 20.1078C15.9592 20.6817 16.6621 20.9686 17.4963 20.9686Z"
        fill="#212121"
      />
    </g>
  </svg>
)
