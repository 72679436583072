/**
 * Converts data url to file object
 * @param {String} dataUrl Dataurl text
 * @param {String} fileName FileName
 * @return {File|null} File Object
 */
export async function dataUrlToFile(dataUrl, fileName) {
  if (dataUrl) {
    const res = await fetch(dataUrl)
    const blob = await res.blob()
    return new File([blob], fileName, { type: "image/png" })
  }
  return null
}

/**
 * Crops canvas and return new canvas
 * @param {Canvas} sourceCanvas Canvas to be cropped
 * @param {Number} left left coordinate
 * @param {Number} top top coordinate
 * @param {Number} width Width of crop
 * @param {Number} height Height of the crop
 * @return {Canvas} Canvas
 */
export const cropCanvas = (sourceCanvas, left, top, width, height) => {
  const destCanvas = document.createElement("canvas")
  destCanvas.width = width
  destCanvas.height = height
  destCanvas
    .getContext("2d")
    .drawImage(sourceCanvas, left, top, width, height, 0, 0, width, height)
  return destCanvas
}

/**
 * Converts to cropped image
 * @param {Image} image
 * @param {Object} crop
 * @param {String} fileName
 * @param {String} type
 * @return {File}
 */
export function getCroppedImg(image, crop, fileName, type) {
  const canvas = document.createElement("canvas")
  const scaleX = image?.naturalWidth ? image.naturalWidth / image.width : 1
  const scaleY = image?.naturalHeight ? image.naturalHeight / image.height : 1
  canvas.width = crop.width
  canvas.height = crop.height
  const ctx = canvas.getContext("2d")

  const pixelRatio = window.devicePixelRatio
  canvas.width = crop.width * pixelRatio
  canvas.height = crop.height * pixelRatio
  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
  ctx.imageSmoothingQuality = "high"

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  )

  const base64Image = canvas.toDataURL(type)
  return dataUrlToFile(base64Image, fileName)
}

export const debounce = (func, debounceTime) => {
  let debounce = true
  return () => {
    if (debounce) {
      debounce = false
      setTimeout(() => {
        func()
        debounce = true
      }, debounceTime)
    }
  }
}

const promiseImageFileReader = imageFile => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      resolve({ result: e.target.result, readerResult: reader.result })
    }
    reader.onerror = e => {
      reject(e)
    }
    reader.readAsDataURL(imageFile)
  })
}

const promiseImageReader = source => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.onload = () => {
      resolve(image)
    }
    image.onerror = e => {
      reject(e)
    }
    image.src = source
  })
}

const imageExtentionCheck = imageFile => {
  if (
    imageFile &&
    imageFile.name?.toLowerCase()?.match(/\.(jpg|jpeg|png|bmp|jfif)$/)
  )
    return true
  return false
}

export const validateImageFileAsync = async imageFile => {
  try {
    if (imageExtentionCheck(imageFile)) {
      const { result, readerResult } = await promiseImageFileReader(imageFile)
      const image = await promiseImageReader(result)
      return {
        width: image.width,
        height: image.height,
        image: image,
        file: imageFile,
        dataURL: readerResult,
      }
    }
  } catch (e) {
    return false
  }
  return false
}
