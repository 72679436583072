import React, { useEffect, useRef } from "react"

import { OverlayScrollbarsComponent } from "overlayscrollbars-react"
import { useTranslation } from "next-i18next"
import { getTranslations } from "@/components/VisualSearch/localization"
import { ConditionalWrapper } from "@/utils/index"
import {
  imageInformation,
  scrollOptions,
} from "@/components/VisualSearch/v1/SelectProductCategory/constant"

const SelectProductCategoryView = props => {
  const { updateCategoryItems, setOpenCam } = props
  const scrollerRef = useRef()
  const { t } = useTranslation()
  const { textCommingSoon } = getTranslations(t)

  useEffect(() => {
    let timeId
    if (scrollerRef.current) {
      timeId = setTimeout(() => {
        document
          .querySelectorAll(".carousel-bottom-scroll")
          .forEach(scrollDiv => {
            let heightToSet = 0
            scrollDiv
              ?.querySelectorAll(".cmp-carousel__content")
              .forEach(scrollDivContent => {
                if (
                  scrollDivContent &&
                  heightToSet < scrollDivContent?.offsetHeight
                ) {
                  heightToSet = scrollDivContent?.offsetHeight
                }
              })
            scrollDiv
              ?.querySelectorAll(".os-content-glue")
              .forEach(scrollDivglu => {
                if (scrollDivglu && heightToSet > 0) {
                  scrollDivglu.style.height = heightToSet + "px"
                }
              })
          })
      }, 2000)
    }
    return () => clearTimeout(timeId)
  }, [scrollerRef.current])

  const handleCategorySelection = (e, category, item) => {
    setOpenCam(true)
    const input = [{ category: category, comingSoon: item?.comingSoon }]
    updateCategoryItems(input)
  }

  return (
    <div
      className={`carousel carousel-btm-scroll-title-small product-category`}
    >
      <div className={`cmp-carousel slide-scrollbar`}>
        <div className="cmp-carousel-text">
          <div className="cmp-carousel__pretitle" role="heading">
            <h3>Select Your Product</h3>
          </div>
        </div>

        <ConditionalWrapper
          condition={true}
          wrapper={children => (
            <OverlayScrollbarsComponent
              options={scrollOptions}
              ref={scrollerRef}
              defer
            >
              {children}
            </OverlayScrollbarsComponent>
          )}
        >
          <div className="cmp-carousel__content">
            {imageInformation
              ? imageInformation.map((item, i) => {
                  return (
                    <div
                      key={`carousel-${i}`}
                      className={`cmp-carousel__item cmp-carousel__item--active`}
                      role="tabpanel"
                      aria-label={`Slide ${i + 1} of ${
                        imageInformation?.length
                      }`}
                    >
                      <div
                        className="image-list d-flex flex-column h-100"
                        key={i}
                        onClick={e => {
                          handleCategorySelection(e, item?.imageName, item)
                        }}
                      >
                        <div className="image-with-text position-relative d-inline-block">
                          {item?.imageUrl ? (
                            <>
                              <div className="image-part">
                                <img
                                  className="pia-img"
                                  src={item?.imageUrl?.src}
                                  alt={item?.imageName}
                                  title={item?.imageName}
                                />
                              </div>
                              <div className="text-label">
                                <span fontSize={26} className="image-text">
                                  {item?.imageName}
                                </span>
                                <div className="comingSoon">
                                  {item?.comingSoon ? (
                                    <label
                                      fontSize={16}
                                      className="coming-soon section-Line"
                                    >
                                      {item?.comingSoon
                                        ? textCommingSoon?.toUpperCase()
                                        : null}
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )
                })
              : null}
            {Array.from({ length: 2 }, (val, index) => index + 1).map(val => (
              <div key={val} className="cmp-carousel__item"></div>
            ))}
          </div>
        </ConditionalWrapper>
      </div>
    </div>
  )
}

export default SelectProductCategoryView
