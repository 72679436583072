import { apim } from "@/constants/api"
import {
  createCartEndPoint,
  getCartEndPoint,
  shippingCostEndPoint,
  discountEndPoint,
  taxEndPoint,
  shareCartEndPoint,
  mergeCartEndPoint,
  cartItemsEndPoint,
  deleteCartEndPoint,
  shipmentFromCartEndPoint,
  addressSuggestionEndPoint,
  addAddressEndPoint,
  orderSubmitEndPoint,
  paymentEndPoint,
  payPalPaymentEndPoint,
  checkSplitOrderEndPoint,
  sharedCartItemsEndPoint,
  ADDRESS_SUGGESTION_DEFAULT_COUNTRY,
} from "@/constants/index"
import { CATALOG_PROJECTION_ENDPOINT } from "@/constants/routes"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import {
  getQuantityCount,
  getCartIdParams,
  removeCustomerCartId,
} from "@/utils/cart"
import { store } from "@/store"
import { setAnonymousId, clearAuthState } from "@/store/features/authSlice"
import {
  setIsShipmentFromCart,
  setCheckoutAction,
  setExpPayPalCheckout,
} from "@/store/features/checkoutSlice"
import { getConfig } from "@/constants/config"
import { getUserPersona } from "@/utils/helper"
import { checkExpPayPal } from "@/utils/payment"

const initialState = {
  cart: {
    id: "",
  },
  quantity: 0,
  shipping: {},
  shipment: {},
  cartCount: 0,
  // shpping Items to checkout when product stock available
  shippingItemstoCheckout: [],
  // no items to ship from checkout when product is out of stock
  noShippingItemsInCheckout: [],
  cartUrl: "",
  showShippingDetailsForReview: {
    Parcel: false,
    LTL: false,
    Services: false,
  },
  paymentId: "",
  nameOnCard: "",
  status: "",
}

export const getCart = createAsyncThunk("getCart", async (payload = {}) => {
  const { isNotFromCart = "", klId = "" } = payload
  const { cartId = "" } = getCartIdParams()
  const url = klId
    ? `/cart/${klId}` // it's for loading customer cart data using KL id
    : `${getCartEndPoint}${
        isNotFromCart ? "&gfqprofile=refershCartProfile" : ""
      }${cartId}`

  const { data = {} } = await apim.get(url)
  const { anonymousId = "" } = data
  store.dispatch(setAnonymousId(anonymousId))
  return data
})

export const createCart = createAsyncThunk(
  "createCart",
  async (body, thunkAPI) => {
    const state = thunkAPI.getState() || {}
    const { user } = state.auth || {}
    if (user) body.customerEmail = user.sub?.toLowerCase() ?? ""

    if (user.koc) {
      body.custom = {
        type: { key: "order", typeId: "type" },
        fields: { CSRAssociateId: user.koc, orderType: "ISC" },
      }
    }

    const { data = {} } = await apim.post(`${createCartEndPoint}`, body)
    const { anonymousId = "" } = data
    store.dispatch(setAnonymousId(anonymousId))
    return data
  }
)

export const shippingCost = createAsyncThunk("shippingCost", async id => {
  const response = await apim.get(`${shippingCostEndPoint}?cartId=${id}`)
  return response.data
})

export const discount = createAsyncThunk("discount", async body => {
  const { cartId = "" } = getCartIdParams()
  const url = `${discountEndPoint}${cartId}`
  const response = await apim.post(url, body)
  return response.data
})

export const taxCalculation = createAsyncThunk("tax", async () => {
  const { cartId = "" } = getCartIdParams()
  const body = { actions: [{ action: "calculateTax" }] }
  const url = `${taxEndPoint}${cartId}`
  const response = await apim.post(url, body)
  return response.data
})

export const shipmentUpdate = createAsyncThunk(
  "shipmentUpdate",
  async (body, thunkAPI) => {
    try {
      const { cartId = "" } = getCartIdParams()
      const url = `${taxEndPoint}${cartId}`
      const response = await apim.post(url, body)
      return response.data
    } catch (error) {
      const {
        response: { data: { isNonDeliverableAddress = false } = {} } = {},
      } = error
      return thunkAPI.rejectWithValue({ isNonDeliverableAddress })
    }
  }
)

export const shareCart = createAsyncThunk("sharecart", async body => {
  const response = await apim.post(`${shareCartEndPoint}`, body)
  return response.data
})

export const mergeCart = createAsyncThunk("cart/merge", async payload => {
  const { data = {} } = await apim.post(mergeCartEndPoint, payload)
  if (data && data.type === "Cart") {
    store.dispatch(setAnonymousId(""))
  }
  return data
})
export const addToCart = createAsyncThunk(
  "addToCart",
  async (body, thunkAPI) => {
    const persona = getUserPersona()
    const state = thunkAPI.getState() || {}

    const {
      cart: { customerGroup },
    } = state.cart

    if (!customerGroup) {
      const customerGroupData = {}
      customerGroupData["action"] = "setCustomerGroup"
      customerGroupData["customerGroup"] = { key: persona }
      if (body.actions) {
        body.actions.push(customerGroupData)
      }
    }
    const { cartId = "" } = getCartIdParams()
    const url = `${cartItemsEndPoint}?expand=calculation${cartId}`
    const { data = {} } = await apim.post(url, body)
    const { anonymousId = "" } = data
    store.dispatch(setAnonymousId(anonymousId))
    return data
  }
)

export const shareCartaddToCart = createAsyncThunk(
  "shareCartaddToCart",
  async body => {
    const { cartId = "", isSharedCart = false, ...restBody } = body
    const url = `${sharedCartItemsEndPoint}?expand=calculation&cartId=${cartId}`
    const { data = {} } = await apim.post(url, restBody)
    return { data, isSharedCart }
  }
)

export const removeFromCartLineItem = createAsyncThunk(
  "removeFromCartLineItem",
  async body => {
    const { cartId = "" } = getCartIdParams()
    const url = `${cartItemsEndPoint}?expand=calculation${cartId}`
    const response = await apim.post(url, body)
    return response.data
  }
)
export const deleteCart = createAsyncThunk("deleteCart", async () => {
  const response = await apim.delete(`${deleteCartEndPoint}`)

  return response.data
})
export const shareCartDelete = createAsyncThunk(
  "shareCartDelete",
  async body => {
    const { id, version } = body
    const response = await apim.delete(
      `${`/cart/${id}?cart-versionId=${version}`}`
    )
    return response.data
  }
)

export const shipmentCart = createAsyncThunk("shipmentcart", async body => {
  let response
  const { cartId = "", cartIdQuery = "" } = getCartIdParams()
  const {
    cart: { cart = {} },
  } = store.getState() || {}

  store.dispatch(setIsShipmentLoading(true))

  const isXpp = checkExpPayPal()

  try {
    if (body && body?.assign && body?.payload) {
      const url = `${shipmentFromCartEndPoint}${
        body.assign ? "?assignshipping=true" : ""
      }${cartId}`
      response = await apim.post(url, body.payload)
    } else {
      const url = `${shipmentFromCartEndPoint}${cartIdQuery}`
      response = await apim.post(url)
    }
    store.dispatch(setIsShipmentLoading(false))
    store.dispatch(setIsShipmentFromCart(true))

    if (isXpp) {
      store.dispatch(setCheckoutAction(3))
      store.dispatch(setExpPayPalCheckout(true))
    } else {
      store.dispatch(setCheckoutAction(1))
    }

    return response.data
  } catch (error) {
    const shippingDetails = {
      "shipping-details": { results: [] },
      shippingCost: true,
    }
    response = await apim.get(`${shippingCostEndPoint}?cartId=${cart?.id}`)
    shippingDetails["shipping-details"]["results"] = response?.data?.results
    store.dispatch(setIsShipmentLoading(false))
    store.dispatch(setIsShipmentFromCart(false))
    return shippingDetails
  }
})

export const addresssSuggest = createAsyncThunk(
  "addresssSuggest",
  async addr => {
    const config = await getConfig()
    const country =
      config?.apiEndPointData?.map?.experianISOCode ||
      ADDRESS_SUGGESTION_DEFAULT_COUNTRY
    const response = await apim.get(
      `${addressSuggestionEndPoint}?query=${addr}&country=${country}`
    )
    return response.data
  }
)
export const addAddress = createAsyncThunk("addAddress", async body => {
  const response = await apim.post(`${addAddressEndPoint}`, body)
  return response.data
})

export const checkSplitOrder = createAsyncThunk(
  "checkSplitOrder",
  async cartId => {
    const response = await apim.get(
      `${checkSplitOrderEndPoint}?cartId=${cartId}`
    )
    return response.data
  }
)

export const payment = createAsyncThunk("payment", async body => {
  const response = await apim.post(`${paymentEndPoint}`, body)
  return response.data
})

export const payPalPayment = createAsyncThunk("payPalPayment", async body => {
  const response = await apim.post(`${payPalPaymentEndPoint}`, body)
  return response.data
})

export const orderSubmit = createAsyncThunk(
  "orderSubmit",
  async (body, thunkAPI) => {
    const { cartIdQuery = "" } = getCartIdParams(true)
    const url = `${orderSubmitEndPoint}${cartIdQuery}`
    try {
      const { data, status } = await apim.post(url, body)
      const state = thunkAPI.getState()
      const { auth: { isAuth = false } = {} } = state

      if (status === 201) {
        removeCustomerCartId()
        if (!isAuth) {
          store.dispatch(clearAuthState())
        }
      }
      return data
    } catch (error) {
      const { response: { data = {} } = {} } = error
      return thunkAPI.rejectWithValue(data)
    }
  }
)

export const recentlyViewed = createAsyncThunk(
  "recentlyViewed",
  async query => {
    const response = await apim.get(
      `${CATALOG_PROJECTION_ENDPOINT}?where=id in (${query})`
    )
    return response.data
  }
)
export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setCart(state, action) {
      state.cart = action.payload
    },
    setCartCount(state, action) {
      state.cartCount = action.payload
    },
    setQtyLoading(state, action) {
      state.qtyLoading = action.payload
    },
    setShipping(state, action) {
      state.shippingItemstoCheckout = action.payload
    },
    setNoshipping(state, action) {
      const skuIds = action?.payload?.map(item => item.sku)
      state.noShippingItemsInCheckout = skuIds || []
    },
    setShowShippingDetails(state, action) {
      state.showShippingDetailsForReview = action.payload
    },
    setCartUrl(state, action) {
      state.cartUrl = action.payload
    },
    setPaymetId(state, action) {
      state.paymentId = action.payload
    },
    increment(state, action) {
      state.quantity = state.quantity + 1
    },
    decrement(state, action) {
      state.quantity = state.quantity - 1
    },
    setNameOnCard(state, action) {
      state.nameOnCard = action.payload
    },
    setCardStatus(state, action) {
      state.isCartLoading = action.payload
    },
    setIsPayPalCallback(state, action) {
      state.isPayPalCallback = action.payload
    },
    setIsShipmentLoading(state, action) {
      state.isShipmentLoading = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload.cart,
        }
      })
      .addCase(getCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getCart.fulfilled, (state, action) => {
        state.cart = action.payload
        state.cartCount = getQuantityCount(action.payload?.lineItems) ?? 0
        state.status = "succeeded"
      })
      .addCase(getCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(createCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(createCart.fulfilled, (state, action) => {
        state.cart = action.payload
        state.cartCount = getQuantityCount(action.payload?.lineItems) ?? 0
        state.status = "succeeded"
      })
      .addCase(createCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(shippingCost.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(shippingCost.fulfilled, (state, action) => {
        state.shipping = action.payload
        state.status = "succeeded"
      })
      .addCase(shippingCost.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })

      .addCase(discount.pending, state => {
        state.status = "loading"
      })
      .addCase(discount.fulfilled, (state, action) => {
        state.status = "succeeded"
        // after discount this will set cart with updated data
        state.cart = action.payload
      })
      .addCase(discount.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(taxCalculation.pending, state => {
        state.status = "loading"
      })
      .addCase(taxCalculation.fulfilled, (state, action) => {
        state.status = "succeeded"
        // after tax this will set cart with updated data
        state.cart = action.payload
      })
      .addCase(taxCalculation.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(shipmentUpdate.pending, state => {
        state.status = "loading"
      })
      .addCase(shipmentUpdate.fulfilled, (state, action) => {
        state.status = "succeeded"
        // temp
        const activeCart =
          action.payload?.type === "Cart" &&
          action.payload?.lineItems.length > 0
        state.cart = activeCart ? action.payload : state.cart
      })
      .addCase(shipmentUpdate.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
        state.isNonDeliverableAddress = action.payload?.isNonDeliverableAddress
      })
      .addCase(shareCart.pending, state => {
        // state.status = "loading"
      })
      .addCase(shareCart.fulfilled, (state, action) => {
        // state.status = "succeeded"
      })
      .addCase(shareCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addToCart.pending, (state, action) => {
        // state.status = "loading"
        state.status = "loading"
      })
      .addCase(addToCart.fulfilled, (state, action) => {
        state.cart = action.payload
        state.cartCount = getQuantityCount(action.payload?.lineItems) ?? 0
        state.status = "succeeded"
      })
      .addCase(addToCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(deleteCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(deleteCart.fulfilled, (state, action) => {
        // state.cart = { id: action.payload.id, lineItems: [] }
        state.cartCount = 0
        state.status = "succeeded"
      })
      .addCase(deleteCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(shipmentCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(shipmentCart.fulfilled, (state, action) => {
        if (!action.payload?.shippingCost) {
          state.cart = { ...state.cart, ...action.payload }
        }
        state.status = "succeeded"
      })
      .addCase(shipmentCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addresssSuggest.pending, (state, action) => {
        // state.status = "loading"
      })
      .addCase(addresssSuggest.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(addresssSuggest.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addAddress.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        // state.cart = action.payload
        state.status = "succeeded"
      })
      .addCase(addAddress.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(checkSplitOrder.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(checkSplitOrder.fulfilled, (state, action) => {
        state.cart = { ...state.cart, ...action.payload }
        state.status = "succeeded"
      })
      .addCase(checkSplitOrder.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(mergeCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(mergeCart.fulfilled, (state, action) => {
        state.cart = action.payload
        state.cartCount = getQuantityCount(action.payload?.lineItems) ?? 0
        state.status = "succeeded"
      })
      .addCase(mergeCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(payment.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(payment.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(payment.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(payPalPayment.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(payPalPayment.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(payPalPayment.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(orderSubmit.pending, (state, action) => {
        state.status = "loading" || ""
      })
      .addCase(orderSubmit.fulfilled, (state, action) => {
        // state.cart = action.payload
        state.status = "succeeded" || ""
      })
      .addCase(orderSubmit.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message || ""
      })
      .addCase(removeFromCartLineItem.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(removeFromCartLineItem.fulfilled, (state, action) => {
        state.cart = action.payload
        state.cartCount = getQuantityCount(action.payload?.lineItems) ?? 0
        state.status = "succeeded"
      })
      .addCase(removeFromCartLineItem.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(recentlyViewed.pending, state => {
        state.status = "loading"
      })
      .addCase(recentlyViewed.fulfilled, (state, action) => {
        state.status = "succeeded"
      })
      .addCase(recentlyViewed.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(shareCartaddToCart.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(shareCartaddToCart.fulfilled, (state, action) => {
        const { data, isSharedCart } = action.payload
        if (!isSharedCart) state.cart = data
        state.cartCount = getQuantityCount(data?.lineItems) ?? 0
        state.status = "succeeded"
      })
      .addCase(shareCartaddToCart.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(shareCartDelete.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(shareCartDelete.fulfilled, (state, action) => {
        state.cart = { id: action.payload.id, lineItems: [] }
        state.cartCount = 0
        state.shippingItemstoCheckout = []
        state.status = "succeeded"
      })
      .addCase(shareCartDelete.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {
  setCart,
  increment,
  decrement,
  setCartCount,
  setQtyLoading,
  setCartUrl,
  setShipping,
  setNoshipping,
  setShowShippingDetails,
  setPaymetId,
  setNameOnCard,
  setCardStatus,
  setIsPayPalCallback,
  setIsShipmentLoading,
} = cartSlice.actions
export const selectCartState = state => state.cart
export default cartSlice.reducer
