import React, { useEffect, useState } from "react"
import { useTranslation } from "next-i18next"
import useViewport from "@/hooks/useViewport"
import { getTranslations } from "@/components/VisualSearch/localization"
import {
  CorrectIcon,
  IncorrectIcon,
  CorrectIconMob,
} from "@/components/VisualSearch/v1/UploadImage/icons"
import { getImageCategory } from "@/components/VisualSearch/v1/UploadImage/utils"

const UploadImageSection = props => {
  const { category } = props
  const device = useViewport()
  const { t } = useTranslation()
  const { photoText, toFar, toClose, wrongAngle } = getTranslations(t)
  const [idealImg, setIdeal] = useState({
    idealImage: null,
    badAngle: null,
    tooClose: null,
    tooFar: null,
    text: photoText,
  })
  const { idealImage, badAngle, tooClose, tooFar, text } = idealImg

  useEffect(() => {
    if (device?.isSmallScreen) {
      const timer = setTimeout(() => {
        const elements = document.getElementsByTagName("mavenoid-assistant")
        if (elements.length > 0) {
          document.querySelector("mavenoid-assistant").style.display = "none"
        }
      }, 100)
      return () => {
        document.querySelector("mavenoid-assistant").style.display = "block"
        clearTimeout(timer)
      }
    }
  }, [])

  useEffect(() => {
    const selectedCategory = category?.toLowerCase()
    if (selectedCategory) {
      const selectedCategoryImg = getImageCategory(selectedCategory, device)
      setIdeal(prevstate => {
        return { ...prevstate, ...selectedCategoryImg }
      })
    }
  }, [category])

  return (
    <>
      <div className="main-image-container position-relative ">
        <div className="corret-image-container">
          <img
            className="correct-image"
            src={idealImage?.src}
            alt="Ideal Angle"
          />
        </div>
        <div className="indicator-icon">
          {device?.isExtraSmallScreen ? <CorrectIconMob /> : <CorrectIcon />}
        </div>

        <p className="image-caption">{text}</p>
      </div>
      <div className="outer-wrapper-wrongImage">
        <div className="wrong-image-section">
          <img
            alt="Bad Angle"
            className="incorrect-image"
            src={badAngle?.src}
          />
          <div className="indicator-wrong-icon">
            <IncorrectIcon />
          </div>
          <p className="image-caption">{wrongAngle}</p>
        </div>
        <div className="wrong-image-section">
          <img
            alt="Too Close"
            className="incorrect-image"
            src={tooClose?.src}
          />
          <div className="indicator-wrong-icon">
            <IncorrectIcon />
          </div>
          <p className="image-caption">{toClose}</p>
        </div>
        <div className="wrong-image-section">
          <img alt="Too Far" className="incorrect-image" src={tooFar?.src} />
          <div className="indicator-wrong-icon">
            <IncorrectIcon />
          </div>
          <p className="image-caption">{toFar}</p>
        </div>
      </div>
    </>
  )
}

export default UploadImageSection
