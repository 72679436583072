export const getSessionIdObjWithEpoc = () => {
  const cookies = document.cookie.split(";") ?? []
  let sessionCookie =
    cookies.find(myCookie => myCookie.includes("session#")) ?? ""
  sessionCookie =
    sessionCookie.split("|").find(item => item.includes("session")) ?? ""
  const sessionCookies = sessionCookie.split("#") ?? []
  const sessionId = sessionCookies[1] ?? ""
  return sessionId ? `${sessionId}-${Date.now()}` : ""
}

export const getSessionId = () => {
  const cookies = document.cookie.split(";") ?? []
  let sessionCookie =
    cookies.find(myCookie => myCookie.includes("session#")) ?? ""
  sessionCookie =
    sessionCookie.split("|").find(item => item.includes("session")) ?? ""
  const sessionCookies = sessionCookie.split("#") ?? []
  return sessionCookies[1] ?? ""
}

export const getTntId = () => {
  const cookies = document.cookie.split(";") ?? []
  let tntCookie = decodeURIComponent(
    cookies.find(myCookie => myCookie.includes("AMCV_")) ?? ""
  )
  tntCookie = tntCookie.split("|") ?? ""
  return tntCookie[4] ?? ""
}

export const getUserListLineItems = results => {
  const listItems = {}
  results.forEach(list => {
    list?.lineItems.forEach(lineItem => {
      listItems[lineItem?.productId] = {
        ...lineItem,
        listName: list?.name,
        listId: list?.id,
        listVersion: list?.version,
      }
    })
  })
  return listItems
}

const getMaxVersionOfList = (listName, lists, locale) => {
  const versions = []
  lists.forEach(list => {
    if (list?.name[locale] == listName) {
      versions.push(list?.version)
    }
  })
  return Math.max(...versions)
}

const removeSystemDefinedLists = userList => {
  const systemDefinedListNames = [
    "MyProducts",
    "MyServices",
    "MesServices",
    "Mes produits",
  ]
  return userList.filter(list => !systemDefinedListNames.includes(list))
}

export const getMaxVersionLists = (result, locale) => {
  const listNames = []
  const finalLists = []
  result.forEach(list => {
    listNames.push(list?.name[locale])
  })

  const uniqueListNames = removeSystemDefinedLists([...new Set(listNames)])
  uniqueListNames.forEach(listName => {
    const maxVersion = getMaxVersionOfList(listName, result, locale)
    result.forEach(list => {
      if (list?.name[locale] === listName && list?.version == maxVersion) {
        finalLists.push(list)
      }
    })
  })
  return finalLists
}

export const getMultiDeleteItemsPayLoad = (list, selectedItems) => {
  const deletePayLoad = []
  selectedItems.forEach(productId => {
    list.lineItems.forEach(lineItem => {
      if (lineItem?.productId == productId) {
        deletePayLoad.push({
          action: "removeLineItem",
          lineItemId: lineItem.id,
        })
      }
    })
  })
  return deletePayLoad
}

const getProductSku = (productID, productData) => {
  let sku = ""
  productData.forEach(product => {
    if (product["ctId_s"] == productID) {
      sku = product["masterSKU_s"]
    }
  })
  return sku
}

export const getMovieToListAndDeleteFormListItems = (
  selectedItems,
  currentList,
  productData,
  productQtyObj
) => {
  const deleteItemsList = []
  const addItemsList = []
  selectedItems.forEach(productId => {
    currentList.lineItems.forEach(lineItem => {
      if (lineItem?.productId == productId) {
        deleteItemsList.push({
          action: "removeLineItem",
          lineItemId: lineItem.id,
        })
        addItemsList.push({
          action: "addLineItem",
          sku: getProductSku(productId, productData),
          quantity: productQtyObj[productId],
          custom: {
            type: {
              key: "lineItemShoppingList",
              typeId: "type",
            },
          },
        })
      }
    })
  })
  return [deleteItemsList, addItemsList]
}
