import React from "react"
import { getProResultListEventInfo } from "@/components/FindAProResults/v1/analytics/index"
import Image from "next/image"

const ProfessionalsList = props => {
  const {
    data = {},
    staticTexts = "",
    swatchUrl = "",
    selectedMenu = "",
    serviceProviderType = "",
  } = props
  const {
    Phone: phone = "",
    LocationName: locationName = "",
    AddressLine: addressLine,
    AddressLine2: addressLine2 = "",
    Locality: locality = "",
    AdminDistrict: adminDistrict = "",
    PostalCode: postalCode = "",
    __Distance: distance = "",
    LogoIMG: logoIMG = "",
    SpecGeneral: specGeneral = "",
    SpecIntelligentToilets: specIntelligentToilets = "",
    SpecDigitalShowering: specDigitalShowering = "",
    SpecLighting: specLighting,
    SpecKohlerConnected: specKohlerConnected = "",
    SpecShowerDoors: specShowerDoors = "",
    Email: email = "",
  } = data

  const handleConatctDetalisClick = (isEmail, url) =>
    getProResultListEventInfo({
      phone: phone,
      url: `mailto:${url}`,
      partnerName: locationName.toLowerCase(),
      isEmail: isEmail,
      needLocationFinderType: false,
      staticTexts: staticTexts,
      selectedMenu: selectedMenu,
      serviceProviderType: serviceProviderType,
    })
  return (
    <div className="find-pro-results__content-section" role="listitem">
      <div className="find-pro-results__content-section__space">
        <div className="row">
          <div className="col-sm-6 col-md-6">
            <div className="row">
              <div
                className="col-6 col-sm-6 col-md-6 col-lg-12 partner-name"
                role="heading"
                level="3"
              >
                {locationName.toLowerCase()}
              </div>
              <div className="col-6 col-sm-6 col-md-6 col-lg-12 partner-rating"></div>
            </div>
            <div className="profile-details">
              <div className="partner-details">
                <div className="partner-address">
                  {addressLine.toLowerCase()}
                  {addressLine2 ? ", " + addressLine2.toLowerCase() : ""}
                </div>
                <div className="partner-address">
                  {locality ? locality.toLowerCase() + ", " : ""}
                  {adminDistrict} {postalCode}
                </div>
                <div className="miles-away">{`(${distance.toFixed(
                  1
                )} mi away)`}</div>
              </div>
              {logoIMG ? (
                <div className="partner-photography">
                  {/* TODO: Make URL configurable */}
                  <Image src={`${swatchUrl}/${logoIMG}`} alt="logo" />
                </div>
              ) : null}
            </div>
          </div>

          <div className="col-sm-6 col-md-6">
            <div className="row mx-0">
              {specGeneral ||
              specIntelligentToilets ||
              specDigitalShowering ||
              specLighting ||
              specKohlerConnected ||
              specShowerDoors ? (
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 speciality">
                  <div className="speciality-title">
                    {staticTexts?.specialtiesTxt}
                  </div>
                  <div className="speciality-details">
                    <ul>
                      {specGeneral ? <li>{staticTexts?.SpecGeneral}</li> : ""}
                      {specIntelligentToilets ? (
                        <li>{staticTexts?.SpecIntelligentToilets}</li>
                      ) : (
                        ""
                      )}
                      {specDigitalShowering ? (
                        <li>{staticTexts?.SpecDigitalShowering}</li>
                      ) : (
                        ""
                      )}
                      {specLighting ? <li>{staticTexts?.SpecLighting}</li> : ""}
                      {specKohlerConnected ? (
                        <li>{staticTexts?.SpecKohlerConnected}</li>
                      ) : (
                        ""
                      )}
                      {specShowerDoors ? (
                        <li>{staticTexts?.SpecShowerDoors}</li>
                      ) : (
                        ""
                      )}
                    </ul>
                  </div>
                </div>
              ) : null}

              {email || phone ? (
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 contact">
                  <div className="contact-title">{staticTexts?.contactTxt}</div>
                  <div className="contact-details">
                    {email ? (
                      <div className="email">
                        <a
                          href={`mailto:${email}`}
                          onClick={() => handleConatctDetalisClick(true, email)}
                          aria-label={staticTexts?.ariaLabel.email(
                            locationName,
                            email
                          )}
                        >
                          {email}
                        </a>
                      </div>
                    ) : null}
                    {phone ? (
                      <div className="phone">
                        <a
                          href={`tel:${phone}`}
                          onClick={() =>
                            handleConatctDetalisClick(false, phone)
                          }
                          aria-label={staticTexts?.ariaLabel?.phone(
                            locationName,
                            phone
                          )}
                        >
                          {phone}
                        </a>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProfessionalsList
