import React, { useEffect, useRef } from "react"
import { sanitizeInnerHtml } from "@/utils/helper"
import { getProResultListEventInfo } from "@/components/FindAProResults/v1/analytics/index"
import Image from "next/image"

const DesignerList = props => {
  const contactRef = useRef(null)
  const {
    data = {},
    staticTexts = "",
    selectedMenu = "",
    serviceProviderType = "",
  } = props
  const {
    proName = "",
    address = "",
    jobTitle = "",
    available = "",
    duration = "",
    image = "",
    specialties = "",
    contactInfo = "",
  } = data
  useEffect(() => {
    if (contactInfo) {
      if (contactRef.current) {
        const links = contactRef.current.querySelectorAll("a")
        links.forEach(link => {
          link.classList.add("gbh-data-layer")
          const phone = link.textContent
          const url = link.getAttribute("href")
          const isEmail = url.includes("mailto")
          link.setAttribute(
            "data-gbh-data-layer",
            getProResultListEventInfo({
              phone: phone,
              url: url,
              partnerName: proName,
              isEmail: isEmail,
              staticTexts: staticTexts,
              selectedMenu: selectedMenu,
              serviceProviderType: serviceProviderType,
            })
          )
        })
      }
    }
  }, [data])
  const clickHandler = () => {
    getProResultListEventInfo({
      phone: null,
      url: data["detail-page-link"] ?? "/",
      partnerName: proName,
      isEmail: false,
      needLocationFinderType: true,
      isForServiceProvider: true,
      staticTexts: staticTexts,
      selectedMenu: selectedMenu,
      serviceProviderType: serviceProviderType,
    })
  }
  return (
    <div role="listitem" className="find-pro-results__content-section">
      <a
        onClick={clickHandler}
        href={data["detail-page-link"] ?? "/"}
        className={`gbh-data-layer find-pro-results__designer-link ${
          data["detail-page-link"] ? "link-added" : "link-removed"
        }`}
      >
        <div className="find-pro-results__content-section__space">
          <div className=" row">
            <div className="col-sm-6 col-md-6">
              <div className="col-12 col-sm-12 col-md-12 px-0">
                <div className="row">
                  <div
                    className="col-6 col-sm-6 col-md-6 col-lg-12 partner-name"
                    role="heading"
                    level="3"
                  >
                    {proName}
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-12 partner-rating"></div>
                </div>
                <div className="profile-details">
                  <div className="partner-details">
                    <div className="partner-address">
                      {sanitizeInnerHtml(address)}
                    </div>
                    <div className="partner-designation">
                      {sanitizeInnerHtml(jobTitle)}
                    </div>
                    {available === true ? (
                      <p
                        className="available-btn"
                        role="button"
                        tabIndex="0"
                        aria-label={staticTexts.availableCTA}
                      >
                        {sanitizeInnerHtml(staticTexts.availableCTA)}
                      </p>
                    ) : (
                      <div className="available-duration">
                        {sanitizeInnerHtml(duration)}
                      </div>
                    )}
                  </div>
                  <div className="partner-photography">
                    {Boolean(image) ? (
                      <Image src={image} alt={staticTexts.userAlt} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-6">
              <div className="row mx-0">
                {specialties?.length ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 speciality">
                    <div className="speciality-title">
                      {staticTexts.specialtiesTxt}
                    </div>
                    <div className="speciality-details">
                      {sanitizeInnerHtml(specialties)}
                    </div>
                  </div>
                ) : null}
                {contactInfo?.length ? (
                  <div className="col-12 col-sm-12 col-md-12 col-lg-6 contact">
                    <div className="contact-title">
                      {staticTexts.contactTxt}
                    </div>
                    <div className="contact-details" ref={contactRef}>
                      {sanitizeInnerHtml(contactInfo)}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default DesignerList
