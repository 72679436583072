import React from "react"
import { useTranslation } from "next-i18next"
import Style from "@/components/VisualSearch/v1/ComingSoon/comingSoon.module.scss"
import { getTranslations } from "@/components/VisualSearch/localization"
import { getCategoryURL } from "@/components/VisualSearch/v1/ComingSoon/utils"

const ComingSoon = props => {
  const { setSection, category } = props
  const categorySelected = category?.toLowerCase()
  const { t } = useTranslation()
  const {
    recognitionCategory = () => {},
    recognitionCategorySubText = () => {},
    viewGuidtext,
    backOnCategory,
    commingSoon,
  } = getTranslations(t)

  const viewGuide = () => {
    const supportUrl = getCategoryURL(category)
    window.open(supportUrl, "_blank")
  }

  return (
    <div className={Style.ComingSoonContainer}>
      <div className="para-section">
        <p className="working-para">{commingSoon}</p>
        <div className="button-section">
          <p className="para-two-section">
            {recognitionCategory(categorySelected)}
            <span className="secong-line">
              {recognitionCategorySubText(categorySelected)}
            </span>
          </p>
          <button type="button" className="view-button" onClick={viewGuide}>
            <span className="button-label">{viewGuidtext}</span>
          </button>
        </div>
        <button className="back-button" onClick={() => setSection("category")}>
          <p>{backOnCategory}</p>
        </button>
      </div>
    </div>
  )
}

export default ComingSoon
