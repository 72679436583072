import { getShortenedUrlPath } from "@/utils/helper"
import { useTranslation } from "next-i18next"
const useFindAProResultsi18n = (componentProp = "") => {
  const { t } = useTranslation()
  const i18n = {
    remodelersTitleText: t("kf.findpro.label.remodelersTitleText"),
    plumbersInstallersTitleText:
      t("kf.findpro.label.plumbersInstallersTitleBaseText") +
      t("kf.findpro.label.installers") +
      " & " +
      t("kf.findpro.label.plumbers"),
    buildersTitleText: t("kf.findpro.label.buildersTitleText"),

    architectTitleText: t("kf.findpro.label.architectTitleText"),
    designersTitleText: t("kf.findpro.label.designersTitleText"),
    remodelers: t("kf.findpro.label.remodelers"),
    plumbersInstallers:
      t("kf.findpro.label.plumbers") + " & " + t("kf.findpro.label.installers"),
    builders: t("kf.findpro.label.builders"),
    bathroomDesignConsultText: componentProp?.bathroomDesignConsultCopy
      ? getShortenedUrlPath(componentProp?.bathroomDesignConsultCopy)
      : t("kf.findpro.label.serviceInfoText"),
    changeLocationText: t("kf.findpro.label.changeLocationText"),
  }
  return i18n
}
export default useFindAProResultsi18n
