import React, { Fragment } from "react"
import { sanitizeInnerHtml } from "@/utils/helper"

const NoResults = props => {
  const {
    professionalcount,
    brandName,
    findAProbyZipPagePath,
    zipcodeVal,
    staticTexts,
    subMenusRef,
    dataList,
    visibleSection,
    designersRef,
    handleMenuClick,
    handleKeyPress,
    bathroomDesignServiceLinkRef,
  } = props
  const bathroomDesignConsultText = staticTexts?.bathroomDesignConsultText

  return (
    <Fragment>
      <div className="find-pro-results__local-professional-details">
        <div className="container kf-react-container">
          <div className="local-professional-count">
            {`Showing ${professionalcount} professionals near `}
            {brandName === "sterling" ? (
              <a
                tabIndex="0"
                className="local-professional-count"
                href={findAProbyZipPagePath}
              >
                {zipcodeVal}
              </a>
            ) : (
              zipcodeVal
            )}
            <a
              id="request-change-location"
              tabIndex="0"
              className="change-location"
              href={findAProbyZipPagePath}
            >
              {staticTexts?.changeLocationText}
            </a>
          </div>
        </div>
      </div>
      <div className="find-pro-results__sub-navigation" ref={subMenusRef}>
        <nav className="container kf-react-container">
          <ul role="menubar">
            {dataList.map((smenu, index) => {
              return smenu.data?.length > 0 && smenu.section !== "Designers" ? (
                <li
                  key={index}
                  className={`header_link ${
                    visibleSection === smenu.menuName ? "selected" : ""
                  }`}
                  onClick={() =>
                    handleMenuClick(smenu.ref.current, smenu.menuName)
                  }
                  onKeyDown={e => handleKeyPress(e, smenu.section)}
                  role="menuitem"
                  tabIndex={visibleSection === smenu.menuName ? 0 : -1}
                  id={`section-${smenu.section.toLowerCase()}`}
                >
                  {smenu.menuName}
                </li>
              ) : null
            })}
            <li
              className={`header_link ${
                visibleSection === staticTexts?.designers ? "selected" : ""
              }`}
              onClick={() =>
                handleMenuClick(designersRef.current, staticTexts?.designers)
              }
              onKeyDown={e => handleKeyPress(e, "Designers")}
              role="menuitem"
              tabIndex={visibleSection === staticTexts?.designers ? 0 : -1}
              id={`section-designers`}
            >
              {staticTexts?.designers}
            </li>
          </ul>
        </nav>
      </div>
      <div
        className="find-pro-results__designer-section"
        id="Designers"
        ref={designersRef}
        tabIndex="-1"
      >
        <div className="container kf-react-container">
          <div className="find-pro-results__container">
            <div
              className="d-inline-block service-info"
              ref={bathroomDesignServiceLinkRef}
            >
              {sanitizeInnerHtml(bathroomDesignConsultText)}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default NoResults
