const getProResultListEventInfo = data => {
  const {
    phone = "",
    url = "",
    partnerName = "",
    isEmail = false,
    needLocationFinderType = false,
    isForServiceProvider = false,
    staticTexts = {},
    selectedMenu = "",
    serviceProviderType = "",
  } = data
  const sleectedMenuName = selectedMenu ? selectedMenu : staticTexts?.designers
  const internalLinkName = isEmail
    ? "services@kohler.com"
    : phone || partnerName

  const type = phone
    ? "contact " + (isEmail ? "email" : "number")
    : isForServiceProvider
    ? "results"
    : partnerName

  let internalLinkType = "find a pro results:navigation"

  if (phone)
    internalLinkType = `find a pro results:contact:${partnerName}:${sleectedMenuName?.toLowerCase()}`

  if (needLocationFinderType)
    internalLinkType = `find a pro results:${sleectedMenuName?.toLowerCase()}`

  const serviceProviderName = serviceProviderType?.toLowerCase() || ""

  const datalayerObj = {
    clickInternalLinks: "true",
    eventAction: `find a pro results:service providers:${type}`,
    eventName: `find a pro results:service providers:${type}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName,
    internalLinkPosition: "find a pro results",
    internalLinkType,
    internalLinkURL: url || "n/a",
    internalLinkZoneName: `find a pro results:${serviceProviderName}`,
  }

  window.adobeDataLayer.push(datalayerObj)
}

export { getProResultListEventInfo }
