import SelectProductCategoryView from "@/components/VisualSearch/v1/SelectProductCategory/SelectProductCategory.view"
import Qrcode from "@/components/VisualSearch/v1/QRCode/index"
import Style from "@/components/VisualSearch/v1/SelectProductCategory/index.module.scss"
import useViewport from "@/hooks/useViewport"

const SelectProductCategory = props => {
  const { updateCategoryItems, setOpenCam } = props
  const device = useViewport()

  return (
    <div className={`${Style.SelectProductCategoryWrapper}`}>
      <div className="inpage-search_category inpage-list-category">
        <SelectProductCategoryView
          updateCategoryItems={updateCategoryItems}
          setOpenCam={setOpenCam}
        />
        {!device?.isSmallScreen ? <Qrcode /> : null}
      </div>
    </div>
  )
}

export default SelectProductCategory
