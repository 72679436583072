export const getTranslations = t => {
  return {
    recognitionCategory: category =>
      t("kf.visualsearch.label.recognitionCategory", { category }),
    recognitionCategorySubText: category =>
      t("kf.visualsearch.label.recognitionCategorySubText", { category }),
    faucetGuide: t("kf.visualsearch.label.faucetGuide"),
    toiletGuide: t("kf.visualsearch.label.toiletGuide"),
    volveGuide: t("kf.visualsearch.label.volveGuide"),
    defaultGuide: t("kf.visualsearch.label.defaultGuide"),
    commingSoon: t("kf.visualsearch.label.commingSoon"),
    viewGuidtext: t("kf.visualsearch.button.viewGuid"),
    backOnCategory: t("kf.visualsearch.button.backOnCategory"),
    errorText: t("kf.visualsearch.ErrorPage.errorText"),
    errorSubText: t("kf.visualsearch.ErrorPage.errorSubText"),
    tryAgainBtn: t("kf.visualsearch.ErrorPage.button.tryAgainBtn"),
    QRCodeText: t("kf.visualsearch.QRCode.text"),
    textCommingSoon: t("kf.visualsearch.label.textCommingSoon"),
    imageUploadtext: t("kf.visualsearch.ImageUpload.text"),
    borwseBtn: t("kf.visualsearch.ImageUpload.button.borwse"),
    changeProduct: t("kf.visualsearch.ImageUpload.text.changeProduct"),
    imageUploadSubText: t("kf.visualsearch.ImageUpload.subText"),
    photoGuidelineText: t("kf.visualsearch.ImageUpload.photoGuidelineText"),

    photoText: t("kf.visualsearch.ImageUpload.photoText"),
    wrongAngle: t("kf.visualsearch.ImageUpload.wrongAngle"),
    toClose: t("kf.visualsearch.ImageUpload.toClose"),
    toFar: t("kf.visualsearch.ImageUpload.toFar"),
  }
}
