import uploadImg from "@/public/images/animated/uploadImage.svg"
import matchFound from "@/public/images/animated/successImage.svg"
import matchingImg from "@/public/images/animated/cricleImage.svg"
import retrievingImg from "@/public/images/animated/downloadData.svg"
export const data = [
  {
    text: "Uploading image...",
    imageUrl: uploadImg.src,
    status: true,
  },
  {
    text: "Matching products...",
    imageUrl: matchingImg.src,
    status: true,
  },
  {
    text: "Match found.",
    imageUrl: matchFound.src,
    status: true,
  },
  {
    text: "Retrieving details...",
    imageUrl: retrievingImg.src,
    status: true,
  },
]
export const APP_URL = "https://www.kohler.com"
