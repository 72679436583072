import { apim } from "@/constants/api"
import { oktaTokenEndpoint, signupEndPoint } from "@/constants/index"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { authClient } from "@/services/auth.service"
import {
  caesarDecrypt,
  clearAnonymousToken,
  clearAuthToken,
  getAccessToken,
  getUserPersona,
  revalidateTime,
  shaEncrypt,
  createPersonaCookie,
  hourDifference,
  getShortenedUrl,
} from "@/utils/helper"
import { removeCustomerCartId } from "@/utils/cart"
import { getCart, mergeCart } from "@/store/features/cartSlice"

import { getConfig } from "@/constants/config"
import CONS from "@/constants"
import _isEmpty from "lodash/isEmpty"
import { getAnonymousId } from "@/utils/helper"

const initialState = {
  isAuth: false,
  anonymousId: "",
  access_token: "",
  refresh_token: "",
  expires_in: "",
  revalidate_in: "",
  scopes: [],
  token_type: "",
  status: "",
  error: "",
  authModal: {
    show: false,
    staticText: {},
    formData: {},
    active: 0,
  },
  showCartAction: false,
  hashedEmail: "",
  customerProspect: "",
  showForgotPassword: false,
  showCustomerSignup: false,
  showProSignup: false,
  hideCustomerSignupCTA: false,
  user: {},
}

export const getOktaToken = createAsyncThunk(
  "auth/getOktaToken",
  async type => {
    const { gId } = getAccessToken()
    const { apiEndPointData: { map: { channel } } = {} } = await getConfig()
    const path = `${oktaTokenEndpoint}?scope=${type}${
      channel ? `&channel=${channel}` : ""
    }`
    const response = await apim.post(path, null, {
      params: {
        gId,
      },
    })
    return response.data
  }
)

export const login = createAsyncThunk(
  "auth/login",
  async (payload, thunkAPI) => {
    const { username, password, isFirstLogin } = payload
    try {
      const config = await getConfig()
      const { apiEndPointData: { map: { brandUsernameExtension } } = {} } =
        config
      const usernameWithExtension = brandUsernameExtension
        ? `${username}.${brandUsernameExtension}`
        : username
      const res = await authClient.signInWithCredentials({
        username: usernameWithExtension,
        password,
      })
      if (res.status === "SUCCESS") {
        return authClient.token
          .getWithoutPrompt({
            responseType: ["id_token", "access_token"],
            sessionToken: res.sessionToken,
          })
          .then(async function ({ tokens }) {
            thunkAPI.dispatch(setAuthModalVisibility({ show: false }))
            const anonymousId = getAnonymousId()
            localStorage.setItem("tempAnonymousId", anonymousId)
            clearAnonymousToken()
            authClient.tokenManager.setTokens(tokens)

            const payload = {
              accessToken: tokens.accessToken.accessToken,
              refreshToken: tokens.refreshToken.refreshToken,
              user: tokens.accessToken?.claims || {},
              isAuth: true,
            }

            await thunkAPI.dispatch(postLogin(payload))

            if (!isFirstLogin) {
              thunkAPI
                .dispatch(getCart())
                .unwrap()
                .then((res = {}) => {
                  const { lineItems = [] } = res

                  if (lineItems.length) {
                    thunkAPI.dispatch(setShowCartAction(true))
                  } else {
                    const mergeCartPayload = {
                      anonymousId,
                    }
                    if (anonymousId) {
                      thunkAPI
                        .dispatch(mergeCart(mergeCartPayload))
                        .unwrap()
                        .then(() => {
                          thunkAPI.dispatch(setAnonymousId(""))
                          localStorage.removeItem("tempAnonymousId")
                          const disableCommerce =
                            config?.general?.disableCommerce ?? false
                          if (!disableCommerce) {
                            thunkAPI.dispatch(getCart())
                          }
                        })
                    }
                  }
                })
            }

            // ------------ functionality review start ----------------
            const state = thunkAPI.getState()
            const loginCallback = window.loginCallback
            const { hideCustomerSignupCTA } = state.auth
            if (loginCallback) {
              if (hideCustomerSignupCTA) {
                const persona = getUserPersona()
                const { general: { homepageUrl = "" } = {} } = await getConfig()
                if (persona !== "TVS") {
                  window.location.replace(
                    await getShortenedUrl(homepageUrl ?? "/")
                  )
                } else
                  window.KPNSignIn
                    ? window.location.replace(loginCallback)
                    : null
              } else {
                if (typeof loginCallback === "function") {
                  loginCallback()
                } else {
                  window.location.replace(loginCallback)
                  // await authClient.session.setCookieAndRedirect(res.sessionToken)
                }
                window.loginCallback = null
              }
            }
            // ------------ functionality review end ----------------
            // await authClient.session.setCookieAndRedirect(res.sessionToken)

            return thunkAPI.fulfillWithValue("success")
          })
          .catch(function (err) {
            return thunkAPI.fulfillWithValue(err?.errorSummary || "error")
          })
      } else if (res.status === "LOCKED_OUT") {
        return thunkAPI.fulfillWithValue({ lockedOut: true })
      } else if (res.status === "PASSWORD_EXPIRED") {
        return thunkAPI.fulfillWithValue({
          passwordExpired: true,
          data: res.data,
        })
      }
    } catch (err) {
      return thunkAPI.fulfillWithValue(err?.errorSummary || "error")
    }
  }
)

export const signup = createAsyncThunk(
  "auth/signup",
  async (payload, thunkAPI) => {
    try {
      const response = await apim.post(`${signupEndPoint}`, payload)
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data)
    }
  }
)

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (payload, thunkAPI) => {
    const { username } = payload
    try {
      const CONFIG = await getConfig()
      const { oktaChangePasswordUri } = CONFIG?.apiEndpoints
      const { map: { brandUsernameExtension } = {} } = CONFIG?.apiEndPointData

      const usernameWithExtension = brandUsernameExtension
        ? `${username}.${brandUsernameExtension}`
        : username

      const response = await authClient.forgotPassword({
        username: usernameWithExtension,
        factorType: "EMAIL",
        relayState: oktaChangePasswordUri,
      })
      return response.data
    } catch (err) {
      return thunkAPI.rejectWithValue(err)
    }
  }
)

export const logout = createAsyncThunk(
  "auth/logout",
  async (payload, thunkAPI) => {
    try {
      thunkAPI.dispatch(clearAuthState())
      createPersonaCookie()
      await authClient.session.close()
      removeCustomerCartId()
      await authClient.signOut({
        clearTokensBeforeRedirect: true,
      })
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.response?.data)
    }
  }
)

export const postLogin = createAsyncThunk("auth/postLogin", async payload => {
  const { user = {} } = payload
  if (user?.sub) {
    // this is email id hashing
    const res = await shaEncrypt(user?.sub)
    createPersonaCookie()
    return {
      ...payload,
      encryptedEmail: res,
    }
  }
})

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearAuthState(state, action) {
      clearAuthToken()
      if (window) {
        window.authAccessToken = ""
      }
      state.isAuth = false
      state.anonymousId = ""
      state.access_token = ""
      state.refresh_token = ""
      state.revalidate_in = ""
      state.user = {}
      state.hashedEmail = ""
      state.customerProspect = ""
      state.auth = {}
      state.hideCustomerSignupCTA = false
    },
    setAuthData(state, action) {
      const {
        accessToken = "",
        refreshToken = "",
        isAuth = false,
        user = {},
      } = action.payload
      if (accessToken) {
        state.access_token = accessToken
        if (window) {
          window.authAccessToken = accessToken
        }
        if (!isAuth) {
          localStorage.setItem("okta-anonymous-access-token", accessToken)
        }
      }
      if (refreshToken) {
        state.refresh_token = refreshToken
        localStorage.setItem("okta-anonymous-refresh-token", refreshToken)
      }
      if (isAuth) state.isAuth = isAuth
      if (user) state.user = user
      state.revalidate_in = revalidateTime()
    },
    setRevalidateTime(state, action) {
      state.revalidate_in = revalidateTime()
    },
    setAuthModalVisibility(state, action) {
      const { show, active = 0, onCloseHandlation = () => {} } = action.payload
      state.authModal = {
        ...state.authModal,
        show: show,
        active: active,
        onCloseHandlation: onCloseHandlation,
      }
      state.showCustomerSignup = false
      state.showProSignup = false
      state.showForgotPassword = false
      state.hideCustomerSignupCTA = show ? state.hideCustomerSignupCTA : false
    },
    setAuthModalActiveTab(state, action) {
      state.authModal = {
        ...state.authModal,
        active: action.payload,
      }
    },
    setAuthModalStaticText(state, action) {
      state.authModal = {
        ...state.authModal,
        staticText: action.payload,
      }
    },
    setAnonymousId(state, action) {
      const anonymousId = action.payload
      state.anonymousId = anonymousId
      localStorage.setItem("anonymousId", anonymousId)
    },
    setShowCartAction(state, action) {
      state.showCartAction = action.payload
    },
    setShowCustomerSignup(state, action) {
      state.showCustomerSignup = action.payload
      state.showProSignup = false
      state.showForgotPassword = false
      state.authModal = {
        ...state.authModal,
        show: action.payload,
      }
    },
    setShowProSignup(state, action) {
      state.showProSignup = action.payload
      state.showCustomerSignup = false
      state.showForgotPassword = false
      state.authModal = {
        ...state.authModal,
        show: action.payload,
      }
    },
    setShowForgotPassword(state, action) {
      state.showForgotPassword = action.payload
      state.showCustomerSignup = false
      state.showProSignup = false
      state.authModal = {
        ...state.authModal,
        show: action.payload,
      }
    },
    setHideCustomerSignupCTA(state, action) {
      state.hideCustomerSignupCTA = action.payload
    },
    setPersona(state, action) {
      const {
        isAuth,
        user: { uid },
      } = state
      const { user } = state

      if (isAuth) {
        sessionStorage.setItem(
          CONS.KF_UPDATE_MEMBERSHIP,
          JSON.stringify({ uid })
        )
        localStorage.setItem(CONS.KF_FORCE_RENEW_TOKEN, true)

        state.user = {
          ...user,
          persona: [action.payload],
        }
      }
    },
    setUserName(state, action) {
      const { isAuth, user } = state
      const [firstName = "", lastName = ""] = action?.payload ?? []
      if (isAuth) {
        state.user = {
          ...user,
          firstName,
          lastName,
        }
      }
    },
    setAuthModalFormData(state, action) {
      state.authModal.formData = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload.auth,
        }
      })
      .addCase(getOktaToken.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(getOktaToken.fulfilled, (state, action) => {
        const {
          access_token: accessToken = "",
          refresh_token: refreshToken = "",
          expires_in: expiresIn = "",
          scopes = [],
          token_type: tokenType = "",
        } = action.payload
        if (state?.access_token) {
          if (window) {
            window.authAccessToken = state?.access_token
          }
          localStorage.setItem(
            "okta-anonymous-access-token",
            state?.access_token
          )
        } else if (accessToken) {
          state.access_token = accessToken
          if (window) {
            window.authAccessToken = accessToken
          }
          localStorage.setItem("okta-anonymous-access-token", accessToken)
        }
        if (state?.refresh_token) {
          localStorage.setItem(
            "okta-anonymous-refresh-token",
            state?.refresh_token
          )
        } else if (refreshToken) {
          state.refresh_token = refreshToken
          localStorage.setItem("okta-anonymous-refresh-token", refreshToken)
        }

        if (expiresIn) state.expires_in = expiresIn
        if (scopes) state.scopes = scopes
        if (tokenType) state.token_type = tokenType
        state.revalidate_in = revalidateTime()
        state.status = "succeeded"
        state.error = ""
      })
      .addCase(getOktaToken.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(login.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(login.rejected, (state, action) => {
        console.log("LOGIN", action.payload)
      })
      .addCase(login.fulfilled, (state, action) => {
        console.log("LOGIN", action.payload)
      })
      .addCase(signup.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(signup.rejected, (state, action) => {
        console.log("SIGNUP rejected", action.error)
      })
      .addCase(signup.fulfilled, (state, action) => {
        console.log("SIGNUP fulfilled", action.payload)
      })
      .addCase(postLogin.pending, (state, action) => {
        state.status = "loading"
      })
      .addCase(postLogin.fulfilled, (state, action) => {
        const {
          encryptedEmail = "",
          accessToken = "",
          refreshToken = "",
          user = {},
          isAuth = "",
        } = action?.payload
        const oldHashedEmail = state.hashedEmail
        const oldCustomerProspect = state.customerProspect
        if (accessToken) {
          state.access_token = accessToken
          if (window) {
            window.authAccessToken = accessToken
          }
        }

        if (refreshToken) state.refresh_token = refreshToken
        if (!_isEmpty(user)) {
          state.user = user
        }
        if (isAuth !== "") state.isAuth = isAuth
        if (encryptedEmail) state.hashedEmail = encryptedEmail
        state.revalidate_in = revalidateTime()

        let needsUpdate = false
        if (oldCustomerProspect && oldHashedEmail === encryptedEmail) {
          // here check if existing customerProspects are outdated
          try {
            const decryptedCaesar = caesarDecrypt(
              encryptedEmail,
              oldCustomerProspect
            )
            const decryptedJSON = JSON.parse(decryptedCaesar)
            const currDate = new Date().toString()
            if (
              decryptedJSON.updatedAt &&
              hourDifference(decryptedJSON.updatedAt, currDate) >= 1
            ) {
              needsUpdate = true
            }
          } catch (e) {
            needsUpdate = true
          }
        } else {
          needsUpdate = true
        }
        if (needsUpdate) {
          /**
           * checking 3 conditions here
           *  1. if hashed emails are different
           *  2. if hashed emails are same then check if sync is done 1 hour ago or before that
           *  3. if old customer prospect object is missing from localstorage
           */
          // await getPastOrders(true)
        }
      })
      .addCase(postLogin.rejected, (state, action) => {
        console.log(action.error)
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.cart = {}
      })
  },
})

export const {
  clearAuthState,
  setAuthData,
  setRevalidateTime,
  setAuthModalVisibility,
  setAuthModalActiveTab,
  setAuthModalStaticText,
  setAnonymousId,
  setShowCustomerSignup,
  setShowProSignup,
  setShowForgotPassword,
  setHideCustomerSignupCTA,
  setPersona,
  setUserName,
  setAuthModalFormData,
  setShowCartAction,
} = authSlice.actions
export const selectAuthState = state => state.auth
export default authSlice.reducer
