import {
  handleZipDownload,
  sanitizeInnerHtml,
  sanitizeUrl,
} from "@/utils/helper"
import ShareIcon from "@/public/icons/share.svg"
import DownloadIcon from "@/public/icons/download-dark-gray.svg"
import { useEffect, useState } from "react"
import { getConfig } from "@/constants/config"
import _get from "lodash/get"
import isEmpty from "lodash/isEmpty"
import { useRef } from "react"
import { useTranslation } from "next-i18next"
import { getShortenedUrl } from "@/utils/location"
import { ConditionalWrapper } from "@/utils/index"
import { useDispatch } from "react-redux"
import { shareCart as sharedCartFromStore } from "@/store/features/cartSlice"

const SocialShare = ({
  cartId,
  analyticEventData,
  specFiles,
  mobileView = false,
  componentProp: { assetUrl = "", url: compUrl = "" } = {},
  enableDownload = true,
  videoComp = false,
}) => {
  // *****
  const [shareCart, setShareCart] = useState({ show: false, id: "", url: "" })
  const [copyIcon, setCopyIcon] = useState(false)
  const [link, setLink] = useState("")
  const [downloading, setDownloading] = useState(false)
  const url = shareCart.url || ""
  const [sites, setSites] = useState([])
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const origin = window.location.origin
  const brandName = useRef("")
  const [socialMediaConfigs, setSocialMediaConfigs] = useState({})
  const emailBtnRef = useRef(null)
  const copyBtnRef = useRef(null)
  const shareBoxRef = useRef(null)
  const shareCartUrl = useRef("")
  const socialShareCopyIconPath = useRef("")
  const socialShareEmailIconPath = useRef("")
  const hostUrlPath = useRef("")

  useEffect(() => {
    getConfig().then(async config => {
      const socialMediaConfigs = _get(config, "socialMediaConfig", {})
      setSocialMediaConfigs(socialMediaConfigs)
      brandName.current = _get(config, "general?.siteName", "")
      const { general: { hostUrl = "" } = {} } = config ?? {}
      hostUrlPath.current = hostUrl

      socialShareCopyIconPath.current = _get(
        config,
        "general.socialShareCopyIconPath",
        ""
      )
      socialShareEmailIconPath.current = _get(
        config,
        "general.socialShareEmailIconPath",
        ""
      )
      shareCartUrl.current = await getShortenedUrl(
        _get(config, "general.shareCartPagePath", "")
      )
    })

    getLink().then(urlLink => {
      setLink(urlLink)
    })
  }, [])

  const handleShareCart = () => {
    setShareCart({ ...shareCart, show: !shareCart.show })
    addDataLayer("share cart", link)
  }

  const handleShareCartCopyLink = data => {
    const { isCopyLink = false, ismail = false } = data
    const payload = { cartId: cartId }
    return dispatch(sharedCartFromStore(payload))
      .unwrap()
      .then(res => {
        if (res?.sharedCartId) {
          const shareUrl =
            window.location.origin +
            shareCartUrl.current +
            `?share-id=${res.sharedCartId}`
          if (isCopyLink) {
            setShareCart({
              ...shareCart,
              id: res.sharedCartId,
              url: shareUrl,
            })
            navigator.clipboard.writeText(shareUrl)
            addDataLayer("copy link", shareUrl ?? "n/a")
          } else if (ismail) {
            return `share-id=${res.sharedCartId}`
          }
          return shareUrl
        }
      })
  }
  // get component social share link url
  const getLink = async () => {
    const isFullUrl = /^https?:\/\//i
    if (url && isFullUrl.test(url)) {
      return url
    }
    if (url) {
      return `${origin}${url}`
    } else if (assetUrl && compUrl) {
      // teaser video component link all cases
      if (!isFullUrl.test(compUrl)) {
        const newcompUrl = await getShortenedUrl(compUrl)
        return `${origin}${newcompUrl}`
      } else {
        return compUrl
      }
    } else if (assetUrl) {
      return assetUrl
    } else {
      return sanitizeUrl(window.location.href)
    }
  }

  const i18n = {
    email: t("kf.share.label.email"),
    emailSubject: t("kf.share.label.emailSubject"),
    copyLink: t("kf.share.label.copyLink"),
    copyLinkIndication: t("kf.share.label.copyLinkIndication"),
    yourSharedCartNumber: t("kf.share.label.yourSharedCartNumber", {
      cartNumber: shareCart.id,
    }),
    linksCTA: siteKey =>
      t("kf.pdp.label.aria.socialLinksCTA", { brandName, siteKey }),
    openNewTabText: t("kf.share.label.openNewTabText"),
    shareCartDesc: t("kf.cart.label.shareCartDesc"),
    shareCart: t("kf.cart.label.shareCart"),
    mailSubject: t("kf.share.label.emailSubject"),
    downloadSpecs: t("kf.cart.label.downloadSpecs"),
  }

  useEffect(() => {
    const siteList = []
    socialMediaConfigs &&
      socialMediaConfigs?.myArrayList?.forEach(socialMediaConfig => {
        if (!isEmpty(socialMediaConfig.map && socialMediaConfig.map.name)) {
          const site = {
            key: socialMediaConfig.map.name ?? "",
            icon: socialMediaConfig.map.iconPath ?? "",
          }

          const url = socialMediaConfig.map.redirectUrl ?? ""
          if (socialMediaConfig.map.name.toLowerCase().includes("twitter")) {
            siteList.push({
              ...site,
              url: encodeURI(url + link + `&text=${document.title}`),
            })
          } else if (
            socialMediaConfig.map.name.toLowerCase().includes("pinterest")
          ) {
            // if (props.asset)
            //   siteList.push({
            //     ...site,
            //     url:
            //       url +
            //       `${origin}&media=${props.asset.url}&is_video=${props.asset.isVideo}$description=${props.asset.description}`,
            //   })
          } else {
            siteList.push({
              ...site,
              url: url + link,
            })
          }
        }
      })

    setSites(siteList)
  }, [socialMediaConfigs])

  const emailLink = async e => {
    const firstLink = window.location.origin + shareCartUrl.current
    const lastLink = await handleShareCartCopyLink({ ismail: true })
    const cartLink = sanitizeUrl(
      `mailto:?body=${firstLink + "%3F" + lastLink}${
        i18n.mailSubject && "&subject=" + i18n.mailSubject
      }`
    )

    window.open(cartLink, "_blank")
    e.stopPropagation()
    addDataLayer("email a friend", "n/a")
  }

  const handleEmailActionKeydown = async e => {
    if (e.keyCode === 13) {
      emailLink(e)
    }
    handleShareCart()
  }

  const socialLinks = e => {
    const target = e.target
    const nodeUrl = target.hostname.replace(/(^www\.)/, "")
    if (
      !window.excludUrl ||
      (window.excludUrl && !window.excludUrl.includes(nodeUrl))
    ) {
      e.stopPropagation()
      e.preventDefault()
      if (window.handleShowModal) window.handleShowModal(target.href, target)
    } else if (target.href) {
      window.open(target.href)
    }
    const key = target.siteKey || nodeUrl || target.hostname
    try {
      addDataLayer(key.split(".com")[0], target.href)
      // eslint-disable-next-line no-empty
    } catch (ee) {}
  }

  useEffect(() => {
    copyBtnRef?.current?.addEventListener("click", copyLink, false)
    shareBoxRef?.current?.addEventListener("click", shareBox, false)
    emailBtnRef?.current?.addEventListener("click", emailLink, false)
    emailBtnRef?.current?.addEventListener(
      "keydown",
      handleEmailActionKeydown,
      false
    )
    if (shareBoxRef?.current) {
      Array.from(
        shareBoxRef.current.getElementsByClassName("kf-social-links")
      )?.forEach(link => {
        link?.addEventListener("click", socialLinks, false)
      })
    }
    return () => {
      // garbage collection
      copyBtnRef?.current?.removeEventListener("click", copyLink, false)
      shareBoxRef?.current?.removeEventListener("click", shareBox, false)
      emailBtnRef?.current?.removeEventListener("click", emailLink, false)
      emailBtnRef?.current?.removeEventListener(
        "keydown",
        handleEmailActionKeydown,
        false
      )

      if (shareBoxRef?.current) {
        Array.from(
          shareBoxRef.current.getElementsByClassName("kf-social-links")
        )?.forEach(link => {
          link?.removeEventListener("click", socialLinks, false)
        })
      }
    }
  }, [sites, shareCart.show])

  const analyticData = analyticEventData || {
    internalLinkPosition: "n/a",
    internalLinkType: "n/a",
    internalLinkZoneName: "n/a",
  }

  const copyLink = e => {
    e.stopPropagation()
    e.preventDefault()
    handleShareCartCopyLink({ isCopyLink: true })
    setCopyIcon(true)
    setTimeout(() => {
      setCopyIcon(false)
    }, 3000)
  }

  const shareBox = e => {
    e.stopPropagation()
    e.preventDefault()
  }

  const handleDownload = async () => {
    setDownloading(true)
    await handleZipDownload(specFiles)
    setDownloading(false)
  }

  const addDataLayer = (siteKey, link) => {
    const {
      eventAction = "n/a",
      eventName = "n/a",
      eventType = siteKey === "share cart" ? "navigation" : "informative",
      eventMsg = "n/a",
      eventStatus = "n/a",
      internalLinkPosition = "n/a",
      internalLinkType = "n/a",
      internalLinkZoneName = "n/a",
      internalLinkURL,
      videoId = "",
      videoName = "",
    } = analyticData

    const eventInfo = {
      eventAction,
      eventName,
      eventType,
      eventMsg,
      eventStatus,
      internalLinkName: siteKey,
      internalLinkPosition,
      internalLinkType,
      internalLinkZoneName,
      internalLinkURL: link || internalLinkURL || "n/a",
      clickInternalLinks:
        siteKey === "copy link" ||
        siteKey === "email a friend" ||
        siteKey === "share cart"
          ? "true"
          : "false",
    }

    if (videoId) eventInfo.videoId = videoId
    if (videoName) eventInfo.videoName = videoName

    if (window.dataLayerEventType) {
      eventInfo.internalLinkType = window.dataLayerEventType
    }

    if (window.dataLayerEventZoneName) {
      eventInfo.internalLinkZoneName = window.dataLayerEventZoneName
      if (eventAction === "n/a") {
        eventInfo.eventAction = window.dataLayerEventZoneName + ":share"
        eventInfo.eventName = window.dataLayerEventZoneName + ":share"
      }
    }

    const { adobeDataLayer: { getState } = {} } = window
    const page = (getState && getState("page")) || {}
    if (window.adobeDataLayer?.length) {
      const { page: { cart = {} } = {} } = window.adobeDataLayer[0]
      page.cart = cart
    }

    window.adobeDataLayer.push({
      event: "cmp:click",
      page,
      eventInfo,
    })
  }

  return (
    <>
      <ConditionalWrapper
        condition={enableDownload}
        wrapper={children => (
          <div
            className={`download-specs${
              mobileView ? `--mobile` : ` d-none d-lg-block`
            }`}
          >
            <p>{sanitizeInnerHtml(i18n.shareCartDesc)}</p>
            <div
              className={`download-specs${mobileView ? `--mobile` : ``}__cta`}
            >
              <span
                role="button"
                tabIndex="0"
                onClick={() => handleShareCart()}
                name="socialShareModelClose"
              >
                {i18n.shareCart}
                <img src={ShareIcon?.src} role="presentation" alt="" />
              </span>
              {specFiles?.length > 0 && (
                <span
                  role="button"
                  tabIndex="0"
                  className="gbh-data-layer"
                  data-gbh-data-layer='{"eventAction":"cart:landing page:download specs","eventName":"cart:landing page:download specs","eventType":"navigation","eventMsg":"n/a","eventStatus":"n/a","internalLinkName":"download specs","internalLinkPosition":"cart","internalLinkType":"cart:download","internalLinkZoneName":"cart:landing page:order summary","internalLinkURL":"n/a","clickInternalLinks":"true"}'
                  onClick={handleDownload}
                >
                  {i18n.downloadSpecs}
                  {downloading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    <img src={DownloadIcon?.src} role="presentation" alt="" />
                  )}{" "}
                </span>
              )}
            </div>
            {children}
          </div>
        )}
      >
        {shareCart.show || !enableDownload ? (
          <div className="social-share" id="kf-order-summary">
            <div className="social-share__box" ref={shareBoxRef}>
              <div className="social-share__sites">
                {shareCart.id && !videoComp ? (
                  <div className="social-share__shared-cart-number">
                    {i18n.yourSharedCartNumber}
                  </div>
                ) : (
                  <ul role="list">
                    {sites.map((site, i) => {
                      return (
                        <li key={i} role="listitem">
                          <a
                            id={`kf-social-links-${i}`}
                            rel="noreferrer"
                            href={site.url}
                            className="kf-social-links"
                            aria-label={`${i18n.linksCTA(site.key)} ${
                              i18n.openNewTabText
                            }`}
                            target="_blank"
                            data-link-type="social media"
                          >
                            <img
                              aria-hidden="true"
                              src={`${process.env.NEXT_PUBLIC_AUTHORBASEURL}${site.icon}`}
                              alt={site.key}
                              className={`social-share__${site.key}`}
                            />
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
              <div className="social-share__actions" role="list">
                <div role="listitem">
                  <div
                    tabIndex="0"
                    role="button"
                    ref={copyBtnRef}
                    className="social-share__cta"
                  >
                    <img
                      className="social-share__copy-link-icon"
                      aria-hidden
                      src={`${hostUrlPath.current}${socialShareCopyIconPath.current}`}
                      role="presentation"
                      alt=""
                    />
                    {copyIcon ? i18n.copyLinkIndication : i18n.copyLink}
                  </div>
                </div>
                <div role="listitem">
                  <a
                    tabIndex="0"
                    className="social-share__cta social-share__email"
                    aria-label={`${i18n.email} ${i18n.openNewTabText}`}
                    role="link"
                    ref={emailBtnRef}
                  >
                    <img
                      src={`${hostUrlPath.current}${socialShareEmailIconPath.current}`}
                      role="presentation"
                      alt=""
                    />
                    {i18n.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </ConditionalWrapper>
    </>
  )
}
export default SocialShare
