import {
  PDP_DETAILS,
  PDP_FAVORITE,
  PDP_GUEST_FAVORITE,
} from "@/constants/index"
import {
  SANITIZE_TEXT_REGEX
} from "@/constants/regex"
export const getAnalyticsData = (
  type,
  url,
  needsJSON = false,
  isBundleProduct = false
) => {
  const title = type.toLowerCase().trim()
  let eventAction
  let internalLinkZoneName
  let internalLinkType
  let internalLinkPosition
  let internalLinkURL
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageType = page?.category?.pageType ?? "pdp"

  if (title === "notify me") {
    internalLinkZoneName = `${page?.category?.pageType}:modal:availability notification`
    eventAction = `${internalLinkZoneName}:${title}`
  } else if (title === "local store or showroom") {
    eventAction = `${page?.category?.pageType ?? "pdp"}:${title}`
    internalLinkPosition = pageType
    internalLinkType = `${pageType}:${title}`
    internalLinkZoneName = `${pageType}:product details`
    internalLinkURL = "n/a"
  } else if (title === "where to buy") {
    internalLinkURL = `${window.location.origin + url}`
  }
  const eventInfo = {
    eventAction:
      eventAction || `${page?.category?.pageType}:product details:${title}`,
    eventName:
      eventAction || `${page?.category?.pageType}:product details:${title}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${title}`,
    internalLinkPosition:
      internalLinkPosition ||
      `${page?.category?.pageType}${isBundleProduct ? ":bundle" : ""}`,
    internalLinkType:
      internalLinkType || `${page?.category?.pageType}:${title}`,
    internalLinkZoneName:
      internalLinkZoneName || `${page?.category?.pageType}:product details`,
    internalLinkURL: internalLinkURL || "n/a",
    clickInternalLinks: "true",
  }
  if (needsJSON) {
    return eventInfo
  }
  return JSON.stringify(eventInfo)
}

export const replaceAddToCartDatalayerPush = (
  replaceAddToCartCtaLabel,
  replaceAddToCartCtaLink,
  analyticData
) => {
  const eventInfo = getAnalyticsData(
    replaceAddToCartCtaLabel,
    replaceAddToCartCtaLink,
    true
  )
  const page = window.adobeDataLayer.getState("page")
  analyticData = JSON.parse(analyticData)
  const productInfo = analyticData?.productInfo
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}

export const addToCompareAnalytics = (isChecked, analyticData) => {
  const compareText = `${isChecked ? "remove" : "add to"} compare`
  const eventInfo = {
    eventAction: `pdp:product details:${compareText}`,
    eventName: `pdp:product details:${compareText}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${isChecked ? "remove compare" : "compare add"}`,
    internalLinkPosition: "pdp",
    internalLinkType: `pdp:${compareText}`,
    internalLinkZoneName: "pdp:product details",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }

  const page = window.adobeDataLayer.getState("page")
  analyticData = JSON.parse(analyticData)
  const productInfo = analyticData?.productInfo
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}

export const addDatalayerLocalStoreHrefClick = (type, link, productInfo) => {
  const eventInfo = {
    eventAction: "pdp:local store or showroom",
    eventName: "pdp:local store or showroom",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "local store or showroom",
    internalLinkPosition: "pdp",
    internalLinkType: "pdp:local store or showroom",
    internalLinkZoneName: "pdp:product details",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  const localStoreEvent = window.adobeDataLayer.filter(data => {
    return data.eventInfo?.eventAction === "pdp:local store or showroom"
  })
  if (localStoreEvent && !localStoreEvent.length) {
    pushAnalyticsObjToDataLayer(eventInfo, productInfo)
  }
}

const pushAnalyticsObjToDataLayer = (eventInfo = {}, productInfo = {}) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const event = "cmp:click"
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
    productInfo,
  })
}

export const addDataLayerFavGuestUser = () => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const { productInfo } = page
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${PDP_DETAILS}:${PDP_GUEST_FAVORITE}`,
    eventMsg: "n/a",
    eventName: `${PDP_DETAILS}:${PDP_GUEST_FAVORITE}`,
    eventStatus: "n/a",
    eventType: PDP_FAVORITE,
    internalLinkName: PDP_FAVORITE,
    internalLinkPosition: page?.category?.pageType?.toLowerCase(),
    internalLinkType: `${page?.category?.pageType?.toLowerCase()}:${PDP_FAVORITE}`,
    internalLinkURL: window.location.href,
    internalLinkZoneName: `${PDP_DETAILS}`,
  }
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}

export const productSuggestionLinkAnalytics = (eventName, url, analyticData) => {
  const pageType = window.eventPageType
  const eventInfo = {
    eventAction: `${pageType}:product details:${eventName}`,
    eventName: `${pageType}:product details:${eventName}`,
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: `${eventName}`,
    internalLinkPosition: `${pageType}`,
    internalLinkType: `${pageType}:navigation`,
    internalLinkZoneName: `${pageType}:product details:${eventName}`,
    internalLinkURL: `${url}`,
    clickInternalLinks: "true",
  }
  const page = window.adobeDataLayer.getState("page")
  analyticData = JSON.parse(analyticData)
  const productInfo = analyticData?.productInfo
  window.adobeDataLayer.push({
    event: "cmp:click",
    eventInfo,
    productInfo,
    page,
  })
}

const sanitizeTextForAnalytics = (text = "") => {
  const testData = text ?? ""
  return String(testData).replace(SANITIZE_TEXT_REGEX, "").toLowerCase().trim()
}

export const getProductInfoAnalytics = (data) => {
  const { getState = () => {} } = window.adobeDataLayer
  const page = getState().page

  const customerFacingSKUId = data.customerFacingSKU || data.sku

  return {
    productBrand: page?.site?.siteName,
    description: sanitizeTextForAnalytics(data.desc) || "n/a",
    frequency: "n/a",
    globalPromotionPrice: Number(data.discountPrice)
      ? Number(data.discountPrice)
      : Number(data.unitPrice),
    isRecommended: "n/a",
    isSendNow: "n/a",
    isSubscription: "n/a",
    priceState:
      data.discountPercent > 0 || data.discountAmount > 0
        ? "percentOff|" + data.discountPercent
        : "regular price",
    productBasePrice: Number(data.unitPrice) || "n/a",
    productCategory: sanitizeTextForAnalytics(data.category) || "n/a",
    productColor: sanitizeTextForAnalytics(data.color) || "n/a",
    productCoupondiscount: "n/a",
    productID: customerFacingSKUId || "n/a",
    productName: sanitizeTextForAnalytics(data.name) || "n/a",
    productPartnerBuyNow: false,
    productRoom: sanitizeTextForAnalytics(data.category) || "n/a",
    productSalePrice: Number(data.discountPrice)
      ? Number(data.discountPrice)
      : Number(data.unitPrice),
    productSaleable:
      page?.productInfo?.pdpType === "bundle" || data?.isBundleParent
        ? "true"
        : Number(data.productSaleable) ?? "true",
    productStatus: data.availableQty >= 0 ? "in stock" : "out of stock",
    productSuperSku: data.superSku ? true : false,
    productTileName: sanitizeTextForAnalytics(data.name) || "n/a",
    quantity: data.quantity,
    initialQuantity: data.quantity,
    defaultImageName: data.image || "n/a",
    ratings: "n/a",
    numberOfReviews: "n/a",
    pdpType:data.pdpType,
    productFindingMethod: "n/a",
    productCollectionsName:
      sanitizeTextForAnalytics(data.productCollectionsName || data.category) ||
      "n/a",
  }
}

export const pushAnalyticsToDataLayer = (eventInfo = {}, productInfo = {}) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const event = "cmp:click"
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
    productInfo,
  })
}