import React, { useEffect, useState } from "react"
import _get from "lodash/get"

import { getConfig } from "@/constants/config"
import Button from "@/components/core/Button/Button"
import useFindAProByZipi18n from "@/i18n/useFindAProByZipi18n"
import ZipCodeInfoModal from "@/components/core/Modal/ZipCodeInfoModal"
import findAProByZipStyle from "@/components/FindAProByZip/v1/index.module.scss"
import {
  getShortenedUrl,
  validateField,
  getMyLocation,
  sanitizeInnerHtml,
  jsonStringEscape,
} from "@/utils/helper"
import {
  addUseMyLocationAnalytics,
  getSubmitBtnAnalyticsData,
} from "@/components/FindAProByZip/v1/analytics"

const FindAProByZip = props => {
  const { data: componentProp = {} } = props

  const [zipcode, setZipcode] = useState("")
  const [zipCodeError, setZipCodeError] = useState("")
  const [infoModalError, setInfoModalError] = useState({})
  const [infoModal, setInfoModal] = useState(false)
  const [info, setInfo] = useState({})
  const [disableButton, setDisableButton] = useState(true)
  const staticTexts = useFindAProByZipi18n(
    JSON.parse(jsonStringEscape(JSON.stringify(componentProp)))
  )

  const {
    validZipcode = "",
    zipcodeInfo = "",
    zipcodeDesc = "",
    zipcodeLabel = "",
    submitCTA = "",
    useMyLocation = "",
  } = staticTexts

  const handleChange = e => {
    const { target: { value = "" } = {} } = e ?? {}
    const inputValue = value?.trim()
    validateField("zipCodeNumeric", inputValue).then(isValid => {
      if (isValid || inputValue === "") {
        setZipcode(inputValue)
      }
    })
  }

  const zipCodeCheck = (zipVal = "") => {
    const zipcodeValue = zipVal || zipcode
    validateField("zipCodeNumeric", zipcodeValue).then(isValid => {
      if ((zipVal?.length || zipcode?.length) && !isValid) {
        setZipCodeError(validZipcode)
      } else {
        setZipCodeError("")
      }
    })
  }

  const getGeoLocation = () => {
    getMyLocation()
      .then(getLocation => {
        const { zipcode = "" } = getLocation ?? {}
        setZipcode(zipcode)

        zipCodeCheck(zipcode)
        if (zipcode) {
          addUseMyLocationAnalytics("success", "", zipcode)
        } else {
          addUseMyLocationAnalytics("failure", "failed to load zipcode")
        }
      })
      .catch(({ cause }) => {
        setInfoModalError(cause)
        setInfoModal(true)
        addUseMyLocationAnalytics("failure", cause?.message ?? "")
      })
  }

  useEffect(() => {
    getConfig().then(async config => {
      const locale = _get(config, "internationalization.locale", "")
      const currencySign = _get(config, "internationalization.currencySign", "")
      const currencyCode = _get(config, "internationalization.currencyCode", "")

      const findAProPageUrl = await getShortenedUrl(
        _get(config, "general.findProPagePath", "")
      )

      const info = {
        locale,
        currencySign,
        currencyCode,
        findAProPageUrl,
      }
      setInfo(info)
    })
  }, [])

  useEffect(() => {
    validateField("zipCodeNumeric", zipcode).then(isValid =>
      setDisableButton(!isValid)
    )
  }, [zipcode])

  return (
    <div className={`${findAProByZipStyle?.findAProByZipWrapper}`}>
      <div className="zipcode-page">
        <ZipCodeInfoModal
          handleModalClose={() => {
            setInfoModal(false)
          }}
          show={infoModal}
          data={infoModalError}
          pro={true}
          labelledBy="Geo-Location-pro-error-message"
          className="message-modal"
          staticTexts={staticTexts}
          dataLayerPosition="find a pro"
        />
        <div className="zipcode-page__main-container">
          <div className="zipcode-page__inner-container">
            <div className="zipcode-page__zipcode-left">
              <div className="zipcode-info">
                {sanitizeInnerHtml(zipcodeInfo)}
              </div>
            </div>

            <div className="zipcode-page__zipcode-right">
              <div className="zipcode-description">
                {sanitizeInnerHtml(zipcodeDesc)}
              </div>
              <div className="zipcode-text-box">
                <input
                  type="text"
                  className="zipcodeInput"
                  aria-label={staticTexts.ariaLabel?.zipcodeLabel}
                  value={zipcode}
                  onKeyUp={() => zipCodeCheck()}
                  onFocus={() => zipCodeCheck()}
                  placeholder={zipcodeLabel}
                  onChange={e => handleChange(e)}
                />
                <Button
                  label={submitCTA}
                  className="gbh-data-layer"
                  data-gbh-data-layer={getSubmitBtnAnalyticsData(
                    zipcode,
                    info?.findAProPageUrl
                  )}
                  tag="anchor"
                  type="primary"
                  customClass="submitCTA"
                  to={`${info?.findAProPageUrl}?q=${zipcode}`}
                  disabled={disableButton}
                  useDefaultClass={true}
                />
              </div>
              <div className="location-link-container">
                {zipCodeError && (
                  <div className="findstore-util-nav__zipcode-error">
                    {zipCodeError}
                  </div>
                )}
                <span
                  className="location-link"
                  tabIndex="0"
                  role="link"
                  onClick={() => getGeoLocation()}
                >
                  {useMyLocation}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default FindAProByZip
