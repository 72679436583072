import faucetmainImage from "@/public/images/parts/faucets/faucets_main.png"
import faucetWrong1 from "@/public/images/parts/faucets/wrong1.png"
import faucetWrong2 from "@/public/images/parts/faucets/wrong2.png"
import faucetWrong3 from "@/public/images/parts/faucets/wrong3.png"
import faucetWrong1Mob from "@/public/images/parts/faucets/wrong1_mob.png"
import faucetWrong2Mob from "@/public/images/parts/faucets/wrong2_mob.png"
import faucetWrong3Mob from "@/public/images/parts/faucets/wrong3_mob.png"
import faucetmainImageTab from "@/public/images/parts/faucets/faucets_image_tab.png"
import faucetmainImageMobile from "@/public/images/parts/faucets/faucet_mask_group.png"
import toiletmainImage from "@/public/images/parts/toilet/toilet_main.png"
import toiletWrong1 from "@/public/images/parts/toilet/toilet_wrong1.png"
import toiletWrong2 from "@/public/images/parts/toilet/toilet_wrong2.png"
import toiletWrong3 from "@/public/images/parts/toilet/toilet_wrong3.png"
import toiletWrongMob1 from "@/public/images/parts/toilet/toilet-wrong-mob1.png"
import toiletWrongMob2 from "@/public/images/parts/toilet/toilet-wrong-mob2.png"
import toiletmainImageTab from "@/public/images/parts/toilet/toilet_Ideal_Tab.png"
import valvemainImage from "@/public/images/parts/valve/valve_main.png"
import valveWrong1 from "@/public/images/parts/valve/valve_wrong1.png"
import valveWrong2 from "@/public/images/parts/valve/valve_wrong2.png"
import valveWrong3 from "@/public/images/parts/valve/valve_wrong3.png"
import valvemainImageTab from "@/public/images/parts/valve/valve_Ideal_Tab.png"
import showermainImage from "@/public/images/parts/shower/shower_main.png"
import showerWrong1 from "@/public/images/parts/shower/shower_wrong1.png"
import showerWrong2 from "@/public/images/parts/shower/shower_wrong2.png"
import showerWrong3 from "@/public/images/parts/shower/shower_wrong3.png"

export const imageList = {
  faucetmainImage,
  faucetWrong1,
  faucetWrong2,
  faucetWrong3,
  faucetWrong1Mob,
  faucetWrong2Mob,
  faucetWrong3Mob,
  faucetmainImageTab,
  faucetmainImageMobile,
  toiletmainImage,
  toiletWrong1,
  toiletWrong2,
  toiletWrong3,
  toiletWrongMob1,
  toiletWrongMob2,
  toiletmainImageTab,
  valvemainImage,
  valveWrong1,
  valveWrong2,
  valveWrong3,
  valvemainImageTab,
  showermainImage,
  showerWrong1,
  showerWrong2,
  showerWrong3,
}

export const IMAGE_FILE_TYPE =
  "image/png, image/jpeg, image/jfif, image/bmp, image/heic, image/heif"
