import React, { useEffect, useState } from "react"
import { useRouter } from "next/router"
import Head from "next/head"
import { useDispatch, useSelector } from "react-redux"
import AemGrid from "@/components/AemGrid"

import { selectCartState } from "@/store/features/cartSlice"
import Script from "next/script"
import { getCartCount, getAriaLabel } from "@/utils/cart"
import { useTranslation } from "next-i18next"

import {
  getPageLevelScriptsAndStyles,
  getRandom,
  hasComponentType,
} from "@/utils/helper"
import {
  selectGenericState,
  setBrandLogoImg,
  closeMenu,
  openMenu,
  setActiveMainCategory,
  setRegionNav,
  setGlobalStyles,
  setGlobalScripts,
} from "@/store/features/genericSlice"
import { COMPONENT_TYPES, FRAGMENT_TYPES } from "@/constants"

const GlobalPageHeader = props => {
  const {
    header = {},
    title = "",
    description = "",
    globalScripting = "",
    isPdp = false,
    imageUrl = "",
    email = "",
    phone = "",
    fullUrl = "",
    siteName = "",
  } = props
  const dispatch = useDispatch()
  const router = useRouter()

  const { cartUrl } = useSelector(selectCartState)

  const cartCount = getCartCount()
  const {
    brandLogoImg,
    menu: { isOpen, mainCategoryActive, isRegionOpen },
    pageIsInteractive,
  } = useSelector(selectGenericState)
  const toggleMenu = () => {
    isOpen ? dispatch(closeMenu()) : dispatch(openMenu())
  }
  const { t } = useTranslation("common")
  const ariaLabel = getAriaLabel(t, cartCount)
  const [metas, setMetas] = useState([])
  const [styles, setStyles] = useState([])
  const [scripts, setScripts] = useState([])
  const [links, setLinks] = useState([])
  const [hasGlobalNavigation, setHasGlobalNavigation] = useState(false)

  const pageUrl =
    fullUrl || (typeof window !== "undefined" ? window?.location?.href : "")

  useEffect(() => {
    const { pageLevelDetails = [] } =
      getPageLevelScriptsAndStyles(globalScripting)
    setMetas(pageLevelDetails["metaList"])
    setStyles(pageLevelDetails["stylesList"])
    setScripts(pageLevelDetails["pageScripts"])
    setLinks(pageLevelDetails["linkList"])
  }, [globalScripting])

  const redirect = () => {
    router.push(`${cartUrl}`)
  }

  useEffect(() => {
    hasComponentType(header, COMPONENT_TYPES.GLOBAL_NAVIGATION, () =>
      setHasGlobalNavigation(true)
    )
  }, [])

  const eventHandler = {
    store: {
      brandLogoImg: brandLogoImg,
      isOpen,
      mainCategoryActive,
      isRegionOpen,
      cartCount,
      ariaLabel,
      cartUrl,
    },
    callbacks: {
      setBrandLogoImg: path => {
        dispatch(setBrandLogoImg(path))
      },
      closeMenu: () => {
        dispatch(closeMenu())
      },
      setMainCategory: payload => {
        dispatch(setActiveMainCategory(payload))
      },
      toggleMenu: () => {
        toggleMenu()
      },
      setRegionNav: active => {
        dispatch(setRegionNav(active))
      },
      setGlobalScripts: item => {
        dispatch(setGlobalScripts(item))
      },
      setGlobalStyles: item => {
        dispatch(setGlobalStyles(item))
      },
      onClick: () => {
        redirect()
      },
    },
  }

  return (
    <>
      <Head>
        {title ? (
          <>
            <title>{title}</title>
            <meta property="og:title" content={title} />
          </>
        ) : null}
        {description ? (
          <>
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
          </>
        ) : null}
        <meta property="og:type" content={isPdp ? "product" : "website"} />
        {siteName ? <meta property="og:site_name" content={siteName} /> : null}
        {pageUrl ? <meta property="og:url" content={pageUrl} /> : null}
        {imageUrl ? <meta property="og:image" content={imageUrl} /> : null}
        {email ? <meta property="og:email" content={email} /> : null}
        {phone ? <meta property="og:phone_number" content={phone} /> : null}

        {metas.map((meta, i) => {
          if (meta.name && meta.content)
            return (
              <meta key={`meta-${i}`} name={meta.name} content={meta.content} />
            )
        })}
        {styles.map((style, i) => {
          if (typeof style === "string")
            return (
              <React.Fragment key={`pagestyle-${i}-${getRandom()}`}>
                <style jsx global>
                  {style}
                </style>
              </React.Fragment>
            )
        })}
        {links.map((link, i) => {
          if (typeof link !== "string")
            return (
              <link key={`pageLinks-${i}`} rel={link.rel} href={link.href} />
            )
        })}
      </Head>
      {pageIsInteractive &&
        scripts.map((script, i) => {
          if (script.src)
            return (
              <Script
                key={`headscript-${i}`}
                src={script.src}
                type={script.type}
                integrity={script.integrity}
                id={`headscript-${i}`}
              />
            )
          if (!script.src && script.innerHTML)
            return (
              <Script key={`headscriptHtml-${i}`} id={`headscriptHtml-${i}`}>
                {`${script.innerHTML}`}
              </Script>
            )
        })}

      <AemGrid
        data={header}
        count={0}
        eventHandler={eventHandler}
        fragment={FRAGMENT_TYPES.HEADER}
        hasGlobalNavigation={hasGlobalNavigation}
      />
    </>
  )
}
export default GlobalPageHeader
