import { PURE_DATA_FOR_STRING_REGEX } from "@/constants/regex"
import {
  addMavenoidChat,
  getPureText,
  pushAnalyticsObjToDataLayer,
  removeAndCleanLink,
} from "@/utils/helper"
import {
  GLOBAL_HEADER_TEXT,
  GLOBAL_NAVIGATION,
  MAIN_MENU,
  MEGA_MENU,
} from "@/components/Shared/HeaderNavTabs/analytics/constant"
import { makingTextDatalayerReady } from "@/components/Shared/HeaderNavTabs/analytics/utils"

export const addAnalyticsOnClose = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "main dropdown menu:products:close",
    eventMsg: "n/a",
    eventName: "main dropdown menu:products:close",
    eventStatus: "n/a",
    eventType: "navigation",
    internalLinkName: "products > close",
    internalLinkPosition: "global header:products",
    internalLinkType: "global header:navigation",
    internalLinkURL: `${window.origin}${location.pathname}`,
    internalLinkZoneName: "main dropdown menu:products",
  }
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
  })
}

export const servicesAnalyticsClick = data => {
  const { text = "", link = "", Leveltwo = "", L1 = "", imgcheck = "" } = data
  const textcont = text ? makingTextDatalayerReady(text) : ""

  const l2DatalayerReady = Leveltwo ? makingTextDatalayerReady(Leveltwo) : ""

  const l1DatalayerReady = L1 ? makingTextDatalayerReady(L1) : ""
  const url = link
    ? `${
        link.indexOf("http") === -1 ? window.location.origin : ""
      }${removeAndCleanLink(link)}`
    : "n/a"
  let internallink
  if (L1) {
    internallink = `${l1DatalayerReady} > ${l2DatalayerReady} > ${
      imgcheck ? `img_${textcont}` : textcont
    }`
  } else if (imgcheck) {
    internallink = `${l2DatalayerReady} > img_${textcont}`
  } else {
    internallink = `${l2DatalayerReady} > ${textcont}`
  }

  const eventInfo = {
    clickInternalLinks:
      url?.startsWith("/") ||
      url?.includes(window.location.hostname) ||
      url === null
        ? "true"
        : "false",
    eventAction: MAIN_MENU,
    eventName: MAIN_MENU,
    eventType: GLOBAL_HEADER_TEXT,
    internalLinkName: getPureText(internallink)?.replace(
      PURE_DATA_FOR_STRING_REGEX,
      ""
    ),
    internalLinkPosition: GLOBAL_HEADER_TEXT,
    internalLinkType: `${GLOBAL_HEADER_TEXT}:navigation`,
    internalLinkURL: url,
    internalLinkZoneName: GLOBAL_HEADER_TEXT,
  }
  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
}

export const l1DatalayerclickMobile = (L1Name = "") => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageNameDatalayer = page?.pageInfo?.pageName
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: MAIN_MENU,
    eventName: MAIN_MENU,
    eventType: GLOBAL_NAVIGATION,
    internalLinkName: L1Name?.toLowerCase(),
    internalLinkPosition: GLOBAL_HEADER_TEXT,
    internalLinkType: `${GLOBAL_HEADER_TEXT}:${MEGA_MENU}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${pageNameDatalayer}:${GLOBAL_HEADER_TEXT}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
}
export const backClickForMobile = (L1Name = "") => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${GLOBAL_HEADER_TEXT}:back`,
    eventName: `${GLOBAL_HEADER_TEXT}:back`,
    eventType: "navigation",
    internalLinkName: `${L1Name.toLowerCase()} > back`,
    internalLinkPosition: `${GLOBAL_HEADER_TEXT}:${L1Name.toLowerCase()}`,
    internalLinkType: `${GLOBAL_HEADER_TEXT}:${MEGA_MENU}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${MAIN_MENU}:${L1Name.toLowerCase()}`,
  }
  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
}
export const closeButtonDatalayer = (data = "products") => {
  addMavenoidChat()
  data = data ? data : "products"
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: `${MAIN_MENU}:${data.toLowerCase()}:close`,
    eventName: `${MAIN_MENU}:${data.toLowerCase()}:close`,
    eventType: "navigation",
    internalLinkName: `${data.toLowerCase()} > close`,
    internalLinkPosition: `${GLOBAL_HEADER_TEXT}:products`,
    internalLinkType: `${GLOBAL_HEADER_TEXT}:${MEGA_MENU}`,
    internalLinkURL: "n/a",
    internalLinkZoneName: `${MAIN_MENU}:products`,
  }
  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
}
export const hamburgerMenuDatalayer = () => {
  const eventInfo = {
    clickInternalLinks: "true",
    eventAction: "hamburger menu open",
    eventName: "hamburger menu open",
    eventType: GLOBAL_NAVIGATION,
    internalLinkName: "hamburger menu open",
    internalLinkPosition: GLOBAL_HEADER_TEXT,
    internalLinkType: `${GLOBAL_HEADER_TEXT}:open`,
    internalLinkURL: "n/a",
    internalLinkZoneName: GLOBAL_HEADER_TEXT,
  }
  pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
}
export const newdatalayerforSignIn = (themeChange = false) => {
  if (themeChange) {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${GLOBAL_HEADER_TEXT}:sign in start`,
      eventName: `${GLOBAL_HEADER_TEXT}:sign in start`,
      eventType: "navigation",
      internalLinkName: "sign in start",
      internalLinkPosition: `${GLOBAL_HEADER_TEXT}:sign in start`,
      internalLinkType: `${GLOBAL_HEADER_TEXT}:${MEGA_MENU}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: GLOBAL_HEADER_TEXT,
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  } else {
    const eventInfo = {
      eventName: "my account:sign in",
      eventAction: "my account:sign in",
      internalLinkName: "sign in interaction",
      internalLinkType: `${GLOBAL_HEADER_TEXT}:navigation`,
      internalLinkZoneName: GLOBAL_HEADER_TEXT,
      clickInternalLinks: "true",
      eventType: GLOBAL_HEADER_TEXT,
      internalLinkURL: "n/a",
      internalLinkPosition: `${GLOBAL_HEADER_TEXT} sign in`,
      eventMsg: "n/a",
      eventStatus: "n/a",
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  }
}
export const newdataLayerForCreateAccount = (themeChange = false) => {
  if (themeChange) {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${GLOBAL_HEADER_TEXT}:create account`,
      eventName: `${GLOBAL_HEADER_TEXT}:create account`,
      eventType: "navigation",
      internalLinkName: "create account",
      internalLinkPosition: `${GLOBAL_HEADER_TEXT}:create account`,
      internalLinkType: `${GLOBAL_HEADER_TEXT}:${MEGA_MENU}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: GLOBAL_HEADER_TEXT,
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  } else {
    const eventInfo = {
      eventName: "my account:create account",
      eventAction: "my account:create account",
      internalLinkName: "create account",
      internalLinkType: `${GLOBAL_HEADER_TEXT}:navigation`,
      internalLinkZoneName: GLOBAL_HEADER_TEXT,
      clickInternalLinks: "true",
      eventType: GLOBAL_HEADER_TEXT,
      internalLinkURL: "n/a",
      internalLinkPosition: `${GLOBAL_HEADER_TEXT} create account`,
      eventMsg: "n/a",
      eventStatus: "n/a",
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  }
}
export const onclickForSignedFun = (themeChange = false) => {
  if (themeChange) {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${GLOBAL_HEADER_TEXT}:your kohler account`,
      eventName: `${GLOBAL_HEADER_TEXT}:your kohler account`,
      eventType: "navigation",
      internalLinkName: "your kohler account",
      internalLinkPosition: `${GLOBAL_HEADER_TEXT}:my account`,
      internalLinkType: `${GLOBAL_HEADER_TEXT}:${MEGA_MENU}`,
      internalLinkURL: "n/a",
      internalLinkZoneName: GLOBAL_HEADER_TEXT,
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  }
}
export const dataLayerForUtilityClicks = (
  title = "",
  themeChange = false,
  url = ""
) => {
  const dataLayerTitle = title?.toLowerCase()
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const pageNameDatalayer = page?.pageInfo?.pageName
  if (themeChange) {
    const eventInfo = {
      clickInternalLinks: "true",
      eventAction: `${GLOBAL_HEADER_TEXT}:${dataLayerTitle}`,
      eventName: `${GLOBAL_HEADER_TEXT}:${dataLayerTitle}`,
      eventType: "navigation",
      internalLinkName: `${dataLayerTitle}`,
      internalLinkPosition: `${GLOBAL_HEADER_TEXT}:${dataLayerTitle}`,
      internalLinkType: `${GLOBAL_HEADER_TEXT}:${MEGA_MENU}`,
      internalLinkURL: url
        ? `${
            url.indexOf("http") === -1 ? window.location.origin : ""
          }${removeAndCleanLink(url)}`
        : "n/a",
      internalLinkZoneName: GLOBAL_HEADER_TEXT,
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  } else if (dataLayerTitle === "find a store") {
    const eventInfo = {
      eventAction: dataLayerTitle,
      eventName: dataLayerTitle,
      eventType: dataLayerTitle,
      internalLinkName: dataLayerTitle,
      internalLinkPosition: dataLayerTitle,
      internalLinkType: `${GLOBAL_HEADER_TEXT}:navigation`,
      internalLinkZoneName: `${pageNameDatalayer}${
        pageNameDatalayer ? ":" : ""
      }${GLOBAL_HEADER_TEXT}`,
      internalLinkURL: "n/a",
      clickInternalLinks: "true",
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  } else {
    dataLayerTitle === "favorites"
    const eventInfo = {
      eventName: "wishlist icon",
      eventAction: "wishlist icon",
      internalLinkName: "wishlist",
      internalLinkType: `${GLOBAL_HEADER_TEXT}:wishlist`,
      internalLinkZoneName: GLOBAL_HEADER_TEXT,
      clickInternalLinks: "true",
      eventType: GLOBAL_HEADER_TEXT,
      internalLinkURL: `${url}`,
      internalLinkPosition: `${GLOBAL_HEADER_TEXT} wishlist`,
      eventMsg: "n/a",
      eventStatus: "n/a",
    }
    pushAnalyticsObjToDataLayer(eventInfo, "cmp:click")
  }
}
