import React, { useState } from "react"
import { useTranslation } from "next-i18next"
import Camera from "@/components/VisualSearch/v1/CameraView/Camera.js"
import { validateImageFileAsync } from "@/components/VisualSearch/v1/utils"
import Style from "@/components/VisualSearch/v1/CameraView/cameraview.module.scss"
import GuildePageMobile from "@/components/VisualSearch/v1/CameraView/GuildePageMobile"
import { getTranslations } from "@/components/VisualSearch/localization"
import { getGuide } from "@/components/VisualSearch/v1/CameraView/utils"
import {
  Close,
  CaptureIcon,
  UploadIcon,
  InfoIcon,
} from "@/components/VisualSearch/v1/CameraView/icons"

const CameraView = props => {
  const {
    setImage = () => {},
    back = () => {},
    category = "",
    setOpenCam = () => {},
    setSection = () => {},
  } = props

  const [captureTrigger, setCaptureTrigger] = useState(false)
  const [showInfo, setShowInfo] = useState(false)
  const { t } = useTranslation()
  const data = getTranslations(t)
  const { image, text } = getGuide(category, data)

  const processImage = input => {
    validateImageFileAsync()
    setImage(input)
  }

  return showInfo ? (
    <GuildePageMobile
      category={category}
      setShowInfo={setShowInfo}
      setSection={setSection}
    />
  ) : (
    <div className={Style.CameraView}>
      <button
        className={"back-button"}
        onClick={() => {
          back()
          setOpenCam(false)
        }}
      >
        <Close />
      </button>
      <Camera
        handlerResult={processImage}
        captureTrigger={captureTrigger}
        setCaptureTrigger={setCaptureTrigger}
      />
      {image}
      <div className="guide-text-wrapper">
        <p className="guide-text">{text}</p>
      </div>
      <div className="button-group">
        <button
          onClick={() => {
            document.querySelector("#upload-input").click()
          }}
        >
          <input
            id="upload-input"
            className={"upload-input"}
            type="file"
            onChange={() => setCaptureTrigger(true)}
            hidden="true"
          />
          <UploadIcon />
        </button>
        <button onClick={() => setCaptureTrigger(true)}>
          <CaptureIcon />
        </button>
        <button onClick={() => setShowInfo(true)}>
          <InfoIcon />
        </button>
      </div>
    </div>
  )
}

export default CameraView
