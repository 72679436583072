import { DOMAIN } from "@/constants"
export const ACI_PAYMENT_CHECKOUT_ENDPOINT = "/payment/checkout"
export const ACI_PAYMENT_CALLBACK_ENDPOINT = "/payment/checkout/callback"
export const FRAUD_DECLINED_MESSAGE_ENDPOINT = "/en/declined"
export const CHANGE_PASSWORD_MODEL_DATA_URL =
  "/en/my-account/change-password.model.json"
export const CHANGE_PASSWORD_ENDPOINT = "/customer/change_password"
export const CATALOG_NOTIFY_ENDPOINT = "catalog/product/oos/notify"
export const CATALOG_PROJECTION_ENDPOINT = "/catalog/product/projection"
export const FEATURE_FLAGS_API_ENDPOINT = DOMAIN + "/api/featureflags"
export const SVG_METADATA_ENDPOINT = "/catalog/svg/metadata"
export const ML_END_POINT = "https://api-kohler-int-test.azure-api.net"
export const ADOBE_TARGET_PRODUCT_RECOMMENDETION_ENDPOINT =
  "/catalog/product/recommendation"
export const PRODUCT_BEST_SELLERS = "/products/best-sellers"
export const USER_LIST_DELETE_END_POINT = "/shopping-list"
export const MY_LISTS_PAGE_PATH = "/my-account/my-lists"
export const FAVORITES_PAGE_PATH = "/my-account/my-favorites"
export const SEARCH_FULL_CATALOG = "/search/full-catalog"
