import { useTranslation } from "next-i18next"
import { getTranslations } from "@/components/ChangePassword/localization"

const PasswordRequirements = props => {
  const { passwordRegExp: { passwordMessage = [] } = {} } = props

  const { t } = useTranslation("common")
  const staticText = getTranslations(t)

  const {
    passwordRequirementsText = "",
    pwMustIncludeText = "",
    characterCountText = "",
    uppercaseLetterText = "",
    lowercaseLetterText = "",
    symbolValidationText = "",
    numberValidationText = "",
  } = staticText

  const i18n = {
    showDialog: true,
  }

  if (passwordRequirementsText === pwMustIncludeText) {
    i18n.characterCount = characterCountText
    i18n.uppercaseLetter = uppercaseLetterText
    i18n.lowercaseLetter = lowercaseLetterText
    i18n.symbolValidation = symbolValidationText
    i18n.numberValidation = numberValidationText
  }

  return (
    <div className="password-tooltip">
      {i18n.showDialog ? (
        <>
          <p>{pwMustIncludeText}</p>
          <ul>
            {passwordMessage.length ? (
              passwordMessage.map((message, i) => <li key={i}>{message}</li>)
            ) : (
              <div>
                <li>{i18n.characterCount}</li>
                <li>{i18n.uppercaseLetter}</li>
                <li>{i18n.lowercaseLetter}</li>
                <li>{i18n.symbolValidation}</li>
                <li>{i18n.numberValidation}</li>
              </div>
            )}
          </ul>
        </>
      ) : (
        passwordRequirementsText
      )}
    </div>
  )
}

export default PasswordRequirements
