import React from "react"
import ProductCard from "@/components/productCard/v3/ProductCard"
import SmartCropImage from "@/components/core/SmartCropImage"
import useSmartCrop from "@/hooks/useSmartCrop"
import styles from "@/components/RoomDetailPage/v1/index.module.scss"
import htmlParser from "html-react-parser"

const RoomDetailPage = ({ data: authorData }) => {
  const {
    skuIds = [],
    imageRight = "false",
    landingImageLink = "",
    presetConfigs = "{}",
    title = "",
    description = "",
    enableFavorite = false,
    imageAltText = "",
  } = authorData
  const smartCrop = useSmartCrop()
  const noOfProducts = skuIds.length

  return (
    <div className={styles.roomDetailWrapper}>
      <div className="room-detail-parent-container">
        <div
          className={`room-detail-product-detail${
            imageRight === "true" ? "__align-right" : ""
          }`}
        >
          <div
            className={`title-image-container
             `}
          >
            <div className="room-details">
              <p className="product-count">{noOfProducts} Products</p>
              <div class="product-info">
                <p class="title">{title ? htmlParser(title) : null}</p>
                <p class="description">
                  {description ? htmlParser(description) : null}
                </p>
              </div>
            </div>
            <div className="image-container">
              <SmartCropImage
                url={landingImageLink}
                altText={imageAltText}
                smartCrop={smartCrop}
              />
            </div>
          </div>
          <div className={`products-container`}>
            {skuIds.length > 0
              ? skuIds.map((skuId, index) => (
                  <ProductCard
                    isFavoriteIcon={enableFavorite}
                    key={`product-tile-${skuId}-${index}`}
                    authorData={{ skewId: skuId, presetConfigs }}
                    isDefaultProductCard={false}
                  />
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RoomDetailPage
