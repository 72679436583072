import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import _get from "lodash/get"
import _isEmpty from "lodash/isEmpty"

import {
  hideAppointmentModal,
  selectGenericState,
  showAppointmentModal,
  showToast,
} from "@/store/features/genericSlice"
import { getConfig } from "@/constants/config"
import { aemAxiosInstance, apim } from "@/constants/api"
import {
  GLOBAL_CONFIGURATION_ENDPOINT,
  STORE_APPOINTMENT_ANCHOR_VALUE,
} from "@/constants"
import useStoreAppointmenti18n from "@/i18n/useStoreAppointmenti18n"
import useIsSsr from "@/hooks/useIsSsr"

import StoreAppointmentView from "@/components/StoreAppointment/v1/StoreAppointmentView"
import { addBookAppointmentAnalytics } from "@/components/StoreAppointment/v1/analytics"
import {
  getInternalLinkTypeForFindStore,
  sanitizeTextForAnalytics,
} from "@/utils/helper"

const StoreAppointment = props => {
  const { pageIsInteractive } = useSelector(selectGenericState)
  const { data: authorData = {} } = props
  const staticTexts = useStoreAppointmenti18n()
  const [storeDetails, setStoreDetails] = useState({})
  const [bpNumberState, setBpNumberState] = useState("")
  const [storeData, setStoreData] = useState({})
  const [info, setInfo] = useState({})
  const [appointmentDate, setAppointmentDate] = useState("")
  const [successData, setSuccessData] = useState({})
  const [isSuccess, setIsSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [getUrlData, setGetUrlData] = useState({})
  const dispatch = useDispatch()
  const isSsr = useIsSsr()

  useEffect(() => {
    if (!isSsr && pageIsInteractive) {
      document.addEventListener("click", getNodeList)
    }

    return () => {
      document.removeEventListener("click", getNodeList)
    }
  }, [isSsr, pageIsInteractive])

  const getNodeList = event => {
    const anchor = event.target.closest("a")
    if (!anchor) return
    const anchorValue = anchor.getAttribute("href")
    if (anchorValue === STORE_APPOINTMENT_ANCHOR_VALUE) {
      event.preventDefault()
      dispatch(showAppointmentModal(null))
    }
  }

  useEffect(() => {
    getConfig().then(async config => {
      const getStoreURL = _get(config, "apiEndpoints.storeBpNumber", "")
      const getStoreAppointmentUrl = _get(
        config,
        "apiEndpoints.storeAppointment",
        ""
      )
      const findStoreDetailsUrl = _get(
        config,
        "general.storeDetailsServletPath",
        ""
      )
      const shortendPagePath = _get(config, "general.shortendPagePath", "")
      const legalPathLinks = _get(config, "legalPageData.map", {})

      const general = {
        getStoreURL: getStoreURL,
        getStoreAppointmentUrl: getStoreAppointmentUrl,
        legalPathLinks: legalPathLinks,
      }
      setInfo(general)

      aemAxiosInstance({
        url: GLOBAL_CONFIGURATION_ENDPOINT,
        params: {
          path: findStoreDetailsUrl,
        },
      })
        .then(response => {
          if (response && response.data) {
            setStoreDetails(response.data)
            const storeDetailsValue = response.data

            const shortendPageUrl =
              shortendPagePath + window.location.pathname + ".html"
            let bpNumberValue = null
            Object.keys(storeDetailsValue).forEach(store => {
              if (
                storeDetailsValue[store] === window.location.pathname ||
                storeDetailsValue[store] === shortendPageUrl
              ) {
                bpNumberValue = store
              }
            })

            if (!bpNumberValue) {
              setGetUrlData(authorData ?? {})
              console.error("Store detail not configured: BP number not found")
            } else {
              setGetUrlData(authorData ?? {})
              setBpNumberState(bpNumberValue)
              if (getStoreURL) {
                getStoreFromBP(bpNumberValue, getStoreURL)
              }
            }
          }
        })
        .catch(err => {
          console.log("Failed to get compare attributes JSON file", err)
        })
    })
  }, [])
  const getStoreFromBP = (bpNumberValue, getStoreURL) => {
    if (!_isEmpty(getStoreURL)) {
      apim
        .get(`${getStoreURL}/${bpNumberValue}`, {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            "Access-Control-Allow-Headers": "X-Requested-With",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
        .then(response => {
          if (response && response.data) {
            setStoreData(response.data ?? {})
          } else {
            dispatch(
              showToast({
                message: staticTexts?.genError,
                isVisible: true,
              })
            )
          }
        })
        .catch(err => {
          dispatch(
            showToast({
              message: staticTexts?.genError,
              isVisible: true,
            })
          )
        })
    } else {
      console.warn("Store Data: Get URL Property not found")
    }
  }

  const getRequestAppointment = (val, selectedDateSucess) => {
    // const genericError = authorData.genericError
    setLoading(true)
    getAppointmentData(val, selectedDateSucess)

    apim
      .post(info.getStoreAppointmentUrl, val, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          "Access-Control-Allow-Headers": "X-Requested-With",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
      .then(response => {
        if (response && response.data) {
          const message = _get(response.data, "success", false)
          if (message === true) {
            setIsSuccess(true)
          }

          const spliteDate =
            selectedDateSucess?.split("at") || appointmentDate?.split("at")
          const dateSplit = spliteDate[0]?.split(",")
          const monthSplit = dateSplit[0]?.split(" ")

          const getFormatDate = `${monthSplit[1]}-${
            monthSplit[0]
          }-${dateSplit[1]?.trim()}`
          const getFormatTime = spliteDate[1]?.trim()
          addBookAppointmentAnalytics({
            storeData: storeData,
            action: "cmp:click",
            status: "success",
            errorMessage: [],
            appointmentDate: getFormatDate,
            appointmentTime: getFormatTime,
          })
        } else {
          setLoading(false)

          dispatch(
            showToast({
              message: staticTexts?.genError,
              isVisible: true,
            })
          )
          console.log("Fetching data failed")
          addBookAppointmentAnalytics({
            storeData: storeData,
            action: "cmp:click",
            status: "failure",
            errorMessage: [staticTexts.genError],
            appointmentDate: null,
            appointmentTime: null,
          })
        }
      })
      .catch(err => {
        dispatch(
          showToast({
            message: staticTexts?.genError,
            isVisible: true,
          })
        )
        setLoading(false)
        console.log(err)
      })
  }

  const getAppointmentDate = val => {
    setAppointmentDate(val)
  }

  const getAppointmentData = (val, selectedDateSucess) => {
    const appointmentData = {}
    let checkWeekdayTime
    let isAllDay
    let isWeekend
    let checkWeekEndTime
    let saturdayTime
    let sundayTime

    const mondayStoreTime = _get(storeData, "Monday_Store_Hours__c", "")
    const saturdayStoreTime = _get(storeData, "Open_Hours_Saturday__c", "")
    const sundayStoreTime = _get(storeData, "Open_Hours_Sunday__c", "")

    const mondayTime = mondayStoreTime?.split(";")

    if (
      mondayStoreTime === saturdayStoreTime &&
      mondayStoreTime === sundayStoreTime
    ) {
      isAllDay = true
      checkWeekdayTime = `${mondayTime[0]}-${mondayTime[1]}`
    } else {
      isAllDay = false
      checkWeekdayTime = `${mondayTime[0]}-${mondayTime[1]}`
    }

    if (saturdayStoreTime === sundayStoreTime) {
      isWeekend = true
      const weekEndTime =
        saturdayStoreTime !== "Closed" ? saturdayStoreTime?.split(";") : []
      checkWeekEndTime =
        weekEndTime.length > 0
          ? `${saturdayStoreTime[0]}-${saturdayStoreTime[1]}`
          : "Closed"
    } else {
      isWeekend = false
      saturdayTime = saturdayStoreTime?.split(";")
      sundayTime = sundayStoreTime?.split(";")
    }

    appointmentData.selectedDate = selectedDateSucess
    appointmentData.storeName = _get(storeData, "AEM_Microsite_Name__c", "")
    appointmentData.address =
      `${storeData.BillingStreet}, ${storeData.BillingCity}, ${storeData.BillingState} ${storeData.BillingPostalCode}` ??
      ""
    appointmentData.contactName =
      `${val?.FirstName__c} ${val?.LastName__c}` ?? ""
    appointmentData.contactEmail = _get(val, "Preferred_Email__c", "")
    appointmentData.contactPhone = _get(val, "PreferredPhone", "")
    appointmentData.storeImgDesktop = _get(getUrlData, "imageDesktop", "")
    appointmentData.storeImgMobilePortrait = _get(
      getUrlData,
      "imageMobilePortrait",
      ""
    )
    appointmentData.storeImgMobileLandscape = _get(
      getUrlData,
      "imageMobileLandscape",
      ""
    )
    appointmentData.sameHours = isAllDay
    appointmentData.openingHours = checkWeekdayTime
    appointmentData.saturday = !isWeekend
      ? `${saturdayTime[0]}${saturdayTime[1] ? `-${saturdayTime[1]}` : ""}`
      : ""
    appointmentData.sunday = !isWeekend
      ? `${sundayTime[0]}${sundayTime[1] ? `-${sundayTime[1]}` : ""}`
      : ""
    appointmentData.weekendHours = isWeekend ? checkWeekEndTime : ""
    appointmentData.storeDetailUrl = storeDetails[bpNumberState] ?? "/"
    setSuccessData(appointmentData)
  }

  const modalClose = () => {
    setIsSuccess(false)
    setLoading(false)
    dispatch(hideAppointmentModal())
  }
  const {
    ariaLabel = {
      sunday: "",
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
    },
    january = "",
    february = "",
    march = "",
    april = "",
    may = "",
    june = "",
    july = "",
    august = "",
    september = "",
    october = "",
    november = "",
    december = "",
  } = staticTexts
  const daysData = [
    ariaLabel.sunday,
    ariaLabel.monday,
    ariaLabel.tuesday,
    ariaLabel.wednesday,
    ariaLabel.thursday,
    ariaLabel.friday,
    ariaLabel.saturday,
  ]
  const monthsData = [
    january,
    february,
    march,
    april,
    may,
    june,
    july,
    august,
    september,
    october,
    november,
    december,
  ]

  let internalLinkType = `find a store:${getInternalLinkTypeForFindStore(
    storeData.BillingStreet,
    storeData.BillingCity,
    storeData.BillingState
  )}`
  if (window.storeLinkType) {
    try {
      internalLinkType = sanitizeTextForAnalytics(window.storeLinkType)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  return (
    <StoreAppointmentView
      i18n={staticTexts}
      daysData={daysData}
      monthsData={monthsData}
      isSuccess={isSuccess}
      successData={successData}
      getStoreData={storeData}
      requestAppointment={(val, selectedDateSucess) =>
        getRequestAppointment(val, selectedDateSucess)
      }
      hideInput={true}
      getAppointmentDate={val => getAppointmentDate(val)}
      hidePast={true}
      bpNumber={bpNumberState}
      onModalClose={() => modalClose()}
      legalPathLinks={info.legalPathLinks}
      getUrlData={getUrlData}
      loading={loading}
      internalLinkType={internalLinkType}
    />
  )
}

export default StoreAppointment
