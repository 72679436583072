import React, { useEffect, useState } from "react"
import Style from "@/components/VisualSearch/v1/LoadingData/loadingdata.module.scss"
import Heading from "@/components/core/Heading/Heading"
import useViewport from "@/hooks/useViewport"
import QRCode from "@/components/VisualSearch/v1/QRCode"
import { getProductByImage } from "@/services/ct.service"
import {
  data,
  APP_URL,
} from "@/components/VisualSearch/v1/LoadingData/constant"

const LoadingData = props => {
  const { image = [], category = "", setSection, setOpenCam } = props
  const [progress, setProgress] = useState(data[0])
  const [productData, setProductData] = useState(null)
  const device = useViewport()

  useEffect(() => {
    let timeId1
    let timeId2
    let timeId3
    if (image && category) {
      if (device.isSmallTabView) {
        document?.body?.classList?.add("overflow-hidden")
      } else {
        document.body.classList.remove("overflow-hidden")
      }
      timeId1 = setTimeout(() => setProgress(data[1]), 1000)
      timeId2 = setTimeout(() => setProgress(data[2]), 2500)
      timeId3 = setTimeout(() => setProgress(data[3]), 4000)
      setOpenCam(false)
      const categoryVal = getValidCategory(category)
      getSkuNumberByImage(image, categoryVal)
    }
    return () => {
      clearTimeout(timeId1)
      clearTimeout(timeId2)
      clearTimeout(timeId3)
    }
  }, [image, category])

  useEffect(() => {
    let timeId

    if (productData !== null) {
      const { pdpUrl } = productData
      if (pdpUrl) {
        timeId = setTimeout(() => {
          const url = pdpUrl.replace(APP_URL, window?.location?.origin)
          window.open(url, "_self")
          document.body.classList.remove("overflow-hidden")
        }, 3000)
      }
    }
    return () => {
      clearTimeout(timeId)
    }
  }, [productData])

  const getSkuNumberByImage = async (image, category = "faucets") => {
    const { file } = image
    try {
      if (file && category) {
        const formdata = new FormData()
        formdata.append("category", category)
        formdata.append("file", file)
        const response = await getProductByImage(formdata)
        if (response?.data?.result) {
          setProductData(response?.data?.result)
        } else {
          setProductData(null)
          setSection("error")
        }
      }
    } catch (err) {
      setSection("error")
    }
  }

  const getValidCategory = value => {
    if (typeof value !== "string") setSection("error")
    const categoryList = ["faucets", "toilet", "valve", "shower"]
    if (categoryList.includes(value.toLowerCase())) {
      return value.toLowerCase()
    } else {
      return categoryList[0]
    }
  }

  if (!category) return null

  return (
    <div className={Style.LoadingImage}>
      <div className="image-loading-container visualContentwarpper d-flex justify-content-center align-items-center">
        <div className="upload-image d-flex justify-content-center align-items-center flex-column">
          <div className="icon-upload">
            <img src={progress?.imageUrl} alt={progress?.text} />
          </div>
          <Heading fontSize={34} level={4} className="loading-text">
            {progress?.text}
          </Heading>
        </div>
      </div>
      <div className="qrWrapper">
        {!device?.isSmallScreen ? <QRCode /> : null}
      </div>
    </div>
  )
}

export default LoadingData
