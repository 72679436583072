export const CorrectIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20"
        cy="20"
        r="20"
        transform="matrix(1 0 0 -1 0 40)"
        fill="#4A9232"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.3778 12L29.3307 12.8741L18.1272 26.6667L10.6641 20.3326L11.4366 19.3022L17.9531 24.8337L28.3778 12Z"
        fill="white"
      />
    </svg>
  )
}

export const IncorrectIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15"
        cy="15"
        r="15"
        transform="matrix(1 0 0 -1 0 30)"
        fill="#D10000"
      />
      <path
        d="M10.7043 10L19.8599 19.2857L19.1556 20L10 10.7143L10.7043 10Z"
        fill="white"
      />
      <path
        d="M19.2957 10L10.1401 19.2857L10.8444 20L20 10.7143L19.2957 10Z"
        fill="white"
      />
    </svg>
  )
}
export const CloseIconGuide = () => (
  <svg
    className="close-big"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.5015 15.4996L28.9365 2.06453L28.2294 1.35742L14.7944 14.7924L2.06648 2.06453L1.35938 2.77164L14.0873 15.4996L1.35938 28.2275L2.06648 28.9346L14.7944 16.2067L28.2294 29.6417L28.9365 28.9346L15.5015 15.4996Z"
      fill="white"
    />
  </svg>
)

export const CloseIconGuideMob = () => (
  <svg
    className="close_small"
    width="17"
    height="30"
    viewBox="0 0 17 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="close/medium">
      <g id="Path">
        <path
          d="M7.79181 15.4999L0.367188 22.9245L1.07429 23.6316L8.49892 16.207L15.9235 23.6316L16.6306 22.9245L9.20602 15.4999L16.6306 8.07527L15.9235 7.36816L8.49892 14.7928L1.07429 7.36816L0.367188 8.07527L7.79181 15.4999Z"
          fill="white"
        />
        <path
          d="M7.79181 15.4999L0.367188 22.9245L1.07429 23.6316L8.49892 16.207L15.9235 23.6316L16.6306 22.9245L9.20602 15.4999L16.6306 8.07527L15.9235 7.36816L8.49892 14.7928L1.07429 7.36816L0.367188 8.07527L7.79181 15.4999Z"
          fill="white"
        />
        <path
          d="M7.79181 15.4999L0.367188 22.9245L1.07429 23.6316L8.49892 16.207L15.9235 23.6316L16.6306 22.9245L9.20602 15.4999L16.6306 8.07527L15.9235 7.36816L8.49892 14.7928L1.07429 7.36816L0.367188 8.07527L7.79181 15.4999Z"
          stroke="white"
          stroke-width="0.5"
        />
        <path
          d="M7.79181 15.4999L0.367188 22.9245L1.07429 23.6316L8.49892 16.207L15.9235 23.6316L16.6306 22.9245L9.20602 15.4999L16.6306 8.07527L15.9235 7.36816L8.49892 14.7928L1.07429 7.36816L0.367188 8.07527L7.79181 15.4999Z"
          stroke="white"
          stroke-width="0.5"
        />
      </g>
    </g>
  </svg>
)

export const CorrectIconMob = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="15"
      cy="15"
      r="15"
      transform="matrix(1 0 0 -1 0 30)"
      fill="#4A9232"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.2853 9L22 9.65557L13.5973 20L8 15.2495L8.57941 14.4766L13.4668 18.6253L21.2853 9Z"
      fill="white"
    />
  </svg>
)
