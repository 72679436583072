import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { apim } from "@/constants/api"
import { editAddressEndPoint, GET_PROFILE_ENDPOINT } from "@/constants"
import { arrayMove } from "@/utils/helper"

const initialState = {
  status: "",
  error: "",
  addresses: [],
  isVerifiedAddress: false,
  profileInfo: null,
  profileVersion: null,
  nextDefaultAddressId: null,
  isExpPayPalCheckout: false,
  paymentMethod: "",
  payPalCallBackErrorMsg: "",
  cardLastDigit: "",
  paymentErrorMessage: "",
  paymentMethodName: "",
  placeOrderCounts: 1,
  isShipmentFromCart: false,
}

export const editAddress = createAsyncThunk(
  "customer/address/edit",
  async (payload, thunkAPI) => {
    let addresses = []
    const body = {
      actions: [
        {
          action: "changeAddress",
          addressId: payload.id,
          address: payload.address,
        },
      ],
      version: payload.version,
    }
    try {
      const res = await apim.post(`${editAddressEndPoint}`, body)
      if (res && res.data) {
        if (res.data.addresses.length > 0) {
          addresses =
            res.data.addresses?.filter(
              addr => addr.custom?.fields?.label !== "Profile"
            ) ?? []
          if (payload.address.setPrimary) {
            res.data.setPrimary = true
            res.data.nextDefaultAddressId = payload.id
          }
        }
      }
      res.data.addresses = addresses
      return res.data
    } catch (error) {
      const {
        response: { data: { isNonDeliverableAddress = false } = {} } = {},
      } = error
      return thunkAPI.rejectWithValue({ isNonDeliverableAddress })
    }
  }
)

export const addAddress = createAsyncThunk(
  "customer/address/add",
  async (payload, thunkAPI) => {
    let addresses = []
    const body = Array.isArray(payload.address)
      ? {
          version: payload.version,
          actions: payload.data,
        }
      : {
          version: payload.version,
          actions: [
            {
              action: "addAddress",
              address: payload.address,
            },
          ],
        }
    try {
      const res = await apim.post(`${editAddressEndPoint}`, body)
      if (res && res.data) {
        if (res.data.addresses.length > 0) {
          addresses =
            res.data.addresses?.filter(
              addr => addr.custom?.fields?.label !== "Profile"
            ) ?? []
          if (payload.address.setPrimary) {
            const index = addresses.length - 1
            res.data.setPrimary = true
            res.data.nextDefaultAddressId = addresses[index]?.id
          }
        }
      }
      res.data.addresses = addresses
      return res.data
    } catch (error) {
      const {
        response: { data: { isNonDeliverableAddress = false } = {} } = {},
      } = error
      return thunkAPI.rejectWithValue({ isNonDeliverableAddress })
    }
  }
)

export const setDefaultAddress = createAsyncThunk(
  "customer/default/address",
  async payload => {
    const resPayload = {
      addresses: [],
      defaultShippingAddressId: "",
    }
    const body = {
      version: payload.profileVersion,
      actions: [
        {
          action: "setDefaultShippingAddress",
          addressId: payload.id,
        },
      ],
    }
    const res = await apim.post(`${editAddressEndPoint}`, body)
    if (res && res.data) {
      resPayload.version = res.data.version
      if (res.data.addresses.length > 0) {
        resPayload.addresses =
          res.data.addresses?.filter(
            addr => addr.custom?.fields?.label !== "Profile"
          ) ?? []
        resPayload.defaultShippingAddressId = res.data.defaultShippingAddressId
      }
    }
    return resPayload
  }
)

export const getProfile = createAsyncThunk("customer/me", async () => {
  let profile = {}
  let index = 0
  let addresses = {}
  const response = await apim.get(`${GET_PROFILE_ENDPOINT}`)
  profile = response.data
  const profileAddr = profile?.addresses?.find(
    addr => addr.custom?.fields?.label === "Profile"
  )
  profile["profileAddrId"] = profileAddr?.id
  profile["addresses"] = profile?.addresses?.filter(
    addr => addr.custom?.fields?.label !== "Profile"
  )

  addresses = profile?.addresses
  if (profile?.defaultShippingAddressId) {
    index = addresses.findIndex(
      item => item.id === profile?.defaultShippingAddressId
    )
  }
  if (index !== 0) {
    addresses = arrayMove(profile?.addresses, index, 0)
  }
  profile["addresses"] = addresses
  response["data"] = profile
  return response.data
})

export const checkoutSlice = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    setIsPaymentInfoAction(state, action) {
      state.isPaymentInfo = action.payload
    },
    setPaymentMethodNameAction(state, action) {
      state.paymentMethodName = action.payload
    },
    setCheckoutAction(state, action) {
      state.checkoutStep = action.payload
    },
    setPaymentFailedAction(state, action) {
      state.isPaymentFailed = action.payload
    },
    setIsInvalidCountryAction(state, action) {
      state.isInvalidCountry = action.payload
    },
    setPaymentTechnicalFailedAction(state, action) {
      state.isPaymentTechnicalErrorFailed = action.payload
    },
    setPaymentErrorMessageAction(state, action) {
      state.paymentErrorMessage = action.payload
    },
    setCardLastDigit(state, action) {
      state.cardLastDigit = action.payload
    },
    setPaymentDisableBtnAction(state, action) {
      state.isPaymentDisableAction = action.payload
    },
    setPlaceOrderCountsAction(state, action) {
      state.placeOrderCounts = action.payload
    },
    setBillingAddress(state, action) {
      state.billingAddress = action.payload
    },
    setExpPayPalCheckout(state, action) {
      state.isExpPayPalCheckout = action.payload
    },
    setPayPalCallBackErrorMsg: (state, action) => {
      state.payPalCallBackErrorMsg = action.payload
    },
    setIsVerifiedAddress: (state, action) => {
      state.isVerifiedAddress = action.payload
    },
    setIsShipmentFromCart: (state, action) => {
      state.isShipmentFromCart = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase(editAddress.pending, state => {
        state.status = "loading"
      })
      .addCase(editAddress.fulfilled, (state, action) => {
        state.status = "succeeded"
        const { addresses, version, nextDefaultAddressId, setPrimary } =
          action.payload
        state.addresses = addresses
        state.profileVersion = version
        if (setPrimary) {
          state.nextDefaultAddressId = nextDefaultAddressId
        }
      })
      .addCase(editAddress.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(setDefaultAddress.pending, state => {
        state.status = "loading"
      })
      .addCase(setDefaultAddress.fulfilled, (state, action) => {
        state.status = "succeeded"
        const { addresses, version } = action.payload
        state.addresses = addresses
        state.profileVersion = version
      })
      .addCase(setDefaultAddress.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getProfile.pending, state => {
        state.status = "loading"
      })
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.profileInfo = action.payload
        state.profileVersion = action.payload.version
      })
      .addCase(getProfile.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(addAddress.pending, state => {
        state.status = "loading"
      })
      .addCase(addAddress.fulfilled, (state, action) => {
        state.status = "succeeded"
        const {
          addresses,
          version,
          nextDefaultAddressId,
          setPrimary,
          defaultShippingAddressId,
        } = action.payload
        state.addresses = addresses
        state.profileVersion = version
        state.defaultAddressId = defaultShippingAddressId
        if (setPrimary) {
          state.nextDefaultAddressId = nextDefaultAddressId
        }
      })
      .addCase(addAddress.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {
  setIsPaymentInfoAction,
  setPaymentMethodNameAction,
  setCheckoutAction,
  setPaymentFailedAction,
  setIsInvalidCountryAction,
  setBillingAddress,
  setPaymentErrorMessageAction,
  setPaymentTechnicalFailedAction,
  setPaymentDisableBtnAction,
  setPlaceOrderCountsAction,
  setCardLastDigit,
  setExpPayPalCheckout,
  setPayPalCallBackErrorMsg,
  setIsVerifiedAddress,
  setIsShipmentFromCart,
} = checkoutSlice.actions
export const checkoutState = state => state.checkout
