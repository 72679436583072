import _get from "lodash/get"
import qs from "qs"
import { apim } from "@/constants/api"
import {
  getPdpTooltipEndPoint,
  searchPdpEndPoint,
  searchPlpEndPoint,
  SEARCH_PDP_FL,
} from "@/constants/index"
import {
  CATALOG_PROJECTION_ENDPOINT,
  SVG_METADATA_ENDPOINT,
} from "@/constants/routes"
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { addToRecentlyViewed } from "@/utils/helper"
import { getInitialSku } from "@/components/ProductDetail/v3/productDetailsHelper"
import { getConfig } from "@/constants/config"
import { getSessionIdObjWithEpoc } from "@/utils/ApiUtil"

const initialState = {
  pdp: {},
  isDiscontinued: false,
  productInfo: {},
  teaserContent: [],
  isTeaserLoaded: false,
  requiredItemsSkuData: [],
}

export const searchPdp = createAsyncThunk(
  "pdp/searchPdp",
  async (payload, thunkAPI) => {
    try {
      const {
        slug,
        isParts,
        selectedSku,
        selectedColor,
        setSkuVariantCb,
        getVariantFieldsCb,
        lwAppName = "",
      } = payload

      const config = await getConfig()
      const language = config?.internationalization?.language ?? "en"

      const response = await apim.post(`${searchPdpEndPoint}/${slug}`, {
        q: "*:*",
        fl: SEARCH_PDP_FL.join(","),
        fq: `language_s:("${language}")`,
        collections: isParts ? "service_parts" : lwAppName?.toLowerCase(),
        profilename: isParts
          ? `profile_service_parts_PDP`
          : `profile_${lwAppName?.toLowerCase()}_PDP`,
        search_type: "product_detail",
        sessionId: getSessionIdObjWithEpoc(),
      })
      const {
        data: {
          response: { docs = [] } = {},
          fusion: {
            BundleSKUDetails: bundleIncludedItems = [],
            ProductDimensionAttributes = {},
            ProductDimensionAttributesValues = {},
          } = {},
        } = {},
      } = response

      const dimensionData =
        Object.keys(ProductDimensionAttributes).length &&
        Object.keys(ProductDimensionAttributesValues).length
          ? {
              ProductDimensionAttributes,
              ProductDimensionAttributesValues,
            }
          : {}

      const productData = docs.at(0)

      const threeSixtyResourceData = {}

      if (productData["ItemResource.IMGSpinSet.resourceFullWebURL_ss"]) {
        threeSixtyResourceData.resourceFullWebUrl =
          productData["ItemResource.IMGSpinSet.resourceFullWebURL_ss"]
      }
      if (productData["ItemResource.IMGSpinSet.resourceAltText_ss"]) {
        threeSixtyResourceData.resourceAltText =
          productData["ItemResource.IMGSpinSet.resourceAltText_ss"]
      }
      if (productData["ItemResource.IMGSpinSet.resourceName_ss"]) {
        threeSixtyResourceData.resourceName =
          productData["ItemResource.IMGSpinSet.resourceName_ss"]
      }
      if (productData["ItemResource.IMGSpinSet.resourceType_ss"]) {
        threeSixtyResourceData.resourceTypeImg =
          productData["ItemResource.IMGSpinSet.resourceType_ss"]
      }
      const sku =
        selectedSku === "" ||
        !productData["variantList.sku_ss"].some(v => v === selectedSku)
          ? getInitialSku({ productData, setSkuVariantCb, selectedColor })
          : selectedSku
      const customerFacingSKU = productData["CustomerFacingSKU_s"]

      const fusionQueryId = _get(response.data, "fusion.fusionQueryId")
      if (productData["variantList.sku_ss"]) {
        getVariantFieldsCb(productData["variantList.sku_ss"])
      }

      let recommendedAccessories = []
      if (productData["ProductProductRecommendedAccessories_ss"]) {
        recommendedAccessories =
          productData["ProductProductRecommendedAccessories_ss"]
      } else if (productData["ProductProductRecommendedAccessories_s"]) {
        recommendedAccessories.push(
          productData["ProductProductRecommendedAccessories_s"]
        )
      }

      let colors = []
      if (productData["Color.SKU.Details_ss"]) {
        colors = productData["Color.SKU.Details_ss"].map(color => {
          const d = color.split("|")
          return {
            sku: d[2],
            discontinued: !(d[4] === "false" || Date.parse(d[4]) > Date.now()),
          }
        })
      } else if (productData["SKU.Details_ss"]) {
        colors = productData["SKU.Details_ss"].map(color => {
          const d = color.split("|")
          return {
            sku: d[0],
            discontinued: !(d[2] === "false" || Date.parse(d[2]) > Date.now()),
          }
        })
      }

      addToRecentlyViewed(productData["ctId_s"])

      return {
        bundleIncludedItems,
        productData,
        sku,
        recommendedAccessories,
        colors,
        fusionQueryId,
        customerFacingSKU,
        slug,
        dimensionData,
        threeSixtyResourceData,
      }
    } catch (err) {
      return err
    }
  }
)

export const getVariantFields = createAsyncThunk(
  "pdp/getVariantFields",
  async payload => {
    try {
      const { skuList = [], slug, isParts, lwAppName = "" } = payload
      let skus = ""
      skuList.forEach(s => (skus += `"${s}" `))

      const config = await getConfig()
      const language = config?.internationalization?.language ?? "en"

      const response = await apim.post(`${searchPdpEndPoint}/${slug}`, {
        q: "*:*",
        fq: `sku_s:(${skus.trim()}),language_s:("${language}")`,
        fl: "sku_s,RegionStatesExcluded_ss,RegionSpecialOrder_s",
        collections: isParts ? "service_parts" : lwAppName.toLowerCase(),
        profilename: isParts
          ? `profile_service_parts_PDP`
          : `profile_${lwAppName.toLowerCase()}_PDP`,
        search_type: "product_detail",
        sessionId: getSessionIdObjWithEpoc(),
      })

      return response.data
    } catch (err) {
      return err
    }
  }
)

export const getPartList = createAsyncThunk(
  "pdp/getPartList",
  async payload => {
    let parts = {}
    try {
      const { productId } = payload
      const response = await apim.get(`${SVG_METADATA_ENDPOINT}/${productId}`)

      if (response && response.data) {
        parts = response.data
      }
    } catch (err) {
      return err
    }
    return parts
  }
)

export const getProductBySlug = createAsyncThunk(
  "pdp/getProductBySlug",
  async payload => {
    let product = {}
    try {
      const { slug } = payload
      const response = await apim.get(
        `${CATALOG_PROJECTION_ENDPOINT}?where=slug(en-US%3D"${slug}")`
      )

      if (response && response.data && response.data.results) {
        product = response.data.results[0] ?? {}
      }
    } catch (err) {
      return err
    }
    return product
  }
)

export const getProductBySlugAndSku = createAsyncThunk(
  "pdp/getProductBySlugAndSku",
  async payload => {
    const { slug, skus } = payload
    let query = ""
    let masterQuery = ""
    if (slug) masterQuery += `slug(en-US%3D"${slug}") or `
    if (skus.length > 0) {
      skus.forEach((sku, i) => {
        query += `"${sku}"`
        if (i + 1 !== skus.length) query += ", "
      })
      masterQuery += `masterVariant(sku in (${query})) or variants(sku in (${query}))`
    }
    try {
      const res = await apim.get(
        `${CATALOG_PROJECTION_ENDPOINT}?where=${masterQuery}`
      )
      if (res && res.data && res.data.results) return res.data.results
    } catch (err) {
      console.error("Failed to fetch product details", err)
    }
    return []
  }
)

export const getReqItemLwData = createAsyncThunk(
  "pdp/getReqItemLwData",
  async payload => {
    const { query, lwAppName = "" } = payload
    const config = await getConfig()
    const language = config?.internationalization?.language ?? "en"
    const response = await apim.get(searchPlpEndPoint, {
      params: {
        q: "*:*",
        fl: "ctId_s, RegionOnBackOrder_s",
        fq: [
          `ctId_s:(${query.trim()})`,
          'masterVariant_b:("true")',
          `language_s:("${language}")`,
        ],
        collections: lwAppName.toLowerCase(),
        profilename: `profile_${lwAppName.toLowerCase()}_General`,
        sessionId: getSessionIdObjWithEpoc(),
      },
      paramsSerializer: params => {
        return qs.stringify(params, { arrayFormat: "repeat", encode: false })
      },
    })
    return response?.data?.response?.docs ?? []
  }
)

export const fetchPdpRequiredItems = createAsyncThunk(
  "pdp/fetchPdpRequiredItems",
  async query => {
    try {
      const response = await apim.get(
        `${CATALOG_PROJECTION_ENDPOINT}?where=id in (${query})`
      )
      return response.data.results ?? []
    } catch (err) {
      return err
    }
  }
)

export const getPdpTooltip = createAsyncThunk(
  "pdp/getPdpTooltip",
  async payload => {
    const { brandName } = payload
    const response = await apim.get(getPdpTooltipEndPoint, {
      params: {
        collection: brandName?.toLowerCase(),
      },
    })
    return response?.data
  }
)

export const pdpSlice = createSlice({
  name: "pdp",
  initialState,
  reducers: {
    setPDP(state, action) {
      state.pdp = { ...state.pdp, ...action.payload }
    },
    getDiscontinuedProductValue(state, action) {
      state.isDiscontinued = action.payload
    },
    setProductInfo(state, action) {
      state.productInfo = action.payload
    },
    setTeaserContent(state, action) {
      state.teaserContent = action.payload
    },
    setIsTeaserLoaded(state, action) {
      state.isTeaserLoaded = action.payload
    },
    setRequiredItemsSkuData(state, action) {
      state.requiredItemsSkuData = action.payload
    },
  },
  extraReducers(builder) {
    builder
      .addCase([HYDRATE], (state, action) => {
        return {
          ...state,
          ...action.payload.pdp,
        }
      })
      .addCase(searchPdp.pending, (state, action) => {
        state.status = "pending"
      })
      .addCase(searchPdp.fulfilled, (state, action) => {
        const {
          productData = {},
          sku = "",
          recommendedAccessories = [],
          colors = [],
          slug = "",
          customerFacingSKU = "",
          bundleIncludedItems = [],
        } = action.payload
        state.status = "succeeded"
        state.pdp = {
          sku,
          slug: slug,
          recommendedAccessories,
          productBrand: productData["ProductBrandNameDisplay_t"],
          productNo: productData["ProductProductNo_s"],
          data: productData,
          variants: colors,
          customerFacingSKU: customerFacingSKU
            ? customerFacingSKU.replace("K-", "")
            : "",
          bundleIncludedItems,
        }
      })
      .addCase(searchPdp.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
      .addCase(getProductBySlugAndSku.pending, state => {
        state.status = "pending"
      })
      .addCase(getProductBySlugAndSku.fulfilled, (state, action) => {
        state.status = "succeeded"
        state.productServices = action.payload
      })
      .addCase(getProductBySlugAndSku.rejected, (state, action) => {
        state.status = "failed"
        state.error = action.error.message
      })
  },
})

export const {
  setPDP,
  getDiscontinuedProductValue,
  setProductInfo,
  setTeaserContent,
  setIsTeaserLoaded,
  setRequiredItemsSkuData,
} = pdpSlice.actions
export const selectPdpState = state => state.pdp
export default pdpSlice.reducer
