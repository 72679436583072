import React from "react"
import { useTranslation } from "next-i18next"
import { QRCode as Qr } from "react-qr-code"
import Style from "@/components/VisualSearch/v1/QRCode/index.module.scss"
import { getTranslations } from "@/components/VisualSearch/localization"

const QRCode = () => {
  const baseUrl = window.location.href
  const currentUrl = `${baseUrl}#search`
  const { t } = useTranslation()
  const { QRCodeText } = getTranslations(t)

  return (
    <div className={`${Style.QRCodeWrapper}`}>
      <div className="qr-section">
        <p className="photo_label">{QRCodeText}</p>
        <div className="qr-part">
          <Qr size={82.93} value={currentUrl} />
        </div>
      </div>
    </div>
  )
}

export default QRCode
