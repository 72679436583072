import { store } from "@/store"

export const getTranslations = t => {
  const { auth: { authModal: { staticText = {} } = {} } = {} } =
    store.getState()
  const {
    passwordlabel = t("kf.changePassword.label.password"),
    resetpasswordlabel = t("kf.changePassword.label.resetPassword"),
    passwordrequirementstext = t(
      "kf.changePassword.label.passwordRequirements"
    ),
    reEnterPassword = t("kf.changePassword.label.reEnterPassword"),
    createnewpassword = t("kf.changePassword.label.createNewPassword"),
    resetPassword = t("kf.changePassword.label.resetPassword"),
    newpassworddescription = t("kf.changePassword.label.createPasswordDesc"),
    passwordlabelerrormessage = t("kf.changePassword.label.passwordError"),
    reenterpasswordlabelerrormessage = t(
      "kf.auth.label.provide.reEnterPwdError"
    ),
    successMessage = t("kf.changePassword.label.successMessage"),
  } = staticText

  return {
    passwordSuccessText: t("kf.changePassword.label.success"),
    recentPasswordText: t("kf.account.error.recentPassword"),
    characterCountText: t("kf.changePassword.label.characterCount"),
    pwMustIncludeText: t("kf.changePassword.label.pwMustInclude"),
    signinText: t("kf.changePassword.label.signin"),
    passwordMatchErrorText: t("kf.changePassword.label.passwordMatchError"),
    passwordAuthErrorText: t("kf.auth.error.passwordError"),
    pwdErrorText: t("kf.auth.label.provide.pwdError"),
    passwordUpdatedText: t("kf.changePassword.label.passwordRequirements"),
    passwordLabelText: passwordlabel,
    successMessageText: successMessage,
    reenterPasswordLabelErrorMessage: reenterpasswordlabelerrormessage,
    passwordLabelErrorMessage: passwordlabelerrormessage,
    newPasswordDescriptionText: newpassworddescription,
    reEnterPasswordText: reEnterPassword,
    createNewPasswordText: createnewpassword,
    resetPasswordText: resetPassword,
    resetPasswordLabelText: resetpasswordlabel,
    passwordRequirementsText: passwordrequirementstext,
    uppercaseLetterText: t("kf.changePassword.label.uppercaseLetter"),
    lowercaseLetterText: t("kf.changePassword.label.lowercaseLetter"),
    symbolValidationText: t("kf.changePassword.label.symbolValidation"),
    numberValidationText: t("kf.changePassword.label.numberValidation"),
  }
}
