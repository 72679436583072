export const addAnalyticsShowAuthModal = () => {
  const { adobeDataLayer: {} = {} } = window

  const eventInfo = {
    eventAction: "my account:password reset:sign in",
    eventName: "my account:password reset:sign in",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "sign in",
    internalLinkPosition: "my account",
    internalLinkType: "my account:navigation",
    internalLinkZoneName: "my account:password reset:success",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const addAnalyticsShowRequirements = () => {
  const { adobeDataLayer: {} = {} } = window

  const eventInfo = {
    eventAction: "my account:password reset:password requirements",
    eventName: "my account:password reset:password requirements",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "password requirements",
    internalLinkPosition: "my account",
    internalLinkType: "my account:informative",
    internalLinkZoneName: "my account:password reset:create new password form",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}
export const addAnalyticsResetPassword = () => {
  const { adobeDataLayer: {} = {} } = window

  const eventInfo = {
    eventAction: "my account:password reset:reset password button",
    eventName: "my account:password reset:reset password button",
    eventType: "navigation",
    eventMsg: "n/a",
    eventStatus: "n/a",
    internalLinkName: "reset password",
    internalLinkPosition: "my account",
    internalLinkType: "my account:navigation",
    internalLinkZoneName: "my account:password reset:create new password form",
    internalLinkURL: "n/a",
    clickInternalLinks: "true",
  }
  pushAnalyticsObjToDataLayer(eventInfo)
}

const pushAnalyticsObjToDataLayer = (eventInfo = {}) => {
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  const event = "cmp:click"
  window.adobeDataLayer.push({
    event,
    eventInfo,
    page,
  })
}
