import { Component } from "react"
import FallBackUI from "@/components/Shared/ErrorBoundary/ErrorPage"
/**
 * Class declaration for handling frontend errors
 */
class ErrorBoundary extends Component {
  /**
   *
   * @param {*} props
   */
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }
  /**
   *
   * @param {*} error
   * @return {boolean}
   */
  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  /**
   *
   * @param {*} error
   * @param {*} errorInfo
   */
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo })
  }
  /**
   *
   * @return {JSX.Element}
   */
  render() {
    if (this.state.hasError) {
      return <FallBackUI />
    }

    return this.props.children
  }
}

export default ErrorBoundary
