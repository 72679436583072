export const getDimensions = ele => {
  const { height } = ele.getBoundingClientRect()
  const offsetTop = ele.offsetTop
  const offsetBottom = offsetTop + height

  return {
    height,
    offsetTop,
    offsetBottom,
  }
}
export const FIND_A_PRO_RESULTS_PAGENAME = "kohler:services:find a pro results"
