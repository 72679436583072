import { useEffect, useState } from "react"

const useViewport = () => {
  const [device, setDevice] = useState(getValues)
  /**
   * This function does the comparisons for different viewports
   * @return {Object} Object of all the viewport flags
   */
  function getValues() {
    const resolution = window.innerWidth
    const isExtraSmallScreen = resolution <= 375
    const isSmallScreen = resolution <= 812
    const isMediumScreen = resolution > 812 && resolution <= 1024
    const isSmallTabView = resolution < 1024
    const isLargeTabView = resolution > 1024
    const isLargeScreen = resolution >= 1024 && resolution < 1920
    const isExtraLargeScreen = resolution >= 1920

    return {
      isExtraSmallScreen,
      isSmallScreen,
      isMediumScreen,
      isLargeScreen,
      isExtraLargeScreen,
      isLargeTabView,
      isSmallTabView,
    }
  }
  /**
   * Sets updated viewport flag values
   */
  function handleWindowResize() {
    setDevice(getValues())
  }
  useEffect(() => {
    handleWindowResize()
    window.addEventListener("resize", handleWindowResize)
    return () => {
      window.removeEventListener("resize", handleWindowResize)
    }
  }, [])
  return device
}

export default useViewport
export const useViewportWidth = () => {
  const [width, setWidth] = useState(0)
  const handleWindowResize = () => setWidth(window.innerWidth)
  useEffect(() => {
    handleWindowResize()
    window.addEventListener("resize", handleWindowResize)
    return () => window.removeEventListener("resize", handleWindowResize)
  }, [])
  return width
}
