import { aemAxiosInstance } from "@/constants/api"
import Button from "@/components/core/Button/Button"
import Heading from "@/components/core/Heading/Heading"
import htmlParser from "html-react-parser"
import Style from "@/components/Shared/ErrorBoundary/index.module.scss"
import { useEffect, useState } from "react"

import {
  authorBaseUrl,
  CORE_GLOBAL_CONFIG_URL,
  footerUrl,
  GLOBAL_CONFIGURATION_ENDPOINT,
  headerUrl,
} from "@/constants/index"

import { filterBackEndJson } from "@/utils/helper"
import PageHeader from "@/components/Shared/GlobalPage/GlobalPageHeader"
import PageFooter from "@/components/Shared/GlobalPage/GlobalPageFooter"
import { useRouter } from "next/router"

const ErrorPage = () => {
  const [header, setHeader] = useState(null)
  const [footer, setFooter] = useState(null)
  const [dataURL, setDataURL] = useState("")
  const [pageData, setPageData] = useState({})

  const axiosCall = async () => {
    aemAxiosInstance({
      url: GLOBAL_CONFIGURATION_ENDPOINT,
      params: {
        path: `${headerUrl}.model.json`,
      },
    })
      .then(res => {
        setHeader(filterBackEndJson(res.data))
      })
      .catch(err => {
        console.error("Failed to get compare attributes JSON file", err)
      })

    aemAxiosInstance({
      url: GLOBAL_CONFIGURATION_ENDPOINT,
      params: {
        path: `${footerUrl}.model.json`,
      },
    })
      .then(res => {
        setFooter(filterBackEndJson(res.data))
      })
      .catch(err => {
        console.error("Failed to get compare attributes JSON file", err)
      })

    const { data: configData = {} } = await aemAxiosInstance({
      url: `${CORE_GLOBAL_CONFIG_URL}`,
      method: "GET",
    })

    setDataURL(configData?.contentFragmentListConfig?.map?.pageNotFoundError)
    if (dataURL) {
      aemAxiosInstance({
        url: GLOBAL_CONFIGURATION_ENDPOINT,
        params: {
          path: dataURL,
        },
      })
        .then(res => {
          setPageData(filterBackEndJson(res)?.data)
        })
        .catch(err => {
          console.error("Failed to get compare attributes JSON file", err)
        })
    }
  }

  useEffect(() => {
    axiosCall()
  }, [dataURL])

  const {
    errorTitle = "",
    supportNumberAndLabel = "",
    previousPageLabel = "",
    refreshLabel = "",
    homepageLable = "",
    homepageLink = "",
    errorImage = "",
  } = pageData || {}
  const homeURL = `${authorBaseUrl}${homepageLink}`
  const router = useRouter()
  const handlePrev = () => {
    document.referrer === "" ? router.push(homeURL) : history.back()
  }

  return (
    <>
      {header ? <PageHeader header={header} /> : null}
      <div className={Style.Container}>
        <div className="subContainer">
          <div className="mainSection">
            <div className="heading">
              <Heading level={1} className="heading-grid">
                {htmlParser(errorTitle)}
              </Heading>
            </div>
            <div className="buttonSection">
              <Button
                type="ghost"
                role="button"
                size="small"
                arrowImg
                flexible
                label={previousPageLabel}
                onClick={handlePrev}
              />
              <Button
                type="ghost"
                role="button"
                size="small"
                flexible
                label={refreshLabel}
                onClick={() => window.location.reload()}
              />
              <Button
                type="ghost"
                role="button"
                size="small"
                flexible
                label={homepageLable}
                onClick={() => (window.location.href = homeURL)}
              />
              <div className="paraG">{htmlParser(supportNumberAndLabel)}</div>
            </div>
          </div>
          <div className="imageDiv">
            <img src={`${authorBaseUrl}${errorImage}`} alt="imag" />
          </div>
        </div>
      </div>
      {footer ? <PageFooter footer={footer} /> : null}
    </>
  )
}

export default ErrorPage
