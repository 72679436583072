import { Fragment, useState } from "react"
import DOMPurify from "isomorphic-dompurify"
import Slider from "react-slick"
import { useDispatch, useSelector } from "react-redux"

import { preventBodyScroll, getRandom } from "@/utils/helper"
import Action from "@/components/Default/Teaser/v1/Action"
import {
  selectGenericState,
  showPromoModal,
} from "@/store/features/genericSlice"
import styles from "@/components/Default/PromoBanner/v1/index.module.scss"

const PromoBanner = ({ data = {}, properties = {} }) => {
  const promoBannerSettings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    swipe: true,
    arrows: true,
    infinite: true,
    centerMode: false,
  }
  const { eventPageType } = useSelector(selectGenericState)
  const dispatch = useDispatch()

  const setPromoModal = data => {
    dispatch(showPromoModal(data))
    preventBodyScroll(true)
  }

  const [bannerIndex, setBannerIndex] = useState(0)

  const getComponentMessage = (message, index) => {
    if (message && index > -1) {
      const obj = JSON.parse(message)
      obj.componentInfo.componentMessage = `${
        obj.componentInfo.componentMessage
      }${index + 1}`
      return JSON.stringify(obj)
    }
  }

  const afterChangeHandler = currentIndex => {
    setBannerIndex(currentIndex)
  }

  return (
    <div className={styles.promoBannerWrapper}>
      <div
        className={`carousel-slick carousel ${data?.carouselComponentProperties?.appliedCssClassNames}`}
        role="group"
        aria-label={
          data["carouselComponentProperties"]["accessibilityLabel"] || undefined
        }
        aria-roledescription="carousel"
        data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"${eventPageType}:${
          properties?.componentPosition
            ? properties.componentPosition
            : "carousel"
        }","internalLinkZoneName":"${data?.pageName.toLowerCase()}:${
          properties?.componentZone ? properties.componentZone : "carousel"
        }","internalLinkURL":"n/a","internalLinkName":"carousel:rm","eventName":"${data?.pageName?.toLowerCase()}","internalLinkType":"${
          properties?.componentLinkType
            ? properties.componentLinkType
            : "navigation"
        }","eventType":"carousel","eventAction":"${data?.pageName?.toLowerCase()}"}`}
      >
        <div
          className={`cmp-carousel cmp-carousel-slick `}
          role="group"
          aria-label={
            data["carouselComponentProperties"]["accessibilityLabel"] ||
            undefined
          }
          aria-roledescription="carousel"
        >
          <Slider
            {...promoBannerSettings}
            className="cmp-carousel-slick__content single-slider"
            afterChange={afterChangeHandler}
          >
            {data["carouselComponentProperties"][":itemsOrder"] &&
              data["carouselComponentProperties"][":itemsOrder"]?.map(
                (key, index, arr) => {
                  const item =
                    data["carouselComponentProperties"][":items"][key]
                  return (
                    <Fragment
                      key={
                        item?.id
                          ? item?.id
                          : `cmp-promo-banner-${index}-${getRandom()}`
                      }
                    >
                      <div
                        className="promo-banner"
                        style={{ visibility: "visible" }}
                      >
                        <div className="cmp-promo__description">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(item?.description),
                            }}
                          />
                          {item?.actionsEnabled &&
                            item?.actionItems?.map((action, index) => {
                              return action.enablePopup ? (
                                <a
                                  onClick={() => 
                                    setPromoModal({
                                      show: true,
                                      modalContent: {
                                        banner: item,
                                        item: action,
                                        count: index,
                                      },
                                    })
                                  }
                                  data-toggle={"modal"}
                                  data-target={`#${item?.promoId}-${index}`}
                                  key={`#${item?.promoId}-${index}`}
                                  className="cmp-promo__action-link btn promo-banner-fragment"
                                  rel={`${action.linkRef ? "noreferrer" : ""}`}
                                  data-gbh-component={getComponentMessage(
                                    item?.componentInfos,
                                    bannerIndex
                                  )}
                                  data-gbh-data-layer={`{"clickInternalLinks":"true","internalLinkPosition":"${item?.pageType?.toLowerCase()}:${
                                    properties?.componentPosition
                                      ? properties.componentPosition
                                      : "promo banner"
                                  }","internalLinkZoneName":"${item?.pageType.toLowerCase()}:${
                                    properties?.componentZone
                                      ? properties.componentZone
                                      : "global header"
                                  }","internalLinkURL":"n/a","internalLinkName":"${
                                    action?.text
                                  }","eventName":"${action?.text.toLowerCase()}","internalLinkType":"${
                                    properties?.componentLinkType
                                      ? properties.componentLinkType
                                      : "navigation"
                                  }","eventType":"promo banner","eventAction":"${action?.text.toLowerCase()}"}`}
                                  data-gbh-clickable={true}
                                >
                                  {action?.text}
                                </a>
                              ) : (
                                <Action
                                  item={item}
                                  key={`promo-banner-action-${
                                    bannerIndex + 1
                                  }-${getRandom()}`}
                                  {...action}
                                  link={action?.link || action?.ctaLink}
                                  newTab={action?.isNewTab}
                                  bannerIndex={bannerIndex}
                                />
                              )
                            })}
                        </div>
                      </div>
                    </Fragment>
                  )
                }
              )}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default PromoBanner
