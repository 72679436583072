import {
  sanitizeTextForAnalytics,
  pushAnalyticsObjToDataLayer,
} from "@/utils/helper"

const handleSearchAnalyticsEvent = (
  status,
  message,
  internalLinkURL,
  eventType,
  redirectUrl,
  analyticsEventData,
  searchString,
  searchTotal,
  topSuggestedData,
  searchHistory,
  brandName
) => {
  if (!internalLinkURL) internalLinkURL = redirectUrl
  const eventInfo = getEventInfoObj(
    status,
    message,
    internalLinkURL,
    eventType,
    analyticsEventData,
    searchString,
    searchTotal,
    topSuggestedData,
    searchHistory,
    brandName
  )
  sessionStorage.setItem("searches", eventInfo.searchCount)
  const { adobeDataLayer: { getState } = {} } = window
  const page = (getState && getState("page")) || {}
  window.adobeDataLayer.push({
    event: "cmp:click",
    page,
    eventInfo,
  })
}

const getEventInfoObj = (
  status,
  message,
  internalLinkURL,
  eventType,
  analyticsEventData,
  searchString,
  searchTotal,
  topSuggestedData,
  searchHistory,
  brandName
) => {
  const currDate = new Date()
  const contentType =
    window.adobeDataLayer && window.adobeDataLayer.getState
      ? window.adobeDataLayer.getState("page.pageInfo.contentType") ||
        window.adobeDataLayer.getState("page.category.primaryCategory")
      : ""
  const pageName = contentType ? contentType.split("-")[0] : ""
  const autoSearch = !internalLinkURL ? true : false
  const searchSuggestedTerm = []
  if (!analyticsEventData.searchString) {
    analyticsEventData.searchString = searchString
  }
  if (!analyticsEventData.searchTotal) {
    analyticsEventData.searchTotal = searchTotal
  }
  if (!analyticsEventData.topSuggestedData) {
    analyticsEventData.topSuggestedData = topSuggestedData
  }
  const searchSuggestions =
    searchHistory && searchHistory.length
      ? searchHistory
      : analyticsEventData.topSuggestedData &&
        analyticsEventData.topSuggestedData.length
      ? analyticsEventData.topSuggestedData
      : []
  if (searchSuggestions?.length) {
    searchSuggestions.slice(0, 5).map(item => {
      searchSuggestedTerm.push(
        sanitizeTextForAnalytics(item.productName_s || item.keyWord)
      )
    })
  }

  const eventInfo = {
    eventName: `internal ${autoSearch ? "automatic " : ""}search`,
    eventAction: `internal ${autoSearch ? "automatic " : ""}search`,
    internalLinkName: "internal search icon",
    internalLinkType: "global header:navigation",
    internalLinkZoneName: "global header",
    clickInternalLinks: "true",
    eventType: "global header",
    internalLinkURL: internalLinkURL
      ? (internalLinkURL.indexOf("http") === -1 ? window.location.origin : "") +
        internalLinkURL
      : "n/a",
    internalLinkPosition: "global header",
    eventMsg: status !== "success" ? message?.toLowerCase() : "n/a",
    eventStatus: status || "n/a",

    searchTerm: analyticsEventData.searchString,
    searchContentType: pageName ? pageName + " page" : "n/a",
    searchSuggestedTerm: searchSuggestedTerm.join("|") || "n/a",
    searchCount: sessionStorage.getItem("searches")
      ? Number(sessionStorage.getItem("searches")) + 1
      : 1,
    searchResult: analyticsEventData.searchTotal,
    internalZoneName: "search:search box",
    pageURL: window.location.href,
    referrer: document.referrer || "n/a",
    previousPage: document.referrer || "n/a", // we can not get previous page name
    timeStamp:
      currDate.toISOString().split("T")[0] +
      " " +
      currDate.toTimeString().split(" ")[0],
    contentType: brandName?.toLowerCase(),
  }

  if (eventType?.type) {
    eventType.term = sanitizeTextForAnalytics(eventType.term)
    eventType.type = sanitizeTextForAnalytics(eventType.type)

    eventInfo.eventName = "search:" + eventType.type + ":search term"
    eventInfo.eventAction = "search:" + eventType.type + ":search term"
    eventInfo.internalLinkZoneName = "search:" + eventType.type
    if (eventType.type === "our top suggestions") {
      eventInfo.searchTerm = analyticsEventData.searchString?.toLowerCase()
      eventInfo.searchSuggestedTerm = eventType.term
    } else {
      eventInfo.searchTerm = eventType.term
    }
    eventInfo.internalLinkName = eventType.term
    eventInfo.internalZoneName = "search:" + eventType.type
  }
  analyticsEventData = {}
  return eventInfo
}

const addSearchAnalytics = (params, general, searchKeyword) => {
  const {
    total,
    errorMessage,
    status,
    autoSearch,
    keyword = "",
    tabCountObj = {},
    isSterlingTemp,
    searchCountIncreased = true,
    urlFlag = false,
  } = params
  const action = autoSearch ? "cmp:click" : params?.action
  const {
    productTabCount,
    helpTabCount,
    resourceTabCount,
    technicalTabCount,
    inspireTabCount,
    partTabCount,
  } = tabCountObj
  const { adobeDataLayer: { getState } = {} } = window
  const page =
    action === "cmp:show"
      ? window?.pageObj
      : (getState && getState("page")) || {}
  const contentType = window.eventPageType || ''
  const pageName = contentType ? contentType.split("-")[0] : ""
  const previousPageNameForSearch = sessionStorage?.getItem(
    "previousPageNameForSearch"
  )
  const previousUrlNew = sessionStorage?.getItem("previousUrl")
  sessionStorage.removeItem("previousUrl")
  const currDate = new Date()
  const eventInfo = {
    searchCount: sessionStorage?.getItem("searches")
      ? Number(sessionStorage?.getItem("searches")) +
        (searchCountIncreased ? 1 : 0)
      : 1,
    searchResult: isSterlingTemp ? `${productTabCount}` : total,
    searchResultCategory: isSterlingTemp
      ? `products>${productTabCount}`
      : `products>${productTabCount}|parts>${partTabCount}|inspiration>${inspireTabCount}|technical>${technicalTabCount}|resources>${resourceTabCount}|help>${helpTabCount}`,
    internalLinkName: "search",
    internalLinkPosition: "search results:search",
    internalLinkType: autoSearch
      ? "search:automatic search"
      : "search results:search:navigation",
    searchContentType: pageName ? pageName + " page" : "n/a",
    searchTerm: keyword.length ? keyword : searchKeyword,
    searchSuggestedTerm: "n/a",
    eventAction: `search:search box:${autoSearch ? "automatic " : ""}search`,
    eventName: `search:search box:${autoSearch ? "automatic " : ""}search`,
    eventType: "navigation",
    clickInternalLinks: "true",
    internalLinkURL: action === "cmp:show" ? "n/a" : window?.location?.href,
    internalZoneName: "search:search box",
    eventMsg: errorMessage || "n/a",
    eventStatus: status === "success" ? "success" : "failure",
    pageName: "search result",
    pageURL: urlFlag ? previousUrlNew : window?.location?.href,
    referrer: document?.referrer || "n/a",
    previousPage: previousPageNameForSearch || document?.referrer || "n/a", // we can not get previous page name
    timeStamp:
      currDate?.toISOString()?.split("T")[0] +
      " " +
      currDate?.toTimeString()?.split(" ")[0],
    mktgCampaign: "n/a", // currently we don't have such implementation
    contentType: general?.brandName?.toLowerCase(),
    internalLinkZoneName: "search:search box",
  }
  sessionStorage?.setItem("searches", eventInfo?.searchCount)
  let {
    pageInfo: { pageUrl: tempUrl },
  } = page
  tempUrl = window?.location?.href
  page.pageInfo.pageUrl = urlFlag ? previousUrlNew : tempUrl
  page.pageInfo.previousPage = previousPageNameForSearch
  pushAnalyticsObjToDataLayer(eventInfo, action, page)
}

export { handleSearchAnalyticsEvent, addSearchAnalytics }
