import React, { useCallback } from "react"
import { useDropzone } from "react-dropzone"
import { useTranslation } from "next-i18next"
import useViewport from "@/hooks/useViewport"
import { getTranslations } from "@/components/VisualSearch/localization"
import { IMAGE_FILE_TYPE } from "@/components/VisualSearch/v1/UploadImage/constant"

const FileUpload = props => {
  const { setImage = () => {}, setSection } = props
  const { t } = useTranslation()
  const device = useViewport()
  const { borwseBtn, imageUploadtext, changeProduct, imageUploadSubText } =
    getTranslations(t)
  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles["file"] = acceptedFiles[0]
    setImage(acceptedFiles)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <>
      {!device?.isSmallTabView ? (
        <div className={`file-upload ${isDragActive ? "drag-container" : ""}`}>
          <div className="drag-wrapper">
            <div
              {...getRootProps()}
              className={"file-upload-dropzone" + (isDragActive ? " drag" : "")}
            >
              {isDragActive ? (
                <p className="title-hover">{imageUploadtext}</p>
              ) : (
                <>
                  <p className="title">{imageUploadtext}</p>
                  <p className="seperator">{imageUploadSubText}</p>
                  <button className="browse">{borwseBtn}</button>
                  <input
                    {...getInputProps()}
                    accept={IMAGE_FILE_TYPE}
                    aria-label="Input Images"
                  />
                </>
              )}
            </div>
            {!isDragActive ? (
              <div className="product_change">
                <button
                  className="change-button"
                  onClick={() => setSection("category")}
                >
                  <p>{changeProduct}</p>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default FileUpload
