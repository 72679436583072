import React from "react"

import styles from "@/components/Shared/index.module.scss"

// import cartEmptyIcon from "/icons/cart-icon-empty.svg"

const BrandHeaderFiller = () => {
  return (
    <div className={styles.utilityNavHeaderWrapper}>
      <div className="utilitynavheader">
        <div className="gbh-utility-nav">
          <div className="gbh-utility-navbar">
            <ul role="list">
              <li className="gbh-nav__findStore">
                <a
                  role="button"
                  tabIndex={0}
                  aria-live="polite"
                  aria-labelledby="find-ada"
                  className="gbh-nav__findStore--link cmp-find-place"
                >
                  {process.env.NEXT_PUBLIC_FIND_STORE_LABEL || "Find a Store"}
                </a>
              </li>
              <li
                className="gbh-nav__region region_arrowdropdown"
                id={process.env.NEXT_PUBLIC_COUNTRYNAME || "United States"}
              >
                <a
                  role="button"
                  tabIndex={0}
                  aria-labelledby="country-ada"
                  aria-expanded="false"
                  className="gbh-nav__region--link active-country  "
                >
                  {process.env.NEXT_PUBLIC_COUNTRYNAME || "United States"} -{" "}
                  {process.env.NEXT_PUBLIC_LANGUAGENAME || "EN"}
                </a>
              </li>
              <li className="gbh-nav__signin">
                <div className="UtilityWidgets_utilityWidgetsWrapper__Wz0ZS">
                  <div
                    role="list"
                    className="utility-widgets utility-widgets__brand-switcher"
                  >
                    <div
                      role="listitem"
                      className="auth-widget auth-widget__menu AuthUtilityNav_authUtilityNavWrapper__iJgYU"
                    >
                      <div className="auth-nav" aria-expanded="false">
                        <img
                          tabIndex={0}
                          role="button"
                          name="avatarIcon"
                          aria-label="Sign In Collapsed"
                          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTlweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMTkgMjAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8dGl0bGU+U2lnbiBJbiBJY29uPC90aXRsZT4KICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgICAgICA8ZyBpZD0iTmF2aWdhdGlvbi9EZXNrdG9wL1V0aWxpdHktQmFyL0xvZ2dlZC1PdXQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMzkxLjAwMDAwMCwgLTEzLjAwMDAwMCkiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8ZyBpZD0iVXRpbGl0eS1MaW5rcyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTExOC4wMDAwMDAsIDguMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8ZyBpZD0iU2lnbi1Jbi1JY29uIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyNzEuMDAwMDAwLCAwLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNiwxNy41IEMxOC40MTQyNzMzLDE3LjUgMjAuMzg0NDg5MSwxOS40MDEyMzY1IDIwLjQ5NTEwMiwyMS43ODgxNjQzIEwyMC41LDIyIEwyMC41LDI1IEwxOS41LDI1IEwxOS41LDIyIEMxOS41LDIwLjEzMTQzNjYgMTguMDM1NzIyNCwxOC42MDQ4NzM2IDE2LjE5MjAzNTIsMTguNTA1MTc4OSBMMTYsMTguNSBMNywxOC41IEM1LjEzMTQzNjYsMTguNSAzLjYwNDg3MzU1LDE5Ljk2NDI3NzYgMy41MDUxNzg4NiwyMS44MDc5NjQ4IEwzLjUsMjIgTDMuNSwyNSBMMi41LDI1IEwyLjUsMjIgQzIuNSwxOS41ODU3MjY3IDQuNDAxMjM2NTIsMTcuNjE1NTEwOSA2Ljc4ODE2NDMsMTcuNTA0ODk4IEw3LDE3LjUgTDE2LDE3LjUgWiBNMTEuNSw1LjUgQzE0LjI2MTQyMzcsNS41IDE2LjUsNy43Mzg1NzYyNSAxNi41LDEwLjUgQzE2LjUsMTMuMjYxNDIzNyAxNC4yNjE0MjM3LDE1LjUgMTEuNSwxNS41IEM4LjczODU3NjI1LDE1LjUgNi41LDEzLjI2MTQyMzcgNi41LDEwLjUgQzYuNSw3LjczODU3NjI1IDguNzM4NTc2MjUsNS41IDExLjUsNS41IFogTTExLjUsNi41IEM5LjI5MDg2MSw2LjUgNy41LDguMjkwODYxIDcuNSwxMC41IEM3LjUsMTIuNzA5MTM5IDkuMjkwODYxLDE0LjUgMTEuNSwxNC41IEMxMy43MDkxMzksMTQuNSAxNS41LDEyLjcwOTEzOSAxNS41LDEwLjUgQzE1LjUsOC4yOTA4NjEgMTMuNzA5MTM5LDYuNSAxMS41LDYuNSBaIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPiA="
                          alt="Sign In"
                        />
                        <div className="auth-nav__menu  " aria-hidden="true" />
                      </div>
                    </div>
                    <div role="listitem" className="wishlist-widget">
                      <a
                        aria-label="View your wishlist"
                        className="gbh-data-layer"
                        href=""
                      >
                        <img
                          alt="View your wishlist"
                          aria-hidden="true"
                          role="presentation"
                          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4NCjxzdmcgd2lkdGg9IjE1cHgiIGhlaWdodD0iMTRweCIgdmlld0JveD0iMCAwIDE1IDE0IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPg0KICAgIDx0aXRsZT5JY29uL1dpc2hsaXN0PC90aXRsZT4NCiAgICA8ZyBpZD0iU3ltYm9scyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+DQogICAgICAgIDxnIGlkPSJCdXR0b24vSW1hZ2UtSWNvbnMvV2lzaGxpc3QtRW1wdHkvRGFyay1HcmF5LUJHIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTEuMDAwMDAwLCAtMTEuMDAwMDAwKSIgZmlsbD0iI0ZGRkZGRiI+DQogICAgICAgICAgICA8ZyBpZD0iSWNvbi9XaXNobGlzdCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMTEuMDAwMDAwLCAzLjAwMDAwMCkiPg0KICAgICAgICAgICAgICAgIDxwYXRoIGQ9Ik04LjE0MDYwNzE0LDkuNjg4NDgxOTcgTDcuNTAxLDEwLjMzNCBMNi44NjE1OTM2OSw5LjY4ODQ4MTk3IEM1LjI5MjQyMzUyLDguMTAzODM5MzQgMi43NDYyNDQ3OSw4LjEwMzgzOTM0IDEuMTc3MDc0NjMsOS42ODg0ODE5NyBDLTAuMzQ4NzgxOTU4LDExLjIyOTM4MzkgLTAuMzk2MjUxOTU5LDEzLjcxODU4MzQgMS4wNjQzMjIzNiwxNS4yOTcwNDQyIEwxLjE3MDcxODUyLDE1LjQwODU3MDcgTDcuNTAxMTEzNDQsMjEuODAxNTAzMyBMMTMuODA4NDgzOSwxNS40MzIxOTk4IEwxMy45MTUxODc4LDE1LjMyMTE0MDggQzE1LjM5NTU5NjgsMTMuNzIxNzEyNSAxNS4zNTM5NzAzLDExLjIzMjQwMSAxMy44MjUxMjYyLDkuNjg4NDgxOTcgQzEyLjI1NTk1Niw4LjEwMzgzOTM0IDkuNzA5Nzc3MzEsOC4xMDM4MzkzNCA4LjE0MDYwNzE0LDkuNjg4NDgxOTcgWiBNMTMuMTE0NTU4OSwxMC4zOTIxMTEzIEMxNC4yNjY2NjkyLDExLjU1NTU4MTYgMTQuMjk4MTAyMiwxMy40MzUzMTE1IDEzLjE4NzU0NTEsMTQuNjM1MjQ1MyBMMTMuMDkyNTIwMiwxNC43MzQxMDUzIEw3LjUwMSwyMC4zOCBMMS44ODc2NDE4OSwxNC43MTE0NzgzIEwxLjc5Mjk1NDA5LDE0LjYxMjE4MzEgQzAuNzAxOTMzNDQxLDEzLjQzMzAxMzYgMC43Mzc3ODAxMDEsMTEuNTUzMzExIDEuODg3NjQxODksMTAuMzkyMTExMyBDMy4wNjU1MDk0NSw5LjIwMjYyOTU4IDQuOTczMTU4ODcsOS4yMDI2Mjk1OCA2LjE1MTAyNjQyLDEwLjM5MjExMTMgTDcuNTAxMTAwNDIsMTEuNzU1NDk3NCBMOC44NTExNzQ0MSwxMC4zOTIxMTEzIEMxMC4wMjkwNDIsOS4yMDI2Mjk1OCAxMS45MzY2OTE0LDkuMjAyNjI5NTggMTMuMTE0NTU4OSwxMC4zOTIxMTEzIFoiIGlkPSJQYXRoIj48L3BhdGg+DQogICAgICAgICAgICA8L2c+DQogICAgICAgIDwvZz4NCiAgICA8L2c+DQo8L3N2Zz4="
                        />
                      </a>
                    </div>
                    {process.env.NEXT_PUBLIC_DISABLE_CART_ICON === "true" ? (
                      ""
                    ) : (
                      <div role="listitem" className="cart-indicator-widget">
                        <div className="cart-indicator false">
                          <a
                            aria-label="View your cart containing 0 item"
                            role="link"
                            className="gbh-data-layer"
                            href="/en/cart"
                          >
                            <img
                              src={"/icons/cart-icon-empty.svg"}
                              alt="Cart"
                            />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrandHeaderFiller
